import React, { useContext } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import { Icon } from 'components/Image/Icon'
import { SvgWrapperSC } from 'components/Image/Image.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { RouterContext } from 'context/Router/RouterContextProvider'

function ScreenWY1() {
  const router = useContext(RouterContext)

  function handleCancel() {
    router.forceCancel()
  }

  return (
    <Screen>
      <Heading>
        <Header messageKey="CLOSE_dialog_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="CLOSE_dialog_text" />
        </Description>

        <SvgWrapperSC>
          <Icon id="warningImage" />
        </SvgWrapperSC>
      </Body>

      <Footer>
        <BackButton />

        <PrimaryButton messageId="generic_button_confirm" onClick={handleCancel} />
      </Footer>
    </Screen>
  )
}

export default ScreenWY1
