import styled from 'styled-components'

export const PreviewWrapperSC = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
`

export const PreviewImageWrapperSC = styled.img`
  width: ${props => props.width || 0}px;
  height: ${props => props.height || 0}px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
