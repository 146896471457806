import React, { useContext, useMemo, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import NextButton from 'components/Button/NextButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import { ButtonLinkSC } from 'components/Button/VerifaiButton.style'
import Description from 'components/Description/VerifaiDescription'
import { DivFlexSC, DivSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { ErrorMessage } from 'components/Text/Text.style'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ALLOWED_FILE_TYPES, ALLOWED_IMAGE_TYPES } from 'lib/constants'
import { HttpCode } from 'lib/enums/common'
import CheckResult from 'pages/CheckResult/CheckResult'

import * as S from './ScreenWH.style'

const MAX_ATTACHMENT_COUNT = 10
const MAX_ATTACHMENT_SIZE = 10 * 1024 * 1024

interface AttachmentListProps {
  attachments: Array<File>
  setAttachments: (attachments: AttachmentListProps['attachments']) => void
  setErrorMessageId: (messageId: string | null) => void
}

function AttachmentList(props: AttachmentListProps) {
  const { attachments, setAttachments, setErrorMessageId } = props

  function removeDocument(index: number) {
    setErrorMessageId(null)

    const newAttachments = [...attachments]

    newAttachments.splice(index, 1)

    setAttachments(newAttachments)
  }

  return (
    <S.ListWrapper>
      {attachments.map((attachment: File, index) => {
        const key = `${attachment.name}-${index}`

        return (
          <S.ListItem key={key}>
            <S.ListItemInfo>{attachment.name}</S.ListItemInfo>

            <S.RemoveButton onClick={() => removeDocument(index)}>&times;</S.RemoveButton>
          </S.ListItem>
        )
      })}

      {attachments.length === 0 && (
        <S.ListItem>
          <S.ListItemInfo>
            <VerifaiMessage id="WH1_no_attachments" />
          </S.ListItemInfo>
        </S.ListItem>
      )}
    </S.ListWrapper>
  )
}

function ScreenWH1() {
  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const config = useContext(ConfigContext)

  const [isUploading, setIsUploading] = useState(false)
  const [errorMessageId, setErrorMessageId] = useState<string | null>(null)
  const [attachments, setAttachments] = useState<AttachmentListProps['attachments']>([])
  const [fileToAdd, setFileToAdd] = useState<File | null>(null)

  const fileInputRef = useRef(null)

  const acceptedFileTypes = useMemo(() => {
    if (config.isMobile) {
      return ALLOWED_IMAGE_TYPES.join(',')
    }

    return ALLOWED_FILE_TYPES.join(',')
  }, [config])

  async function upload() {
    setIsUploading(true)

    await api.image.postAttachments(attachments)

    setIsUploading(false)
  }

  async function sendFile() {
    if (attachments.length === 0) {
      return
    }

    const isValid = attachments.every(attachment => ALLOWED_FILE_TYPES.includes(attachment.type))

    if (isValid) {
      await upload()
    } else {
      setErrorMessageId('WC2_error_file_format')
    }
  }

  function handleAddFilePressed() {
    if (fileInputRef.current) {
      ;(fileInputRef.current as HTMLElement).click()
    }
  }

  async function handleContinue() {
    setIsUploading(true)

    try {
      await sendFile()

      router.next()
    } catch (error) {
      if (error.code === HttpCode.EntityTooLarge413) {
        setErrorMessageId('WH1_file_size_error')
      }
    }

    setIsUploading(false)
  }

  function handleCancelAdd() {
    setFileToAdd(null)
  }

  function addFile() {
    if (fileToAdd) {
      setAttachments([...attachments, fileToAdd])
      setFileToAdd(null)
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || event.target.files.length === 0) {
      return
    }

    setErrorMessageId(null)

    const fileList = event.target.files

    if (config.isMobile) {
      setFileToAdd(fileList[0])
    } else {
      const files = Array.from(fileList)
      const isValid = files.every(file => file.size < MAX_ATTACHMENT_SIZE)

      if (!isValid) {
        setErrorMessageId('WH1_file_size_error')
      }

      const newAttachments = [
        ...attachments,
        ...files.filter(file => file.size < MAX_ATTACHMENT_SIZE)
      ]

      if (newAttachments.length > MAX_ATTACHMENT_COUNT) {
        setErrorMessageId('WH1_file_limit_error')
      } else {
        setAttachments(newAttachments)
      }
    }
  }

  if (fileToAdd && config.isMobile) {
    return <CheckResult file={fileToAdd} onCancel={handleCancelAdd} onContinue={addFile} />
  }

  return (
    <Screen>
      <Heading>
        <Header messageKey="WH1_title" />
      </Heading>

      <Body allowScroll>
        <Description>
          <VerifaiMessage id="WH1_hint" />
        </Description>

        <DivFlexSC flexDirection="column" flexWrap="initial" height="100%">
          {errorMessageId && (
            <ErrorMessage>
              <VerifaiMessage id={errorMessageId} />
            </ErrorMessage>
          )}

          <AttachmentList
            attachments={attachments}
            setAttachments={setAttachments}
            setErrorMessageId={setErrorMessageId}
          />

          {attachments.length === 0 && (
            <ButtonLinkSC onClick={handleContinue}>
              <VerifaiMessage id="WH1_skip_button" />
            </ButtonLinkSC>
          )}
        </DivFlexSC>
      </Body>

      <Footer>
        <BackButton isDisabled={isUploading} />

        <DivSC>
          <PrimaryButton
            messageId={config.isMobile ? 'WN1_button_primary' : 'WH1_add_file_button'}
            onClick={handleAddFilePressed}
            disabled={attachments.length >= MAX_ATTACHMENT_COUNT || isUploading}
            isLoading={isUploading}
          />

          <S.HiddenInput
            type="file"
            ref={fileInputRef}
            accept={acceptedFileTypes}
            capture="environment"
            onChange={handleInputChange}
            multiple={!config.isMobile}
          />
        </DivSC>

        {attachments.length > 0 && <NextButton onClick={handleContinue} isDisabled={isUploading} />}
      </Footer>
    </Screen>
  )
}

export default ScreenWH1
