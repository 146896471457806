import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import QRCode from 'qrcode.react'

import BackButton from 'components/Button/BackButton'
import { ButtonSC } from 'components/Button/VerifaiButton.style'
import Description from 'components/Description/VerifaiDescription'
import { DivSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ScanContext } from 'context/ScanContextProvider'
import { UploadType } from 'lib/enums/common'
import { Config, Router } from 'typings/context'

import SendSmsOption from './components/SendSmsOption'
import useHandoverData from './hooks/useHandoverData'
import useHandoverPolling from './hooks/useHandoverPolling'

interface ScreenWK2Props {
  config: Config
  router: Router
}

function ScreenWK2(props: ScreenWK2Props) {
  const { config, router } = props

  const [isSendingText, setIsSendingText] = useState(false)
  const [errors, setErrors] = useState({})

  const scan = useContext(ScanContext)
  const { api } = useContext(BackendContext)
  const { handoverData, createHandover, update } = useHandoverData()
  const { updatePoller, poller } = useHandoverPolling()

  const next = useCallback(
    async (handoverUsed: boolean, newHandoverId?: string) => {
      scan.setHandover(
        handoverData.phoneNumber,
        newHandoverId || handoverData.id,
        handoverData.expiresAt
      )

      router.next(handoverUsed)
    },
    [router, scan, handoverData]
  )

  const shouldShowSms = useMemo(() => {
    if (!config.uploadTypes.has(UploadType.Phone)) {
      return false
    }

    const phoneConfig = config.uploadTypes.get(UploadType.Phone)

    return phoneConfig?.showSms || false
  }, [config])

  const handoverUrl = useMemo(() => {
    if (handoverData.url && handoverData.secret) {
      return `${handoverData.url}${handoverData.secret}`
    }

    return ''
  }, [handoverData])

  async function setupHandover() {
    const newHandoverId = await createHandover()
    const newPoller = await updatePoller(newHandoverId)
    const isUsed = await newPoller.run()

    if (isUsed) {
      next(isUsed, newHandoverId)
    } else {
      setupHandover()
    }
  }

  async function handleTextMessageSubmit(event: React.FormEvent) {
    event.preventDefault()

    setIsSendingText(true)

    try {
      await api.handover.createHandoverTextMessage(handoverData.phoneNumber, handoverData.id)

      scan.setHandoverPhone(handoverData.phoneNumber)

      next(false)
    } catch (error) {
      setErrors({ phoneNumber: true })
      setIsSendingText(false)
    }
  }

  useEffect(() => {
    setupHandover()
  }, [])

  useEffect(() => {
    return function cleanup() {
      if (poller) {
        poller.reset()
      }
    }
  }, [poller])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WK2_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WK2_instruction_qr" />
        </Description>

        {handoverData.url && handoverData.secret ? (
          <div>
            <DivSC>
              {process.env.NODE_ENV === 'development' ? (
                <a href={handoverUrl} target="_blank" rel="noreferrer" data-testid="qr-code-link">
                  <QRCode value={handoverUrl} />
                </a>
              ) : (
                <QRCode value={handoverUrl} />
              )}
            </DivSC>

            <SendSmsOption
              shouldShowSms={shouldShowSms}
              handoverData={handoverData}
              onPhoneChange={value => update('phoneNumber', value)}
              suggestedCountry={config.countryFinalSuggestion}
              handleTextMessageSubmit={handleTextMessageSubmit}
              isLoading={isSendingText}
              errors={errors}
            />
          </div>
        ) : (
          <>
            {/* Todo: Translate? */}
            <p>Something went wrong with loading, please retry in a second</p>
            <ButtonSC onClick={setupHandover}>Retry</ButtonSC>
          </>
        )}
      </Body>

      <Footer>
        <BackButton isDisabled={isSendingText} />
      </Footer>
    </Screen>
  )
}

export default ScreenWK2
