import Caution from 'pages/Caution/Caution'
import ScreenWA1 from 'pages/WA/ScreenWA1'
import ScreenWB1 from 'pages/WB/ScreenWB1'
import ScreenWC2 from 'pages/WC/ScreenWC2'
import ScreenWC3 from 'pages/WC/ScreenWC3'
import ScreenWC4 from 'pages/WC/ScreenWC4'
import ScreenWD1 from 'pages/WD/ScreenWD1'
import ScreenWE1 from 'pages/WE/ScreenWE1'
import ScreenWE2 from 'pages/WE/ScreenWE2'
import ScreenWF1 from 'pages/WF/ScreenWF1'
import ScreenWG1 from 'pages/WG/ScreenWG1'
import ScreenWG2 from 'pages/WG/ScreenWG2'
import ScreenWH1 from 'pages/WH/ScreenWH1'
import ScreenWI1 from 'pages/WI/ScreenWI1'
import ScreenWK1 from 'pages/WK/ScreenWK1'
import ScreenWK2 from 'pages/WK/ScreenWK2'
import ScreenWK3 from 'pages/WK/ScreenWK3'
import ScreenWK4 from 'pages/WK/ScreenWK4'
import ScreenWMF1 from 'pages/WMF/ScreenWMF1'
import ScreenWMF2 from 'pages/WMF/ScreenWMF2'
import ScreenWMM1 from 'pages/WMM/ScreenWMM1'
import ScreenWMN1 from 'pages/WMN/ScreenWMN1'
import ScreenWMQ1 from 'pages/WMQ/ScreenWMQ1'
import ScreenWMQ2 from 'pages/WMQ/ScreenWMQ2'
import ScreenWMQ3 from 'pages/WMQ/ScreenWMQ3'
import ScreenWMS1 from 'pages/WMS/ScreenWMS1'
import ScreenWY1 from 'pages/WY/ScreenWY1'
import ScreenWZ1 from 'pages/WZ/ScreenWZ1'

export const screenMap = {
  Caution,
  WA1: ScreenWA1,
  WB1: ScreenWB1,
  WC3: ScreenWC3,
  WC2: ScreenWC2,
  WC4: ScreenWC4,
  WD1: ScreenWD1,
  WE1: ScreenWE1,
  WE2: ScreenWE2,
  WF1: ScreenWF1,
  WH1: ScreenWH1,
  WK1: ScreenWK1,
  WK2: ScreenWK2,
  WK3: ScreenWK3,
  WK4: ScreenWK4,
  WG1: ScreenWG1,
  WG2: ScreenWG2,
  WI1: ScreenWI1,
  WM1: ScreenWMM1,
  WN1: ScreenWMN1,
  WQ1: ScreenWMQ1,
  WQ2: ScreenWMQ2,
  WQ3: ScreenWMQ3,
  WS1: ScreenWMS1,
  WY1: ScreenWY1,
  WZ1: ScreenWZ1,
  WMF1: ScreenWMF1,
  WMF2: ScreenWMF2
}

export const route = (name, props = undefined) => ({
  name,
  screen: name !== 'SUCCESS' ? screenMap[name] : null,
  props
})

const frontProp = { side: 'front' }
const backProp = { side: 'back' }
const sideProp = side => ({ side })

const documentKnown = scan => scan.classifiedUuid || scan.document

const simplePDF = scan => scan.pdfImages.length === 1

const expectedMrzFound = scan => {
  const mrz = scan?.mrzResult || {}
  const mrzDocType = mrz.document_type

  // Since 'RP' isn't an official value in a TD1 we have to manually check if it's a residence permit.
  if (mrzDocType === 'I' && scan.documentType.id === 'RP') {
    return true
  }

  return mrzDocType === scan.documentType.id
}

const isCountryKnown = scan => {
  return scan.mrzResult?.country_code !== null
}

const oneDocType = config => Object.values(config.documentTypes).length === 1

const isMobileOnly = ({ isHandover, isMobile }) => !isHandover && isMobile

export class Routes {
  constructor(scan, config) {
    this.update(scan, config)
  }

  update(scan, config) {
    this.scan = scan
    this.config = config
  }

  nextSide(side) {
    const isFront = side === 'front'

    return this.scan.hasBack() && isFront
  }

  accessFaceMatchScreens() {
    return (
      this.config.faceMatchEnabled &&
      (!this.scan.handoverFinished || this.config.isHandover) &&
      (this.config.isMobile || !this.config.faceMatchMobileOnly)
    )
  }

  getRoutes() {
    const routes = {
      // Greeting screen
      WA1: () => {
        if (this.config.cautionInfo) {
          return 'Caution'
        }

        return oneDocType(this.config) ? routes.WB1() : 'WB1'
      },

      Caution: () => {
        if (this.config.isHandover) {
          if (this.config.showInstruction) {
            return 'WI1'
          }

          return ['WN1', frontProp]
        }

        return oneDocType(this.config) ? routes.WB1() : 'WB1'
      },

      // Document type selection screen
      WB1: () => {
        const { uploadTypes, isMobile, showInstruction } = this.config

        if (isMobile) {
          if (showInstruction) {
            return 'WI1'
          }

          return ['WN1', frontProp]
        }

        if (uploadTypes.size === 1) {
          this.scan.setUploadType(uploadTypes.keys().next().value)

          if (showInstruction) {
            if (uploadTypes.has('file')) {
              return 'WI1'
            }

            if (uploadTypes.has('webcam')) {
              return 'WI1'
            }
          }

          if (uploadTypes.has('phone')) {
            return 'WK1'
          }

          if (uploadTypes.has('file')) {
            return ['WC2', frontProp]
          }

          if (uploadTypes.has('webcam')) {
            return ['WC4', frontProp]
          }
        }

        return 'WC3'
      },

      // Desktop upload screen
      WC2: (side, fileType) => {
        if (fileType === 'application/pdf' && !simplePDF(this.scan)) {
          return ['WE2', sideProp(side)]
        }

        return ['WD1', sideProp(side)]
      },

      // Upload type selection screen
      WC3: uploadType => {
        if (this.config.showInstruction) {
          if (uploadType === 'file' || uploadType === 'webcam') {
            return 'WI1'
          }
        }

        if (uploadType === 'phone') {
          return 'WK1'
        }

        if (uploadType === 'webcam') {
          return ['WC4', frontProp]
        }

        return ['WC2', frontProp]
      },

      // Instruction screen
      WI1: () => {
        if (this.scan.uploadType === 'webcam') {
          return ['WC4', frontProp]
        }

        if (this.config.isHandover || this.config.isMobile) {
          return ['WN1', frontProp]
        }

        return ['WC2', frontProp]
      },

      // Crop screen
      WC4: (side, wrong) => {
        if (wrong) {
          return ['WC2', frontProp]
        }

        return ['WD1', sideProp(side)]
      },

      WD1: side => {
        const isMobileOrHandover = this.scan.handoverFinished || isMobileOnly(this.config)

        if (this.nextSide(side)) {
          // In a mobile flow
          if (isMobileOrHandover) {
            return ['WD1', backProp]
          }

          // In a desktop flow
          return [this.scan.uploadType === 'webcam' ? 'WC4' : 'WC2', backProp]
        }

        // If we have an mrz and we know the document skip WE1 else go to WE1
        if (expectedMrzFound(this.scan) && isCountryKnown(this.scan)) {
          return documentKnown(this.scan) ? routes.WE1() : 'WE1'
        }

        // If we do not have an MRZ, go to WF1
        return 'WF1'
      },

      // Document model selection screen
      WE1: () => {
        if (this.config.canEditPrivacyFilters) {
          return ['WG1', frontProp]
        }

        if (this.accessFaceMatchScreens()) {
          return 'WMF1'
        }

        if (this.config.enableAttachments) {
          return 'WH1'
        }

        return this.config.isHandover ? 'WS1' : 'WZ1'
      },

      // PDF page selection screen
      WE2: side => {
        return ['WD1', sideProp(side)]
      },

      // Country select screen
      WF1: () => {
        if (!documentKnown(this.scan)) {
          return 'WE1'
        }

        if (this.config.canEditPrivacyFilters) {
          return ['WG1', frontProp]
        }

        if (this.accessFaceMatchScreens()) {
          return 'WMF1'
        }

        if (this.config.enableAttachments) {
          return 'WH1'
        }

        return this.config.isHandover ? 'WS1' : 'WZ1'
      },

      // Check result screen
      WG1: (side, isAdjusting) => {
        if (isAdjusting) {
          return ['WG2', sideProp(side)]
        }

        if (this.nextSide(side)) {
          return ['WG1', backProp]
        }

        if (this.accessFaceMatchScreens()) {
          return 'WMF1'
        }

        if (this.config.enableAttachments) {
          return 'WH1'
        }

        return this.config.isHandover ? 'WS1' : 'WZ1'
      },

      // Adjust zones screen
      WG2: side => ['WG1', sideProp(side)],

      // Mobile instruction steps screen
      WK1: () => 'WK2',

      // Phone input / QR code screen
      WK2: wasHandoverUsed => (wasHandoverUsed ? 'WK4' : 'WK3'),

      // Handover timeout screen
      WK3: () => 'WK4',

      // Mobile connected with handover screen
      WK4: () => {
        return 'WZ1'
      },

      // You are now connected screen (handover)
      WM1: () => {
        if (this.config.cautionInfo) {
          return 'Caution'
        }

        if (this.config.showInstruction) {
          return 'WI1'
        }

        return ['WN1', frontProp]
      },

      // Take a picture of the selected document screen (handover)
      WN1: side => ['WQ1', sideProp(side)],

      // Handover check result screen
      WQ1: side => {
        return ['WQ2', sideProp(side)]
      },

      // Handover check image screen
      WQ2: (side, isAdjusting) => {
        if (isAdjusting) {
          return ['WQ3', sideProp(side)]
        }

        return routes.WQ4(side)
      },

      // Handover adjust frame screen
      WQ3: side => {
        return routes.WQ4(side)
      },

      // Purely for processing last step
      WQ4: side => {
        if (this.nextSide(side)) {
          return ['WN1', backProp]
        }

        if (expectedMrzFound(this.scan)) {
          return documentKnown(this.scan) ? routes.WE1() : 'WE1'
        }

        return 'WF1'
      },

      // Mobile upload screen
      WMF1: () => 'WMF2',

      // Mobile upload check screen
      WMF2: () => {
        if (this.config.enableAttachments) {
          return 'WH1'
        }

        return this.config.isHandover ? 'WS1' : 'WZ1'
      },

      WH1: () => {
        return this.config.isHandover ? 'WS1' : 'WZ1'
      },

      // Wait for jobs and finish screen
      WZ1: () => {
        return 'SUCCESS'
      }
    }

    return Object.fromEntries(
      Object.entries(routes).map(([k, v]) => [
        k,
        args => {
          const next = v(...args)

          return Array.isArray(next) ? route(...next) : route(next)
        }
      ])
    )
  }
}
