import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const Font = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize}px;
`
