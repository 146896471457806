import React, { useContext, useEffect, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import Description from 'components/Description/VerifaiDescription'
import { Icon } from 'components/Image/Icon'
import { SvgWrapperSC } from 'components/Image/Image.style'
import Body from 'components/Screen/Body/VerifaiBody'
import { InfoboxSC } from 'components/Screen/Body/VerifaiBody.style'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'

function ScreenWK4() {
  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)

  const [poller, setPoller] = useState<ReturnType<typeof api.handover.finishedPoller>>(null)

  async function setupPoller() {
    const handoverId = scan.handover?.id

    if (handoverId) {
      try {
        const newPoller = await api.handover.finishedPoller(handoverId, 1000, 0)

        await newPoller.run()

        setPoller(newPoller)

        scan.setHandoverFinished(true)

        router.next()
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    setupPoller()

    return function cleanup() {
      if (poller) {
        poller.reset()
      }
    }
  }, [poller])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WK4_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WK4_description" />
        </Description>

        <SvgWrapperSC>
          <Icon id="mobileConnected" />
        </SvgWrapperSC>
        <InfoboxSC>
          <VerifaiMessage id="WK4_info_box" />
        </InfoboxSC>
      </Body>

      <Footer>
        <BackButton />
      </Footer>
    </Screen>
  )
}

export default ScreenWK4
