import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { ConfigContext } from 'context/ConfigContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { AnalyticsEvent, Side } from 'lib/enums/common'
import { Router, RouterAction } from 'typings/context'

import { route, Routes } from './Routes'

const frontProp = { side: Side.Front }

export const RouterContext = React.createContext({} as Router)

interface RouterContextProviderProps {
  children: React.ReactNode
}

function RouterContextProvider(props: RouterContextProviderProps) {
  const { children } = props

  const scan = useContext(ScanContext)
  const config = useContext(ConfigContext)
  const router = useRef(new Routes(scan, config))

  const startScreen = useMemo(() => {
    return route(config.startScreen, frontProp)
  }, [config.startScreen])

  const [actions, setActions] = useState<Array<RouterAction>>([startScreen])
  const [current, setCurrent] = useState<RouterAction>(startScreen)
  const [wasBackAction, setWasBackAction] = useState(false)

  const routes = useMemo(() => {
    if (router) {
      return router.current.getRoutes()
    }

    return {}
  }, [router])

  const resetState = useCallback(() => {
    setActions([startScreen])
    setCurrent(startScreen)
  }, [startScreen])

  const addAction = useCallback(
    (action: RouterAction) => {
      setActions([action, ...actions])
      setCurrent(action)
      setWasBackAction(false)

      scan.addEvent(AnalyticsEvent.ChangeScreen, { screen: action.name })
    },
    [actions, scan]
  )

  const popAction = useCallback(() => {
    if (actions.length > 1) {
      setActions(actions.slice(1))
      setCurrent(actions[1])
      setWasBackAction(true)
    }
  }, [actions])

  const goFrom = useCallback(
    (name: string, ...args: Array<string | boolean>) => {
      const nextRoute = routes[name](args)

      if (nextRoute.name === 'SUCCESS') {
        scan.postEvents(AnalyticsEvent.FinishedFlow).finally(() => {
          if (config.onSuccess) {
            config.onSuccess()
          }
        })

        return Promise.resolve()
      }

      return addAction(nextRoute)
    },
    [addAction, config, routes, scan]
  )

  const next = useCallback(
    (...args: Array<string | boolean>) => {
      if (current) {
        const currentScreen = current.name

        goFrom(currentScreen, ...args)
      }
    },
    [current, goFrom]
  )

  const back = useCallback(() => {
    if (actions.length > 1) {
      popAction()
    } else if (config.onCanceled) {
      scan.postEvents(AnalyticsEvent.CanceledFlow)

      config.onCanceled()
    }
  }, [actions, config, popAction, scan])

  function leaveVerifai(event: BeforeUnloadEvent) {
    // If modal is open
    if (config.show) {
      event.preventDefault()
    }
  }

  if (router && scan && config) {
    // Using `useEffect` to update `router` sometimes leads to old state being read.
    // Bit hacky, but updating it every render at least puts in the app in a proper state.
    // TODO: [ep] Refactor this to no longer update every render.
    router.current.update(scan, config)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', leaveVerifai)

    return function cleanup() {
      window.removeEventListener('beforeunload', leaveVerifai)
    }
  }, [])

  useEffect(() => {
    resetState()
  }, [resetState])

  const value = useMemo(() => {
    function goTo(name: string, routeProps = {}) {
      const nextRoute = route(name, routeProps)

      return addAction(nextRoute)
    }

    function isFirst() {
      return actions.length <= 1
    }

    function forceCancel() {
      setActions([])

      scan.postEvents(AnalyticsEvent.CanceledFlow)

      if (config.onCanceled) {
        config.onCanceled()
      }
    }

    function showCloseScreen() {
      // If close screen and we are already there, don't add it back to the stack.
      if (current?.name === 'WY1') {
        return
      }

      addAction(route('WY1'))
    }

    return {
      reset: resetState,
      actions,
      current,
      isFirst,
      wasBackAction,
      next,
      back,
      pop: popAction,
      goTo,
      forceCancel,
      showCloseScreen
    }
  }, [resetState, actions, current, wasBackAction, next, back, popAction, addAction, config, scan])

  return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
}

export default RouterContextProvider
