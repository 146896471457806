import React, { useContext, useMemo, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import Description from 'components/Description/VerifaiDescription'
import DocumentExample from 'components/DocumentExample/DocumentExample'
import DropFile from 'components/DropFile/DropFile'
import { DivSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { ErrorMessage } from 'components/Text/Text.style'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { ALLOWED_FILE_TYPES } from 'lib/constants'
import { DocumentType, FileType, Side } from 'lib/enums/common'
import { ImageSide } from 'typings/common'
import blobToDataUrl from 'utils/blobToDataUrl'

interface ScreenWC2Props {
  side: ImageSide
}

function ScreenWC2(props: ScreenWC2Props) {
  const { side } = props

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)

  const [isUploading, setIsUploading] = useState(false)
  const [hasError, setHasError] = useState(false)

  async function upload(file: File) {
    if (file.type === FileType.PDF) {
      const pages = await api.image.postPDF(side, file)

      scan.setPdfImages(pages)

      if (pages.length === 1) {
        await api.image.postPDFImage(pages[0].image_id)
      }
    } else {
      const dataUrl = await blobToDataUrl(file)
      const imageData = await api.image.postImage(side, dataUrl, scan.uploadType)

      scan.setImageId(imageData.image_id)
    }
  }

  async function sendFile(file: File) {
    setIsUploading(true)
    setHasError(false)

    const isValid = ALLOWED_FILE_TYPES.includes(file.type)

    if (isValid) {
      await upload(file)

      router.next(side, file.type)
    } else {
      setHasError(true)
      setIsUploading(false)
    }
  }

  function handleDrop(files: FileList) {
    sendFile(files[0])
  }

  const [titleHeaderId, hintMessageId] = useMemo(() => {
    if (scan.documentType?.id === DocumentType.Passport && !scan.needsBackside) {
      return ['WC2_title_photo_page', 'WC2_hint_photo_page']
    }

    if (side === Side.Front) {
      return ['WC2_title_front', 'WC2_hint_front']
    }

    return ['WC2_title_back', 'WC2_hint_back']
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey={titleHeaderId} />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id={hintMessageId} />
        </Description>

        <DocumentExample side={side} />

        <DivSC marginTop="auto">
          {hasError && (
            <ErrorMessage>
              <VerifaiMessage id="WC2_error_file_format" />
            </ErrorMessage>
          )}

          <Description>
            <VerifaiMessage id="WC2_dragdrop_title" />
          </Description>

          <Description>
            <VerifaiMessage id="WC2_dragdrop_or" />
          </Description>
        </DivSC>
      </Body>

      <Footer>
        <BackButton isDisabled={isUploading} />

        <DropFile isUploading={isUploading} onDrop={handleDrop} accept={ALLOWED_FILE_TYPES} />
      </Footer>
    </Screen>
  )
}

export default ScreenWC2
