import styled, { css } from 'styled-components'

import Icon from 'components/Image/Icon'
import { BUTTON_LUMINOSITY_AMOUNT, ITEM_INACTIVE_OPACITY } from 'lib/constants'
import { Direction } from 'lib/enums/common'
import { Breakpoint, FontSize } from 'lib/enums/style'
import {
  borderRadius,
  color,
  darken,
  defaultSpacing,
  focusRing,
  fontSize,
  lighten
} from 'src/style/mixins'

import VerifaiButton from './VerifaiButton'

export const ButtonSC = styled(VerifaiButton)`
  display: inline-block;
  text-align: center;
  background-color: transparent;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: ${props => props.theme.corners.buttonRadius}px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: ${defaultSpacing()} ${defaultSpacing(2)};
  height: ${defaultSpacing(6)};

  ${focusRing()}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  &:hover {
    cursor: pointer;
  }
`

export const ButtonHideSC = styled(ButtonSC)`
  visibility: hidden;
`

export const ButtonPrimarySC = styled(ButtonSC)`
  min-width: ${defaultSpacing(25)};
  color: ${props => props.theme.colors.buttonMainText};
  background-color: ${color('primary')};
  text-transform: uppercase;

  &:hover.disabled {
    cursor: default;
    pointer-events: none;
  }

  &.enabled {
    &:hover {
      background-color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }

    &:active {
      background-color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }
  }
`

export const ButtonPrimaryPhoneSC = styled(ButtonPrimarySC)`
  border-radius: 0 ${borderRadius()} ${borderRadius()} 0;
  padding: ${defaultSpacing()};
  margin: 0;
  text-transform: none;
  min-width: 0;
  height: ${defaultSpacing(5)};
`

export const ButtonOutlineSC = styled(ButtonSC)`
  border-width: 1px;
  border-style: solid;
  border-radius: ${borderRadius()};
  background-color: ${color('modalBackground')};
  color: ${color('primary')};
  border-color: ${color('primary')};

  &:hover {
    color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
    border-color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }

  &:active {
    color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    border-color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }
`

export const FooterIcon = styled(Icon)``

export const FooterButton = styled.button<{ width?: string; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  user-select: none;
  color: #ffffff;
  border: 1px solid transparent;
  color: ${props => props.theme.colors.buttonBack};
  text-transform: uppercase;
  font-size: ${fontSize(FontSize.Medium)};
  background-color: transparent;
  padding: ${defaultSpacing()} ${defaultSpacing(2)};
  transition: color 0.15s ease-in-out;
  width: ${props => props.width || 'initial'};
  border-radius: ${props => props.theme.corners.buttonRadius}px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: ${ITEM_INACTIVE_OPACITY};
    `}

  ${focusRing()}

  ${FooterIcon} {
    color: ${props => props.theme.colors.buttonBack};
    position: relative;
    width: ${defaultSpacing(2)};
    height: ${defaultSpacing(2)};
    display: flex;
    align-items: center;

    svg {
      transition: color 0.15s ease-in-out;
      vertical-align: unset;
      /* TODO: [ep] Hacky fix as the SVG is not an actual rectangle and thus not aligned properly. */
      /* Should be removed once all SVGs have consistent sizes. */
      margin-top: 1px;
    }
  }

  div {
    display: inline-block;
    color: ${props => props.theme.colors.buttonBack};
    position: relative;
  }

  * ~ * {
    margin-inline-start: ${defaultSpacing()};
  }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.buttonBackHover};

    svg {
      color: ${props => props.theme.colors.buttonBackHover};
    }
  }
`

export const ButtonLinkSC = styled(ButtonSC)<{ mt?: number }>`
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}

  color: ${color('primary')};

  &:hover {
    color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }
`

// Todo csb: make better extension from ButtonLinkSC
export const ButtonOptionsSC = styled.div<{ fallback?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    svg {
      color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }
  }
`

export const OptionsArrowSC = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${color('primary')};
  margin-left: 10px;
`

export const RotateButton = styled(ButtonSC)<{ direction: Direction }>`
  color: ${color('primary')};
  padding: ${defaultSpacing()};
  display: inline;
  width: ${defaultSpacing(6)};

  &:hover {
    color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }

  &:active {
    color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }

  ${({ direction }) =>
    direction === Direction.Right &&
    css`
      svg {
        transform: scaleX(-1);
      }
    `}
`

export const CloseButtonSC = styled(ButtonSC)`
  color: ${props => props.theme.colors.buttonBack};
  padding: 0.5rem 0;
  text-transform: uppercase;

  &:hover {
    color: ${props => props.theme.colors.buttonBackHover};
  }
`

// Todo csb: make their own button
export const ButtonShutterSC = styled(ButtonSC)`
  background: #ffffff;
  border: 4px solid ${color('primary')};
  margin: auto;
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 2em;

  &:hover {
    background: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }

  &:active {
    background: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }
`

// Todo csb: make their own button
export const ButtonFlipCameraSC = styled(ButtonSC)`
  background: rgba(255, 255, 255, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNy41MTMiIGhlaWdodD0iMjEuMzA2IiB2aWV3Qm94PSIwIDAgMjcuNTEzIDIxLjMwNiI+PHBhdGggZD0iTTUuNTY1LDE0LjU1OGwzLjItMy4yYTEuMTUxLDEuMTUxLDAsMCwwLC4xMDctMS41NDUsMS4xOTIsMS4xOTIsMCwwLDAtMS43LS4xMDdsLS45MDUuOTA1VjcuNzQxYzAtMS4wMTIsNC40NzQtMi41LDguNzg5LTIuNSw0LjYzNCwwLDguNzg5LDEuNjUxLDguNzg5LDIuNXYyLjM0NGExLjE0NiwxLjE0NiwwLDEsMCwyLjI5LjA1M3YtMi40QzI2LjE3OSw0LjY1MSwxOS42OCwzLDE1LjEsM1M0LjAyMSw0LjY1MSw0LjAyMSw3Ljc0MXYyLjg3NmwtLjYzOS0uNjkyYTEuMTUxLDEuMTUxLDAsMCwwLTEuNTQ1LS4xMDcsMS4xOTIsMS4xOTIsMCwwLDAtLjEwNywxLjdMNC44MiwxNC42MTJBLjU3My41NzMsMCwwLDAsNS41NjUsMTQuNTU4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuNDIzIC0zKSIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik0yNy4wNiwyMS4xNTNsLTMuMiwzLjJhMS4xNTEsMS4xNTEsMCwwLDAtLjEwNywxLjU0NSwxLjE5MiwxLjE5MiwwLDAsMCwxLjcuMTA3bC45MDUtLjkwNXYyLjg3NmMwLDEuMDEyLTQuNDc0LDIuNS04Ljc4OSwyLjUtNC42MzQsMC04Ljc4OS0xLjY1MS04Ljc4OS0yLjVWMjUuNjI4YTEuMTQ2LDEuMTQ2LDAsMSwwLTIuMjktLjA1M3YyLjRjMCwzLjA4OSw2LjUsNC43NDEsMTEuMDc5LDQuNzQxczExLjA3OS0xLjY1MSwxMS4wNzktNC43NDFWMjUuMDk1bC42OTIuNjkyYTEuMTUxLDEuMTUxLDAsMCwwLDEuNTQ1LjEwNywxLjE5MiwxLjE5MiwwLDAsMCwuMTA3LTEuN0wyNy45MTIsMjEuMUEuNy43LDAsMCwwLDI3LjA2LDIxLjE1M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjc5NiAtMTEuNDA2KSIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik0yMy40NTUsMjMuNjA5YTQuMTU1LDQuMTU1LDAsMSwxLDQuMTU1LTQuMTU1QTQuMTYsNC4xNiwwLDAsMSwyMy40NTUsMjMuNjA5Wm0wLTYuNzExQTIuNTU3LDIuNTU3LDAsMCwwLDIwLjksMTkuNDU1YTIuNTU3LDIuNTU3LDAsMSwwLDUuMTEzLDBBMi41NTcsMi41NTcsMCwwLDAsMjMuNDU1LDE2LjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS43NzggLTguNzQ5KSIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==')
    center center no-repeat;
  border: 2px solid white;
  margin: auto;
  width: 48px;
  height: 48px;
  padding: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`

export const ButtonCancelFlowSC = styled(ButtonSC)`
  appearance: none;
  text-align: center;
  background-color: transparent;
  vertical-align: middle;
  border: 1px solid transparent;
  z-index: 5000;
  position: absolute;
  padding: 0;
  top: ${defaultSpacing(3)};
  right: ${defaultSpacing(3)};
  width: ${defaultSpacing(2)};
  height: ${defaultSpacing(2)};

  @media (max-width: ${Breakpoint.Small}px) {
    top: ${defaultSpacing(2)};
    right: ${defaultSpacing(2)};
  }

  :before,
  :after {
    position: absolute;
    content: ' ';
    top: 0;
    left: 50%;
    height: 100%;
    width: 2px;
    background-color: ${darken('uiBackground', 0.7)};
  }

  :hover {
    :before,
    :after {
      background-color: ${color('danger')};
    }
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }
`
