import styled, { css } from 'styled-components'

import VerifaiButton from 'components/Button/VerifaiButton'
import { IconWrapper } from 'components/Image/Image.style'
import { BUTTON_LUMINOSITY_AMOUNT } from 'lib/constants'
import { FontWeight } from 'lib/enums/style'
import { color, darken, defaultSpacing, focusRing, lighten } from 'src/style/mixins'

export const DocumentUploadList = styled.div`
  color: ${color('primary')};
`

export const DocumentUploadListItem = styled(VerifaiButton)`
  position: relative;
  text-align: initial;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${defaultSpacing(2)};
  padding: ${defaultSpacing()};
  margin-bottom: ${defaultSpacing(2)};
  background-color: ${color('modalBackground')};
  color: ${color('primary')};
  border: 1px solid ${color('primary')};

  ${focusRing()}

  &:hover {
    cursor: pointer;
    color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
    border: 1px solid ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }

  &:active {
    color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    border: 1px solid ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
  }
`

export const DocumentUploadImage = styled.div<{ isRTL: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${color('primary')};
  margin-right: ${defaultSpacing()};

  ${props =>
    props.isRTL &&
    css`
      margin-left: ${defaultSpacing()};
      margin-right: 0;
    `}

  ${IconWrapper} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${defaultSpacing(7)};
    height: ${defaultSpacing(7)};
  }

  &:hover {
    cursor: pointer;
    svg {
      color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }
  }

  &:active {
    svg {
      color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }
  }
`

export const Text = styled.div`
  font-style: italic;
  font-size: 0.9rem;
  color: inherit;
`

export const TextBold = styled(Text)`
  font-style: normal;
  font-weight: ${FontWeight.SemiBold};
  font-size: 1.1rem;
`
