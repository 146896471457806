export default function formatSeconds(seconds: number) {
  if (!seconds) {
    return ''
  }

  const minutes = Math.floor(seconds / 60)
  const restSeconds = Math.floor(seconds % 60)
  const formattedSeconds = restSeconds < 10 ? `0${restSeconds}` : restSeconds

  return `${minutes}:${formattedSeconds}`
}
