import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'

import iconMap from './iconMap'

interface IconContextProps {
  getIcon: (icon: string) => { adjustable: boolean; icon: () => JSX.Element; customUrl?: string }
}

interface IconContextProviderProps {
  iconPath: string
  children?: React.ReactNode
}

export const IconContext = createContext<IconContextProps>({} as IconContextProps)

function IconContextProvider(props: IconContextProviderProps) {
  const { iconPath, children } = props

  const themeContext = useContext(ThemeContext)

  const getIcon = useCallback(
    (key: string) => {
      const icon = iconMap[key as keyof typeof iconMap]

      if (icon.adjustable && key in themeContext.icons) {
        return {
          ...icon,
          customUrl: `${iconPath}/images/${themeContext.icons[key]}`
        }
      }

      return icon
    },
    [iconPath, themeContext.icons]
  )

  const value = useMemo(() => {
    return {
      getIcon
    }
  }, [getIcon])

  return <IconContext.Provider value={value}>{children}</IconContext.Provider>
}

export default IconContextProvider
