import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { ButtonCancelFlowSC } from 'components/Button/VerifaiButton.style'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ClickAction } from 'lib/enums/common'

interface CancelFlowButtonProps {
  show: boolean
}

function CancelFlowButton(props: CancelFlowButtonProps) {
  const { show } = props

  const router = useContext(RouterContext)
  const intl = useIntl()

  function handleClick() {
    router.showCloseScreen()
  }

  return show ? (
    <ButtonCancelFlowSC
      onClick={handleClick}
      eventData={{ action: ClickAction.Close }}
      ariaLabel={intl.formatMessage({ id: 'generic_button_close' })}
    />
  ) : null
}

export default CancelFlowButton
