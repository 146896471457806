import React, { useContext } from 'react'

import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'

import * as S from './VerifaiButton.style'

interface NextButtonProps {
  isDisabled?: boolean
  onClick?: () => void
  dataTestId?: string
}

function NextButton(props: NextButtonProps) {
  const { isDisabled, onClick, dataTestId } = props

  const router = useContext(RouterContext)
  const config = useContext(ConfigContext)

  function handleOnClick(event: React.MouseEvent) {
    event.preventDefault()

    if (!isDisabled) {
      if (onClick) {
        onClick()
      } else {
        router.next()
      }
    }
  }

  if (router.isFirst()) {
    return null
  }

  return (
    <S.FooterButton onClick={handleOnClick} disabled={isDisabled} data-testid={dataTestId}>
      {!config.isMobile && <VerifaiMessage id="generic_button_next" />}

      <S.FooterIcon id="backArrowRtl" rotate={config.rightToLeftScript ? 180 : 0} />
    </S.FooterButton>
  )
}
export default NextButton
