import styled, { css } from 'styled-components'

import { Breakpoint } from 'lib/enums/style'
import { borderRadius, color, defaultSpacing, scrollbar } from 'src/style/mixins'

export const Body = styled.div<{ allowScroll?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  padding: ${defaultSpacing()} ${defaultSpacing(7)};
  overflow: hidden;

  ${({ allowScroll }) => {
    if (allowScroll) {
      return css`
        overflow-y: auto;
        scrollbar-gutter: stable both-edges;

        ${scrollbar()}
      `
    }

    return null
  }}

  p {
    color: ${props => props.theme.colors.bodyText};
  }

  .error {
    color: ${props => props.theme.colors.bodyErrorText};
  }

  @media only screen and (max-height: 500px) {
    min-height: 250px;
  }

  @media (max-width: ${Breakpoint.Medium}px) {
    padding: ${defaultSpacing()} ${defaultSpacing(6)};
  }

  @media (max-width: ${Breakpoint.Small}px) {
    padding: ${defaultSpacing()} ${defaultSpacing(4)};
    min-height: 250px;
  }
`

export const InfoboxSC = styled.div`
  padding: ${defaultSpacing(2)};
  margin-top: ${defaultSpacing(4)};
  border-radius: ${borderRadius()};
  background-color: rgba(${color('primary')}, 0.1);
`
