import React, { useContext, useEffect, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import RotateButton from 'components/Button/RotateButton'
import { ButtonHideSC } from 'components/Button/VerifaiButton.style'
import CropCanvas from 'components/CropCanvas/CropCanvas'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import { DivFlexSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { ImageContext } from 'context/ImageContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import useImageProcessing from 'hooks/useImageProcessing'
import usePolling from 'hooks/usePolling'
import { Direction } from 'lib/enums/common'
import { ImageSide } from 'typings/common'

import { PreviewWrapperSC } from './ScreenWMQ2.style'

interface ScreenWMQ3Props {
  side: ImageSide
}

function ScreenWMQ3(props: ScreenWMQ3Props) {
  const { side } = props

  const cropCanvasRef = useRef(null)

  const router = useContext(RouterContext)
  const config = useContext(ConfigContext)
  const image = useContext(ImageContext)

  const { poller, stopPolling } = usePolling(image.side)
  const { getRotatedData, processImage, isProcessing, loadImage } = useImageProcessing(
    image.side,
    cropCanvasRef,
    poller
  )

  const [isConvex, setIsConvex] = useState(true)

  async function handleContinue() {
    await processImage()

    stopPolling()

    router.next(image.side)
  }

  function handleBack() {
    stopPolling()

    router.back()
  }

  async function handleRotation(direction: Direction.Left | Direction.Right) {
    const newImageData = await getRotatedData(direction)

    if (newImageData) {
      const { cropPoints, rotation } = newImageData
      const imageUrl = await loadImage(image.side, rotation)

      if (imageUrl) {
        image.update(image.side, { imageUrl, cropPoints, rotation })
      }
    }
  }

  useEffect(() => {
    image.setSide(side)
  }, [side])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WQ1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WQ3_description_new" />
        </Description>

        <PreviewWrapperSC>
          {image.imageUrl ? (
            <>
              <CropCanvas
                mobileView={config.isMobile}
                ref={cropCanvasRef}
                imageURL={image.imageUrl}
                suggestedPoints={image.cropPoints}
                convexityChanged={(value: boolean) => setIsConvex(value)}
                onPointDrag={image.setCropPoints}
              />

              <DivFlexSC justifyContent="center" flexDirection="row" marginBottom="50px">
                <div>
                  <RotateButton
                    direction={Direction.Left}
                    onClick={() => handleRotation(Direction.Left)}
                    isDisabled={isProcessing}
                  />
                </div>
                <div>
                  <ButtonHideSC onClick={() => {}}>hidden</ButtonHideSC>
                </div>
                <div>
                  <RotateButton
                    direction={Direction.Right}
                    onClick={() => handleRotation(Direction.Right)}
                    isDisabled={isProcessing}
                  />
                </div>
              </DivFlexSC>
            </>
          ) : (
            <LoadingIndicator />
          )}
        </PreviewWrapperSC>
      </Body>
      <Footer>
        <BackButton onClick={handleBack} isDisabled={isProcessing} />

        <PrimaryButton
          messageId="WQ3_button_primary"
          onClick={handleContinue}
          disabled={!isConvex}
          isLoading={isProcessing}
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWMQ3
