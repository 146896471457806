import React, { createContext, memo, useContext, useMemo } from 'react'
import isEqual from 'react-fast-compare'

import { BackendContext } from './Backend/BackendContext'
import { RouterContext } from './Router/RouterContextProvider'
import { ConfigContext } from './ConfigContextProvider'
import { IconContext } from './IconContextProvider'
import { ScanContext } from './ScanContextProvider'

export const MultiContext = createContext(null)
MultiContext.displayName = 'MultiContext'

interface MultiContextProviderProps {
  children: React.ReactNode
}

export const MultiContextProvider = memo(
  function MultiContextProvider(props: MultiContextProviderProps) {
    const { children } = props

    // Defining the context map outside the component doesn't work. Probably due to the context API being used.
    const contextMap = useMemo(() => {
      return {
        backend: BackendContext,
        scan: ScanContext,
        config: ConfigContext,
        router: RouterContext,
        icon: IconContext
      }
    }, [])

    // TODO: Disabling this rule here as refactoring might break things.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const useContextPairs = Object.entries(contextMap).map(([k, v]) => [k, useContext(v)])
    const useContextMap = Object.fromEntries(useContextPairs)

    return <MultiContext.Provider value={useContextMap}>{children}</MultiContext.Provider>
  },
  (prevProps, nextProps) => isEqual(prevProps.children, nextProps.children)
)

MultiContextProvider.displayName = 'MultiContextProvider'
