import React from 'react'

import BackButton from 'components/Button/BackButton'

import { FooterSC } from './VerifaiFooter.style'

interface VerifaiFooterProps {
  children?: React.ReactNode
}

function VerifaiFooter(props: VerifaiFooterProps) {
  const { children } = props

  function getChildArray() {
    const childrenList = React.Children.toArray(children)

    if (childrenList.length === 1) {
      if ((childrenList[0] as React.ReactElement).type === BackButton) {
        return [childrenList[0], null, null]
      }

      return [null, childrenList[0], null]
    }

    if (childrenList.length === 2) {
      return [childrenList[0], childrenList[1], null]
    }

    return children
  }

  function mapChildren() {
    const childrenList = getChildArray()

    if (!childrenList || !Array.isArray(childrenList)) {
      return null
    }

    if (childrenList.length > 3) {
      throw new Error(`Too many children in footer max is 3 got ${childrenList.length}`)
    }

    return childrenList.map((child, index) => {
      const key = `footer-item-${index}`

      return <div key={key}>{child}</div>
    })
  }

  return <FooterSC>{mapChildren()}</FooterSC>
}

export default VerifaiFooter
