import React, { useContext, useEffect, useRef } from 'react'

import BackButton from 'components/Button/BackButton'
import LinkButton from 'components/Button/LinkButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import CropCanvas from 'components/CropCanvas/CropCanvas'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { ImageContext } from 'context/ImageContextProvider'
import useImageProcessing from 'hooks/useImageProcessing'
import usePolling from 'hooks/usePolling'
import { ImageSide } from 'typings/common'
import { Router } from 'typings/context'

import { PreviewWrapperSC } from './ScreenWMQ2.style'

interface ScreenWMQ2Props {
  router: Router
  side: ImageSide
}

function ScreenWMQ2(props: ScreenWMQ2Props) {
  const { router, side } = props

  const config = useContext(ConfigContext)
  const image = useContext(ImageContext)

  const cropCanvasRef = useRef(null)

  const { poller, startPolling, stopPolling } = usePolling(side)
  const { isProcessing, isLoading, processImage } = useImageProcessing(side, cropCanvasRef, poller)

  async function handleContinue(isAdjusting = false) {
    if (!isAdjusting) {
      await processImage()
    }

    stopPolling()

    router.next(image.side, isAdjusting)
  }

  function handleBack() {
    stopPolling()

    router.back()
  }

  useEffect(() => {
    image.setSide(side)
  }, [side])

  useEffect(() => {
    startPolling()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WQ1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WQ2_description" />
        </Description>

        <PreviewWrapperSC>
          {image.imageUrl && image.cropPoints && image.rotation !== null ? (
            <CropCanvas
              mobileView={config.isMobile}
              ref={cropCanvasRef}
              imageURL={image.imageUrl}
              suggestedPoints={image.cropPoints}
              canEdit={false}
            />
          ) : (
            <LoadingIndicator />
          )}
        </PreviewWrapperSC>

        <div>
          <LinkButton messageId="WQ2_button_adjust" onClick={() => handleContinue(true)} />
        </div>
      </Body>

      <Footer>
        <BackButton onClick={handleBack} isDisabled={isProcessing} />

        <PrimaryButton
          messageId="WQ1_button_primary"
          onClick={() => handleContinue()}
          disabled={!image.imageUrl || isLoading}
          isLoading={isProcessing}
          dataTestId="mobile-check-crop-continue-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWMQ2
