import styled, { css } from 'styled-components'

import { IconWrapper } from 'components/Image/Image.style'
import { BUTTON_LUMINOSITY_AMOUNT, ITEM_INACTIVE_OPACITY } from 'lib/constants'
import { borderRadius, color, darken, defaultSpacing, focusRing, lighten } from 'src/style/mixins'

import ListItem from './DocumentTypeListItem'

export { IconWrapper }

export const DocumentTypeGroupSC = styled.div`
  display: flex;
  flex-direction: column;
`

export const DocumentTypeList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius(0.5)};
  color: ${color('primary')};
`

export const DocumentTypeListItem = styled(ListItem)<{ isDisabled: boolean }>`
  display: flex;
  vertical-align: middle;
  align-items: center;
  background-color: transparent;
  color: ${color('primary')};
  border: 1px solid ${color('primary')};
  border-radius: 16px;
  margin-bottom: ${defaultSpacing(2)};
  padding: ${defaultSpacing()};
  height: ${defaultSpacing(7)};

  ${focusRing()}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: ${ITEM_INACTIVE_OPACITY};
    `}

  ${IconWrapper} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${defaultSpacing()};
  }

  svg {
    fill: ${color('primary')};
  }

  &:hover {
    cursor: pointer;
    color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
    border: 1px solid ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};

    svg {
      color: ${lighten('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }
  }

  &:active {
    color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    border: 1px solid ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};

    svg {
      color: ${darken('primary', BUTTON_LUMINOSITY_AMOUNT)};
    }
  }
`
