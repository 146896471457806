import React, { useContext, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import { ButtonLinkSC } from 'components/Button/VerifaiButton.style'
import * as S from 'components/Camera/Camera.style'
import Description from 'components/Description/VerifaiDescription'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { DocumentType, Side, UploadType } from 'lib/enums/common'

interface ScreenWC4Props {
  side: Side
}

function ScreenWC4(props: ScreenWC4Props) {
  const { side } = props

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)

  const [isProcessing, setIsProcessing] = useState(true)
  const [devices, setDevices] = useState<Array<MediaDeviceInfo>>([])
  const [deviceId, setDeviceId] = useState<string>()
  const [noPhoto, setNoPhoto] = useState(false)

  const videoRef = useRef(null)

  async function setupVideoDevices() {
    // Filters video devices and sets a list of unique devices in the state
    const newDevices = await navigator.mediaDevices.enumerateDevices()

    // Filter for video input
    newDevices.filter(device => device.kind === 'videoinput')
    // Map them to simple object
    newDevices.map(device => ({ id: device.deviceId, label: device.label }))
    // Use Object to get unique device ids
    newDevices.reduce((obj, item) => {
      return { ...obj, [item.id]: item.label }
    }, {})
    // Remap unique devices to array
    Object.keys(newDevices).map(key => ({ id: key, label: devices[key] }))

    setDevices(newDevices)
  }

  async function onStream(stream: MediaStream) {
    const videoTrack = stream.getVideoTracks()[0]

    setIsProcessing(false)

    const { deviceId: newDeviceId } = videoTrack.getSettings()

    await setupVideoDevices()

    setDeviceId(newDeviceId)
  }

  function onFallback() {
    router.next(side, true)
  }

  async function onPicture(image?: string) {
    if (!image) {
      setNoPhoto(true)
    } else {
      try {
        await api.image.postImage(side, image)

        router.next(side, false)
      } catch (error) {
        console.error(error)
      }
    }
  }

  function onError() {
    setNoPhoto(true)
    setIsProcessing(false)

    throw new Error('Camera Error')
  }

  function handleTakePicture() {
    if (videoRef.current) {
      const canvas = (videoRef.current as Webcam).getCanvas()

      if (!canvas) {
        onPicture()
      } else {
        const file = canvas.toDataURL('image/jpeg')

        onPicture(file)
      }
    }
  }

  function getTitleHeaderId() {
    // TODO Adapt this when doing Pootle String refactor
    const sideMap = { front: '1', back: '2' }
    // TODO make documentMap a global value
    const documentMap = {
      P: 'passport',
      I: 'id',
      D: 'dl',
      RP: 'rp',
      V: 'visa',
      R: 'rtd'
    }

    if (scan.documentType) {
      if (side === 'back' && scan.documentType.id === DocumentType.Passport) {
        return 'WC4_title_back'
      }

      return `WN${sideMap[side]}_title_${documentMap[scan.documentType.id]}`
    }

    return `WP1_title_${side}`
  }

  useEffect(() => {
    scan.setUploadType(UploadType.Webcam)

    setupVideoDevices()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey={getTitleHeaderId()} />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WC4_description" />
        </Description>

        <S.WebcamView>
          {noPhoto && (
            <S.NoWebcamWarning>
              <VerifaiMessage id="WC4_error_no_photo" />
            </S.NoWebcamWarning>
          )}

          {!noPhoto && (
            <Webcam
              videoConstraints={{
                deviceId,
                width: { ideal: 2048 },
                height: { ideal: 1024 },
                facingMode: { ideal: 'environment' }
              }}
              onUserMedia={onStream}
              onUserMediaError={onError}
              ref={videoRef}
              audio={false}
              forceScreenshotSourceSize
              screenshotFormat="image/jpeg"
              screenshotQuality={0.9}
            />
          )}
        </S.WebcamView>

        <ButtonLinkSC onClick={onFallback}>
          <VerifaiMessage id="WC4_fallback" />
        </ButtonLinkSC>
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />

        {!noPhoto && (
          <PrimaryButton
            messageId="WN2_button_primary"
            onClick={handleTakePicture}
            isLoading={isProcessing}
          />
        )}
      </Footer>
    </Screen>
  )
}

export default ScreenWC4
