import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'

import { DOCUMENT_MAP } from 'components/DocumentTypeList/DocumentTypeList'
import { Icon } from 'components/Image/Icon'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { IdModel } from 'typings/context'

import * as S from './Carousel.style'

interface ListItemProps {
  idModel: Partial<IdModel>
  isSelected: boolean
  onClick: () => void
  alt: string
}

function ListItem(props: ListItemProps) {
  const { idModel, isSelected, onClick, alt } = props

  const config = useContext(ConfigContext)

  const ref = useRef<HTMLDivElement>(null)

  const aspectRatio = useMemo(() => {
    if (config.isMobile && idModel.width_mm && idModel.height_mm) {
      return idModel.width_mm / idModel.height_mm
    }

    return null
  }, [config.isMobile, idModel])

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    }
  }, [isSelected])

  return (
    <S.ThumbnailContainer
      isSelected={isSelected}
      onClick={onClick}
      ref={ref}
      aspectRatio={aspectRatio}
    >
      {idModel.sample_front ? (
        <S.Thumbnail src={idModel.sample_front} alt={alt} />
      ) : (
        <S.UnknownThumbnail>
          <Icon id="documentNotFound" />

          {config.isMobile && <VerifaiMessage id="WE2_document_missing" />}
        </S.UnknownThumbnail>
      )}
    </S.ThumbnailContainer>
  )
}

interface CarouselProps {
  choices: Array<Partial<IdModel>>
  selectedIndex: number
  onChange: (index: number) => void
}

function Carousel(props: CarouselProps) {
  const { choices, selectedIndex, onChange } = props

  const config = useContext(ConfigContext)
  const intl = useIntl()

  function handleSelection(index: number) {
    if (index < 0 || index >= choices.length) {
      return
    }

    onChange(index)
  }

  const { selectedItem, documentType, country } = useMemo(() => {
    if (choices.length === 0) {
      return { selectedItem: null, documentType: '', country: null }
    }

    const selected = choices[selectedIndex]
    const foundCountry = config.countries.find(item => item.code === selected.country)
    const documentData = DOCUMENT_MAP[selected.type as keyof typeof DOCUMENT_MAP] || DOCUMENT_MAP.P
    const documentTranslation = intl.formatMessage({ id: documentData.labelId })

    return {
      selectedItem: selected,
      documentType: documentTranslation,
      country: foundCountry?.name
    }
  }, [choices, selectedIndex, config, intl])

  const items = choices.map((choice, index) => {
    return (
      <S.ItemContainer key={choice.uuid || `version-item-${index}`}>
        <ListItem
          idModel={choice}
          isSelected={index === selectedIndex}
          onClick={() => handleSelection(index)}
          alt={intl.formatMessage(
            { id: 'WE1_example_model' },
            { type: documentType.toLowerCase(), country, model: choice.model }
          )}
        />
      </S.ItemContainer>
    )
  })

  if (items.length === 0) {
    return null
  }

  return (
    <>
      {config.isMobile ? (
        <S.DocumentList>{items}</S.DocumentList>
      ) : (
        <>
          <S.Carousel>
            <S.NavigationArrow
              onClick={() => handleSelection(selectedIndex - 1)}
              isDisabled={selectedIndex === 0}
              aria-label={intl.formatMessage({ id: 'WE1_previous_button' })}
            >
              <Icon id="documentVersionArrowBackImage" />
            </S.NavigationArrow>

            {selectedItem?.sample_front ? (
              <S.ImageContainer>
                <S.IdModelImage
                  src={selectedItem.sample_front}
                  alt={intl.formatMessage(
                    { id: 'WE1_example_model' },
                    { type: documentType.toLowerCase(), country, model: selectedItem.model }
                  )}
                />
              </S.ImageContainer>
            ) : (
              <S.UnknownImageContainer>
                <S.UnknownModelImage>
                  <Icon id="documentNotFound" />

                  <VerifaiMessage id="WE2_document_missing" />
                </S.UnknownModelImage>
              </S.UnknownImageContainer>
            )}

            <S.NavigationArrow
              onClick={() => handleSelection(selectedIndex + 1)}
              isDisabled={selectedIndex === choices.length - 1}
              aria-label={intl.formatMessage({ id: 'WE1_next_button' })}
            >
              <Icon id="documentVersionArrowforwardImage" />
            </S.NavigationArrow>
          </S.Carousel>

          <S.ThumbnailWrapper>
            <S.Thumbnails>{items}</S.Thumbnails>
          </S.ThumbnailWrapper>
        </>
      )}
    </>
  )
}

export default Carousel
