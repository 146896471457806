import styled, { css } from 'styled-components'

import { ITEM_INACTIVE_OPACITY } from 'lib/constants'
import { Breakpoint, FontWeight } from 'lib/enums/style'
import {
  borderRadius,
  color,
  defaultSpacing,
  focusRing,
  lighten,
  scrollbar
} from 'src/style/mixins'

export const CountryListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: unset;
  width: 75%;
  align-self: center;
  overflow: hidden;
  font-weight: ${FontWeight.Medium};

  @media (max-width: ${Breakpoint.Medium}px) {
    width: 100%;
  }
`

export const CountryLabel = styled.div`
  margin-bottom: ${defaultSpacing()};
`

export const SearchWrapper = styled.div`
  padding: 0 1px;
`

export const SearchInput = styled.input`
  border: none;
  border-bottom: 1px solid ${color('border')};
  width: 100%;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy4wNDgiIGhlaWdodD0iMTcuMDU4IiB2aWV3Qm94PSIwIDAgMTcuMDQ4IDE3LjA1OCI+CiAgICA8ZyBpZD0icm91bmQtc2VhcmNoLTI0cHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjk5OSAtMi45OTkpIj4KICAgICAgICA8cGF0aCBpZD0iUGF0aF8xNjMiIGRhdGEtbmFtZT0iUGF0aCAxNjMiIGQ9Ik0xNS41LDE0aC0uNzlsLS4yOC0uMjdhNi41MTgsNi41MTgsMCwxLDAtLjcuN2wuMjcuMjh2Ljc5bDQuMjUsNC4yNWExLjA1NCwxLjA1NCwwLDAsMCwxLjQ5LTEuNDlabS02LDBBNC41LDQuNSwwLDEsMSwxNCw5LjUsNC40OTQsNC40OTQsMCwwLDEsOS41LDE0WiIgZmlsbD0iI2NjYyIvPgogICAgPC9nPgo8L3N2Zz4K');
  background-position: ${defaultSpacing()} center;
  background-repeat: no-repeat;
  border-radius: ${borderRadius(0.5)};
  padding-left: ${defaultSpacing(5)};
  margin: ${defaultSpacing()} 0;
  font-size: inherit;

  :focus-visible {
    outline-color: ${color('border')};
  }
`

export const Flag = styled.span`
  margin-right: ${defaultSpacing(2)};
  margin-left: ${defaultSpacing(2)};
`

export const ListItem = styled.button`
  appearance: none;
  width: 100%;
  background-color: ${color('light')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${defaultSpacing()};
  color: ${color('dark')};

  ${focusRing()};

  :focus-visible {
    outline-offset: -2px;
  }
`

export const ClickableListItem = styled(ListItem)`
  text-align: left;
  transition: background-color 250ms ease, border-color 250ms ease;
  border: 1px solid transparent;

  &:not(:last-child) {
    border-bottom: 1px solid ${color('border')};
  }

  :first-child {
    border-top-left-radius: ${borderRadius()};
  }

  :last-child {
    border-bottom-left-radius: ${borderRadius()};
    border-bottom-right-radius: ${borderRadius()};
  }

  &:hover {
    border-color: ${color('primary')};
    background-color: ${lighten('primary', 0.5)};
    cursor: pointer;
  }
`

export const CountryList = styled.div<{ isDisabled: boolean }>`
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid ${color('border')};
  border-radius: ${borderRadius()};

  ${scrollbar()}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${ListItem}, ${ClickableListItem} {
        pointer-events: none;
        opacity: ${ITEM_INACTIVE_OPACITY};
      }
    `}
`

export const SuggestedCountry = styled.div`
  margin-bottom: ${defaultSpacing(2)};

  ${CountryList} {
    &:hover {
      border-color: ${color('primary')};
    }
  }

  ${ClickableListItem} {
    border: none;
    border-radius: ${borderRadius()};
  }
`
