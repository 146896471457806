import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { ConfigContext } from 'context/ConfigContextProvider'

import { PromoSC, VerifaiLogoSC } from './Promo.style'

interface PromoProps {
  demo: boolean
}

function Promo(props: PromoProps) {
  const { demo } = props

  const config = useContext(ConfigContext)

  return (
    <PromoSC isMobile={config.isMobile} isDemo={demo}>
      {demo ? (
        <p>FOR DEVELOPMENT PURPOSE ONLY</p>
      ) : (
        <VerifaiLogoSC>powered by VERIFAI.</VerifaiLogoSC>
      )}
    </PromoSC>
  )
}

Promo.propTypes = {
  demo: PropTypes.bool
}

export default Promo
