import React from 'react'

import { ConfigContext } from 'context/ConfigContextProvider'
import { ScanContext } from 'context/ScanContextProvider'

import RoutingContextProvider from './RouterContextProvider'

interface RouterWrapperProps {
  children: React.ReactNode
}

function RouterWrapper(props: RouterWrapperProps) {
  const { children } = props

  return (
    <ScanContext.Consumer>
      {scan => (
        <ConfigContext.Consumer>
          {appConfig => (
            <RoutingContextProvider scan={scan} appConfig={appConfig}>
              {children}
            </RoutingContextProvider>
          )}
        </ConfigContext.Consumer>
      )}
    </ScanContext.Consumer>
  )
}

export default RouterWrapper
