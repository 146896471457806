import React, { useContext, useEffect, useState } from 'react'

import Description from 'components/Description/VerifaiDescription'
import { Icon } from 'components/Image/Icon'
import { SvgWrapperSC } from 'components/Image/Image.style'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Body from 'components/Screen/Body/VerifaiBody'
import { InfoboxSC } from 'components/Screen/Body/VerifaiBody.style'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { STORAGE_KEY_HANDOVER_DATA } from 'lib/constants'

function ScreenWMS1() {
  const backend = useContext(BackendContext)

  const [isProcessing, setIsProcessing] = useState(true)

  async function finishHandover() {
    await backend.api.handover.finishHandover()

    localStorage.removeItem(STORAGE_KEY_HANDOVER_DATA)
    setIsProcessing(false)
  }

  useEffect(() => {
    finishHandover()
  }, [])

  return (
    <Screen>
      {isProcessing ? (
        <LoadingIndicator />
      ) : (
        <>
          <Heading>
            <Header messageKey="WS1_title" />
          </Heading>

          <Body>
            <Description>
              <VerifaiMessage id="WS1_description" />
            </Description>

            <SvgWrapperSC>
              <Icon id="bodyCircleImage" />
            </SvgWrapperSC>
            <InfoboxSC>
              <VerifaiMessage id="WS1_info_box" />
            </InfoboxSC>
          </Body>

          <Footer />
        </>
      )}
    </Screen>
  )
}

export default ScreenWMS1
