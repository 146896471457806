import styled, { css, keyframes } from 'styled-components'

import { Size } from 'lib/enums/common'
import { color, defaultSpacing } from 'src/style/mixins'
import { Theme } from 'typings/context'

export const Container = styled.div`
  /* height: 100%; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div<{ hasText: boolean; color: keyof Theme['colors']; size: Size }>`
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 2px solid;
  border-color: ${props => color(props.color)} transparent transparent transparent;
  border-radius: 50%;

  ${({ hasText }) =>
    hasText &&
    css`
      margin-bottom: ${defaultSpacing(2)};
    `}

  ${({ size }) => {
    if (size === Size.Large) {
      return css`
        border-width: 4px;
        width: ${defaultSpacing(8)};
        height: ${defaultSpacing(8)};
      `
    }

    return css`
      width: ${defaultSpacing(4)};
      height: ${defaultSpacing(4)};
    `
  }}
`
