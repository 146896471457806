import styled from 'styled-components'

import { SvgWrapperSC } from 'components/Image/Image.style'
import { Container as LoadingContainer } from 'components/LoadingIndicator/LoadingIndicator.style'
import { color, defaultSpacing } from 'src/style/mixins'

// eslint-disable-next-line import/prefer-default-export
export const DescriptionSC = styled.div`
  text-align: center;
  color: ${color('textDescription')};

  :not(:last-child) {
    + * {
      margin-top: ${defaultSpacing(2)};
    }

    + ${SvgWrapperSC} {
      margin-top: auto;
    }

    + ${LoadingContainer} {
      margin-top: 0;
    }
  }
`
