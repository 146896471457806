import React from 'react'

export default class BaseComponent extends React.Component {
  constructor(props, context) {
    super(props, context)
  }

  /**
   * Promises the state to be set, will always resolve.
   * @param {Object || function} state - State to apply
   * @returns {Promise<Object>}
   */
  promiseState(state) {
    return new Promise(resolve => this.setState(state, () => resolve(state)))
  }

  /**
   * Promises a toggle of the key in the state, key should be a boolean
   * @param {string} key Key of the value to be toggled
   * @returns {Promise<Object>}
   */
  promiseToggle(key) {
    return new Promise(resolve => this.toggleState(key, resolve))
  }

  /**
   * Toggles the key in the state, value should be a boolean
   * @param {string} key - Key of the value to be toggled
   * @param {function} cb - Callback
   */
  toggleState(key, cb = null) {
    this.setState(
      state => ({ [key]: !state[key] }),
      () => cb({ [key]: this.state[key] })
    )
  }

  /**
   * Throws an error by using setState
   * So the error will always be thrown in the current component
   * @param {Error} error
   */
  throwError(error) {
    this.setState(() => {
      throw error
    })
  }
}
