import VError from './VError'

export const DocumentErrorTypes = {
  DocumentWhiteList: 'dwl',
  CountryWhiteList: 'cwl',
  CountryBlackList: 'cbl',
  DocumentTypePerCountry: 'doctype_per_country',
  Age: 'age',
  ExpiryDate: 'exp_date'
}

export default class DocumentError extends VError {
  constructor(
    {
      titleStringId = 'DNA_title',
      descStringId = 'DNA_desc1',
      hintStringId = 'DNA_desc2',
      showCloseButton = true,
      showPrimaryButton = true,
      primaryButtonId = 'generic_button_retry',
      primaryButtonAction = null,
      reasons = [],
      type = null
    },
    ...params
  ) {
    super(
      {
        titleStringId,
        descStringId,
        showCloseButton,
        showPrimaryButton,
        primaryButtonId,
        primaryButtonAction
      },
      ...params
    )

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DocumentError)
    }

    this.name = 'DocumentError'
    this.hintStringId = hintStringId
    this.reasons = reasons
    this.type = type
  }
}
