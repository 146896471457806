import React, { useMemo } from 'react'

import { Icon } from 'components/Image/Icon'
import { DocumentType } from 'lib/enums/common'
import { DocumentTypeData, IconId } from 'typings/common'

import * as S from './DocumentTypeList.style'

export const DOCUMENT_MAP = {
  P: { labelId: 'WB1_item_passport', image: 'passportImage' },
  I: { labelId: 'WB1_item_id', image: 'idCardImage' },
  D: { labelId: 'WB1_item_dl', image: 'drivingLicenceImage' },
  RP: { labelId: 'WB2_item_residence_permit', image: 'residencePermitImage' },
  R: { labelId: 'WB2_item_ref_traveldoc', image: 'refugeeTravelDocumentImage' }
}

interface DocumentTypeListProps {
  documentList: Array<DocumentTypeData>
  onItemClick: (id: DocumentType) => void
  isDisabled: boolean
}

function DocumentTypeList(props: DocumentTypeListProps) {
  const { documentList, onItemClick, isDisabled } = props

  const listItems = useMemo(() => {
    return documentList.map(item => {
      const documentData = DOCUMENT_MAP[item.id as keyof typeof DOCUMENT_MAP] || DOCUMENT_MAP.P

      return (
        <S.DocumentTypeListItem
          key={item.id}
          id={item.id}
          image={
            <S.IconWrapper>
              <Icon id={documentData.image as IconId} width={56} isDecorative />
            </S.IconWrapper>
          }
          messageId={documentData.labelId}
          isDisabled={isDisabled}
          onClick={onItemClick}
        />
      )
    })
  }, [documentList, onItemClick, isDisabled])

  return <S.DocumentTypeList>{listItems}</S.DocumentTypeList>
}

export default DocumentTypeList
