import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { UploadType } from 'lib/enums/common'
import { Country } from 'typings/common'
import { Config } from 'typings/context'
import mobileCheck from 'utils/mobileCheck'

import { BackendContext } from './Backend/BackendContext'

const DEFAULT_UPLOAD_TYPES = {
  phone: {
    showSms: false
  },
  file: {},
  webcam: {}
}

const RTL_SCRIPTS = ['ar', 'he', 'fa', 'ur'] // Arabic, Hebrew, Persian, and Urdu

export const ConfigContext = createContext({} as Config)

type ConfigContextProviderProps = Partial<Config> & {
  uploadTypes?: Array<UploadType>
  countryCodeOption?: string
  children: React.ReactNode
}

function ConfigContextProvider(props: ConfigContextProviderProps) {
  const {
    onSuccess,
    onCanceled,
    onError,
    show = false,
    closable = false,
    showInstruction = false,
    showGreeting = true,
    cautionInfo = null,
    uploadTypes = [UploadType.File, UploadType.Phone],
    faceMatchMobileOnly = false,
    isHandover = false,
    display = 'modal',
    assetsUrl = '',
    countryCodeOption,
    enableAttachments,
    children
  } = props

  const backendContext = useContext(BackendContext)

  const [isInitialized, setIsInitialized] = useState(false)
  const [canEditPrivacyFilters, setCanEditPrivacyFilters] = useState(false)
  const [startScreen, setStartScreen] = useState('')
  const [documentTypes, setDocumentTypes] = useState({} as Config['documentTypes'])
  const [rightToLeftScript, setRightToLeftScript] = useState(false)
  const [countries, setCountries] = useState<Array<Country>>([])
  const [countryFinalSuggestion, setCountryFinalSuggestion] = useState<Country | null>(null)

  const isMobile = useMemo(() => {
    return mobileCheck()
  }, [])

  const hasCautionInfo = useMemo(() => {
    return cautionInfo && cautionInfo.name && cautionInfo.reason
  }, [cautionInfo])

  const uploadTypesMap = useMemo(() => {
    return new Map(
      uploadTypes
        .map(item => (typeof item === 'string' ? [item, {}] : item)) // Map ['str'] => [['str', {}]]
        .filter(item => Array.isArray(item)) // Filter any non arrays
        .map(item => (item.length === 1 && typeof item[0] === 'string' ? [item[0], {}] : item)) // Map [['str']] => [['str', {}]]
        .filter(item => item.length === 2) // Filter out non tuples
        .filter(([t, c]) => typeof t === 'string' && typeof c === 'object') // Filter any malformed tuples
        .map(([t, c]) => [t.toLowerCase(), c]) // Key to lower case
        .filter(([t]) => ['phone', 'file', 'webcam'].includes(t)) // Filter unknown keys
        .map(([t, c]) => [
          t,
          { ...DEFAULT_UPLOAD_TYPES[t as keyof typeof DEFAULT_UPLOAD_TYPES], ...c }
        ]) // Fill with defaults.
    )
  }, [uploadTypes])

  const handleSuccess = useCallback(async () => {
    await backendContext.api.session.setFinished()

    if (onSuccess) {
      const sessionId = backendContext.session.session_id

      onSuccess(sessionId)
    }
  }, [backendContext])

  const handleCancel = useCallback(async () => {
    if (onCanceled) {
      const sessionId = backendContext.session.session_id

      onCanceled(sessionId)
    }
  }, [backendContext, onCanceled])

  function getLanguageCountry() {
    // If en-US, pick US. Otherwise, return null
    const { languages } = navigator
    const navigatorCountries = languages.filter(item => item.includes('-'))

    if (navigatorCountries.length === 0) {
      return null
    }

    const { language } = navigator

    if (languages[0] !== language) {
      if (language.length > 2) {
        return language.slice(-2).toLowerCase()
      }

      return null
    }

    return navigatorCountries[0].slice(-2).toLowerCase()
  }

  function getSuggestedCountry(countryList: Array<Country>) {
    if (countryCodeOption) {
      return (
        countryList.find(
          item => item.code.toLowerCase() === countryCodeOption.toLowerCase() || null
        ) || null
      )
    }

    const languageCountry = getLanguageCountry()

    if (languageCountry) {
      return (
        countryList.find(item => item.code.toLowerCase() === languageCountry.toLowerCase()) || null
      )
    }

    return null
  }

  async function setupValues() {
    // Todo jvw: put this in the session object in middleware
    const documentList = await backendContext.api.session.getDocumentTypeWhitelist()

    const newDocumentTypes = Object.fromEntries(documentList.map(item => [item.id, item]))

    setDocumentTypes(newDocumentTypes)

    // Todo jvw: put this in the session object in middleware
    const { allowed: isAllowed } = await backendContext.api.session.getCanEditPrivacyFilters()

    setCanEditPrivacyFilters(isAllowed)
  }

  function setupStartScreen() {
    const hasDocumentTypes = Object.values(documentTypes).length > 1

    function evalStartScreen() {
      if (isHandover) {
        return 'WM1'
      }

      if (showGreeting) {
        return 'WA1'
      }

      if (hasCautionInfo) {
        return 'Caution'
      }

      if (hasDocumentTypes) {
        return 'WB1'
      }

      if (isMobile) {
        if (!hasDocumentTypes && showInstruction) {
          return 'WI1'
        }

        return 'WN1'
      }

      if (uploadTypesMap.size === 1) {
        if (showInstruction) {
          if (uploadTypesMap.has('file') || uploadTypesMap.has('webcam')) {
            return 'WI1'
          }
        }

        if (uploadTypesMap.has('phone')) {
          return 'WK1'
        }

        if (uploadTypesMap.has('file')) {
          return 'WC2'
        }

        if (uploadTypesMap.has('webcam')) {
          return 'WC4'
        }
      }

      return 'WC3'
    }

    return setStartScreen(evalStartScreen())
  }

  async function setupConfig() {
    await setupValues()

    const countryResult = await backendContext.api.session.getAvailableCountries()

    setCountries(countryResult)
    setCountryFinalSuggestion(getSuggestedCountry(countryResult))
    setRightToLeftScript(RTL_SCRIPTS.includes(backendContext.session.locale.split('_')[0]))
    setIsInitialized(true)
  }

  useEffect(() => {
    setupConfig()
  }, [])

  useEffect(() => {
    if (isInitialized) {
      setupStartScreen()
    }
  }, [isInitialized])

  const value = useMemo(() => {
    function isDemo() {
      const identifier = backendContext.session.identifier_state

      // This also catches null and other edge cases
      if (!(typeof identifier === 'string' || identifier instanceof String)) {
        return false
      }

      return identifier.includes('test')
    }

    return {
      onSuccess: handleSuccess,
      onCanceled: handleCancel,
      onError,
      canEditPrivacyFilters,
      faceMatchEnabled: backendContext.session.enable_facematch,
      enableAttachments,
      faceMatchMobileOnly,
      isMobile,
      isMobileOnly: isMobile && !isHandover,
      display,
      isDemo: isDemo(),
      uploadTypes: uploadTypesMap,
      startScreen,
      documentTypes,
      rightToLeftScript,
      assetsUrl,
      countries,
      countryFinalSuggestion,
      show,
      closable,
      showInstruction,
      showGreeting,
      cautionInfo: hasCautionInfo ? cautionInfo : null,
      isHandover
    }
  }, [
    backendContext,
    handleSuccess,
    handleCancel,
    onError,
    uploadTypesMap,
    canEditPrivacyFilters,
    faceMatchMobileOnly,
    enableAttachments,
    display,
    isMobile,
    isHandover,
    startScreen,
    documentTypes,
    rightToLeftScript,
    assetsUrl,
    countries,
    countryFinalSuggestion,
    show,
    closable,
    showInstruction,
    showGreeting,
    cautionInfo,
    hasCautionInfo
  ])

  return <ConfigContext.Provider value={value}>{isInitialized && children}</ConfigContext.Provider>
}

export default ConfigContextProvider
