import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import RotateButton from 'components/Button/RotateButton'
import { ButtonHideSC } from 'components/Button/VerifaiButton.style'
import CropCanvas from 'components/CropCanvas/CropCanvas'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import { DivFlexSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ImageContext } from 'context/ImageContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import useImageProcessing from 'hooks/useImageProcessing'
import usePolling from 'hooks/usePolling'
import { Direction, Side } from 'lib/enums/common'
import { ImageSide } from 'typings/common'
import { Config, Router } from 'typings/context'

interface ScreenWD1Props {
  config: Config
  router: Router
  side: ImageSide
}

function ScreenWD1(props: ScreenWD1Props) {
  const { config, router, side } = props

  const scan = useContext(ScanContext)
  const image = useContext(ImageContext)

  const [isConvex, setIsConvex] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const cropCanvasRef = useRef(null)

  const { poller, getImageSuggestions, stopPolling } = usePolling(side)
  const { processImage, loadImage, getRotatedData, isProcessing } = useImageProcessing(
    side,
    cropCanvasRef,
    poller
  )

  async function handleContinue() {
    await processImage()

    stopPolling()

    router.next(side)
  }

  async function handleRotation(direction: Direction.Left | Direction.Right) {
    const newImageData = await getRotatedData(direction)

    if (newImageData) {
      const { cropPoints, rotation } = newImageData
      const imageUrl = await loadImage(image.side, rotation)

      if (imageUrl) {
        image.update(side, { imageUrl, cropPoints, rotation })
      }
    }
  }

  function handleBack() {
    stopPolling()

    router.back()
  }

  const headerMessageKey = useMemo(() => {
    if (scan.hasBack()) {
      return `WD1_title_${side}`
    }

    return 'WD1_title_passport'
  }, [scan, side])

  const isContinueDisabled = useMemo(() => {
    return !isConvex || !image.imageUrl || isProcessing || isLoading
  }, [isConvex, image, isProcessing, isLoading])

  useEffect(() => {
    async function setupImage() {
      setIsLoading(true)

      const { cropPoints, rotation } = await getImageSuggestions()

      const imageUrl = await loadImage(side, rotation)

      if (imageUrl) {
        image.update(side, { imageUrl, cropPoints, rotation })
      }

      setIsLoading(false)
    }

    setupImage()
  }, [])

  useEffect(() => {
    image.setSide(side)
  }, [side])

  useEffect(() => {
    if (side === Side.Front) {
      scan.reset()
    }
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey={headerMessageKey} />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WD1_description" />
        </Description>

        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <CropCanvas
              mobileView={config.isMobile}
              ref={cropCanvasRef}
              imageURL={image.imageUrl}
              suggestedPoints={image.cropPoints}
              convexityChanged={(value: boolean) => setIsConvex(value)}
              onPointDrag={image.setCropPoints}
            />

            <DivFlexSC justifyContent="center" flexDirection="row" marginTop="8px">
              <RotateButton
                direction={Direction.Left}
                onClick={() => handleRotation(Direction.Left)}
                isDisabled={isContinueDisabled}
              />

              <ButtonHideSC onClick={() => {}}>hidden</ButtonHideSC>

              <RotateButton
                direction={Direction.Right}
                onClick={() => handleRotation(Direction.Right)}
                isDisabled={isContinueDisabled}
              />
            </DivFlexSC>
          </>
        )}
      </Body>

      <Footer>
        <BackButton onClick={handleBack} isDisabled={isProcessing} />

        <PrimaryButton
          messageId="generic_button_continue"
          onClick={handleContinue}
          disabled={isContinueDisabled}
          isLoading={isProcessing}
          dataTestId="document-crop-continue"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWD1
