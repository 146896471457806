import React, { useContext } from 'react'

import { ConfigContext } from 'context/ConfigContextProvider'
import { UploadType } from 'lib/enums/common'
import { IconId } from 'typings/common'

import * as S from './DocumentUploadList.style'
import DocumentUploadListItem from './DocumentUploadListItem'

const UPLOAD_TYPES_MAP = {
  file: {
    type: UploadType.File,
    icon: 'upload' as IconId,
    titleId: 'WC3_button_primary',
    descriptionId: 'WC3_button_primary2'
  },
  phone: {
    type: UploadType.Phone,
    icon: 'smartphone' as IconId,
    titleId: 'WC3_button_phone',
    descriptionId: 'WC3_button_phone2'
  },
  webcam: {
    type: UploadType.Webcam,
    icon: 'webcam' as IconId,
    titleId: 'WC3_button_webcam',
    descriptionId: 'WC3_button_webcam2'
  }
}

interface DocumentUploadListProps {
  onClick: (type: UploadType) => void
  isDisabled: boolean
}

function DocumentUploadList(props: DocumentUploadListProps) {
  const { onClick, isDisabled } = props

  const { uploadTypes } = useContext(ConfigContext)

  return (
    <S.DocumentUploadList>
      {uploadTypes.has(UploadType.File) && (
        <DocumentUploadListItem
          data={UPLOAD_TYPES_MAP.file}
          onClick={() => onClick(UploadType.File)}
          isDisabled={isDisabled}
        />
      )}

      {uploadTypes.has(UploadType.Phone) && (
        <DocumentUploadListItem
          data={UPLOAD_TYPES_MAP.phone}
          onClick={() => onClick(UploadType.Phone)}
          isDisabled={isDisabled}
        />
      )}

      {uploadTypes.has(UploadType.Webcam) && (
        <DocumentUploadListItem
          data={UPLOAD_TYPES_MAP.webcam}
          onClick={() => onClick(UploadType.Webcam)}
          isDisabled={isDisabled}
        />
      )}
    </S.DocumentUploadList>
  )
}

export default DocumentUploadList
