import React, { useContext, useEffect, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import LinkButton from 'components/Button/LinkButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import FitImage from 'components/Image/FitImage'
import { PreviewContainer } from 'components/Image/Image.style'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { HttpCode, Side } from 'lib/enums/common'

interface ScreenWG1Props {
  side: Side
}

function ScreenWG1(props: ScreenWG1Props) {
  const { side } = props

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const config = useContext(ConfigContext)

  const [imageObjectUrl, setImageObjectUrl] = useState<string | null>(null)
  const [isProcessing, setIsProcessing] = useState(true)

  function handleCompletePressed() {
    router.next(side, false)
  }

  function handleAdjustZonesPressed() {
    router.next(side, true)
  }

  async function loadImage() {
    const result = await api.image.getMaskedImageSrc(side)
    const objectUrl = URL.createObjectURL(result)

    setImageObjectUrl(objectUrl)
  }

  function isFinalScreen() {
    const { sides } = scan.documentType

    return sides === 1 || side === 'back'
  }

  function completeMessageId() {
    if (isFinalScreen()) {
      return 'WG1_button_primary_finished'
    }

    return 'WG1_button_primary_complete'
  }

  async function setupZones() {
    setIsProcessing(true)

    try {
      const zones = await api.image.getAdjustableZones(side)

      if (zones.length === 0) {
        router.next(side, false)
      } else {
        loadImage()
        setIsProcessing(false)
      }
    } catch (error) {
      if (error.code === HttpCode.ServerError500) {
        throw error
      } else {
        router.next(side, false)
      }
    }
  }

  useEffect(() => {
    setupZones()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WG1_title" />
      </Heading>

      <Body>
        <Description>
          {config.canEditPrivacyFilters && (
            <p>
              <VerifaiMessage id="WG1_description" />
            </p>
          )}
        </Description>

        {isProcessing || !imageObjectUrl ? (
          <LoadingIndicator />
        ) : (
          <PreviewContainer>
            <FitImage src={imageObjectUrl} />

            {config.canEditPrivacyFilters && (
              <LinkButton messageId="WG1_button_adjust_zones" onClick={handleAdjustZonesPressed} />
            )}
          </PreviewContainer>
        )}
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />

        <PrimaryButton
          messageId={completeMessageId()}
          onClick={handleCompletePressed}
          disabled={isProcessing || !imageObjectUrl}
          dataTestId="privacy-filters-continue-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWG1
