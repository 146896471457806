import styled from 'styled-components'

import { defaultSpacing } from 'src/style/mixins'

export const LabelWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-top: ${defaultSpacing()};
`

export const LabelHint = styled.div`
  float: right;
  color: ${props => props.theme.colors.bodyText};
`

export const InstructionWrapper = styled.div`
  text-align: left;
  margin-bottom: 0;
  color: ${props => props.theme.colors.bodyText};
`

export const SmsWrapper = styled.div`
  margin-top: ${defaultSpacing(2)};
  margin-bottom: 0;
  color: ${props => props.theme.colors.bodyText};
`
