import styled, { css } from 'styled-components'

import { defaultSpacing } from 'src/style/mixins'

export const SvgWrapperSC = styled.div<{ width?: number }>`
  svg {
    width: 100%;
    fill: ${props => props.theme.colors.imageForeground};
    color: ${props => props.theme.colors.imageBackground};
  }

  img {
    width: 100%;
  }

  width: ${defaultSpacing(30)};
  height: ${defaultSpacing(30)};
  margin: auto;

  // todo csb: find better solution to make images smaller in mobile only
  @media only screen and (max-width: 600px) {
    width: ${defaultSpacing(20)};
    height: ${defaultSpacing(20)};
  }
`

export const CautionImage = styled(SvgWrapperSC)`
  svg {
    fill: initial;
    color: initial;
  }
`

export const IconWrapper = styled.div<{ width?: number; rotate?: number; isDisabled?: boolean }>`
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}

  ${({ rotate }) =>
    rotate &&
    css`
      rotate: ${rotate}deg;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`

export const PreviewContainer = styled.div`
  flex-grow: 1;
`

export const ImageContainer = styled.div`
  max-height: ${defaultSpacing(35)};
`

export const ImageSC = styled.img`
  width: 100%;
  height: auto;
`
