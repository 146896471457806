import {
  darken as darkenColor,
  lighten as lightenColor,
  transparentize as transparentizeColor
} from 'polished'
import { css } from 'styled-components'

import { FontSize } from 'lib/enums/style'
import { Theme } from 'typings/context'

type ColorId = keyof Theme['colors']

export const defaultSpacing = (multiplier = 1, unit = 'px') => css`
  ${props => `${props.theme.spacing.default * multiplier}${unit}`}
`

export const borderRadius = (multiplier = 1) => css`
  ${props => `${props.theme.spacing.default * multiplier}px`}
`

export const color = (id: ColorId) => css`
  ${props => props.theme.colors[id]}
`

export const lighten = (id: ColorId, amount: number) => css`
  ${props => `${lightenColor(amount, props.theme.colors[id])}`}
`

export const darken = (id: ColorId, amount: number) => css`
  ${props => `${darkenColor(amount, props.theme.colors[id])}`}
`

export const transparentize = (id: ColorId, amount: number) => css`
  ${props => `${transparentizeColor(amount, props.theme.colors[id])}`}
`

export const fontSize = (size: FontSize) => css`
  ${() => `${size}rem`}
`

export const scrollbar = () => css`
  scrollbar-color: ${color('primary')} ${color('uiBackground')};

  ::-webkit-scrollbar {
    width: ${defaultSpacing()};
    height: ${defaultSpacing()};
  }

  ::-webkit-scrollbar-thumb {
    height: ${defaultSpacing(5)};
    border-radius: ${borderRadius()};
    background-clip: padding-box;
    background-color: ${color('primary')};
  }

  ::-webkit-scrollbar-track {
    background-color: ${color('uiBackground')};
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
`

export const focusRing = () => css`
  :focus {
    outline: none;
  }

  :focus-visible {
    outline: 2px solid ${color('dark')};
    outline-offset: 2px;
  }
`
