import React, { useContext, useMemo, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import LinkButton from 'components/Button/LinkButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import { InstructionBody, InstructionTableWrapper } from 'components/Instructions/Instruction.style'
import InstructionGrid from 'components/Instructions/InstructionGrid'
import InstructionList from 'components/Instructions/InstructionList'
import InstructionTable from 'components/Instructions/InstructionTable'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import VerifaiMessage from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'

export const instructionList = [
  { labelId: 'angle', messageId: 'WI1_description_angle' },
  { labelId: 'contrast', messageId: 'WI1_description_contrast' },
  { labelId: 'far', messageId: 'WI1_description_far' },
  { labelId: 'flash', messageId: 'WI1_description_flash' },
  { labelId: 'focus', messageId: 'WI1_description_focus' },
  { labelId: 'obstruction', messageId: 'WI1_description_construction' },
  { labelId: 'resolution', messageId: 'WI1_description_resolution' }
]

function MobileInstructions() {
  const [showMore, setShowMore] = useState(false)

  const instructionsButtonText = useMemo(() => {
    if (showMore) {
      return 'WI1_less_instructions_btn'
    }

    return 'WI1_more_instructions_btn'
  }, [showMore])

  return (
    <>
      {showMore ? (
        <InstructionTableWrapper className="overflow-content">
          <InstructionTable instructions={instructionList} />
        </InstructionTableWrapper>
      ) : (
        <InstructionList />
      )}

      <LinkButton messageId={instructionsButtonText} onClick={() => setShowMore(!showMore)} />
    </>
  )
}

function ScreenWI1() {
  const router = useContext(RouterContext)
  const config = useContext(ConfigContext)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectedInstruction = useMemo(() => {
    return instructionList[selectedIndex]
  }, [selectedIndex])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WI1_title" />
      </Heading>

      <InstructionBody allowScroll>
        {!config.isMobile && (
          <Description>
            <VerifaiMessage id={selectedInstruction.messageId} />
          </Description>
        )}

        {config.isMobile ? (
          <MobileInstructions />
        ) : (
          <InstructionGrid
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            instructions={instructionList}
          />
        )}
      </InstructionBody>

      <Footer>
        <BackButton />

        <PrimaryButton
          messageId="WI1_continue_btn"
          onClick={() => router.next()}
          dataTestId="instructions-continue-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWI1
