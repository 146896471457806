import React, { useEffect, useMemo } from 'react'

import { JwtPayload, VerifaiSession } from 'typings/api'

import BaseApi from './Api/BaseApi'
import handoverApi from './Api/HandoverApi'
import imageApi from './Api/ImageApi'
import jobApi from './Api/JobApi'
import sessionApi from './Api/SessionApi'

interface BackendContextProps {
  jwt: string
  jwtPayload: JwtPayload
  session: VerifaiSession
  api: {
    session: ReturnType<typeof sessionApi>
    image: ReturnType<typeof imageApi>
    job: ReturnType<typeof jobApi>
    handover: ReturnType<typeof handoverApi>
  }
}

export const BackendContext = React.createContext({} as BackendContextProps)

interface BackendContextProviderProps {
  backendUrl: string
  jwt: string
  jwtPayload: JwtPayload
  session: VerifaiSession
  handoverId?: string
  children: React.ReactNode
}

function BackendContextProvider(props: BackendContextProviderProps) {
  const { backendUrl, jwt, jwtPayload, session, handoverId, children } = props

  const baseApi = useMemo(() => {
    return new BaseApi(backendUrl, jwt, session)
  }, [])

  const value = useMemo(() => {
    return {
      jwt,
      jwtPayload,
      session,
      api: {
        session: sessionApi(baseApi),
        image: imageApi(baseApi),
        job: jobApi(baseApi),
        handover: handoverApi(baseApi, handoverId)
      }
    }
  }, [baseApi, jwt, jwtPayload, handoverId, session])

  useEffect(() => {
    baseApi.update(backendUrl, jwt, session)
  }, [baseApi, backendUrl, jwt, session])

  return <BackendContext.Provider value={value}>{children}</BackendContext.Provider>
}

export default BackendContextProvider
