import React, { useContext, useMemo } from 'react'

import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { AnalyticsEvent, ClickAction, DocumentType } from 'lib/enums/common'

interface DocumentTypeListItemProps {
  id: DocumentType
  image: React.ReactNode
  messageId: string
  onClick?: (id: DocumentType) => void
  className?: 'string'
}

function DocumentTypeListItem(props: DocumentTypeListItemProps) {
  const { id, image, messageId, onClick, className } = props

  const scan = useContext(ScanContext)
  const router = useContext(RouterContext)

  function handleOnClick(event: React.MouseEvent) {
    event.preventDefault()

    scan.addEvent(AnalyticsEvent.SelectDocumentType, {
      screen: router.current.name,
      button: { type: ClickAction.ListItem, id: messageId }
    })

    if (onClick) {
      onClick(id)
    }
  }

  const buttonClassName = useMemo(() => {
    return `button ${className}`
  }, [className])

  return (
    <button
      type="button"
      onClick={handleOnClick}
      className={buttonClassName}
      data-testid={`document-${id}`}
    >
      {image}

      {messageId && <VerifaiMessage id={messageId} />}
    </button>
  )
}

export default DocumentTypeListItem
