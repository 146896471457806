import styled, { css } from 'styled-components'

// You can give a number and px will be injected, or you can specify the unit: [20, "em"]
const valUnit = v =>
  Array.isArray(v) && v.length === 2
    ? css`
        ${v[0]}${v[1]};
      `
    : css`
        ${v}px;
      `

// Margin mixins
const marginTop = mt =>
  css`
    margin-top: ${valUnit(mt)};
  `
const marginBottom = mb =>
  css`
    margin-bottom: ${valUnit(mb)};
  `
const marginLeft = ml =>
  css`
    margin-left: ${valUnit(ml)};
  `
const marginRight = mr =>
  css`
    margin-right: ${valUnit(mr)};
  `

// Padding mixins
const paddingTop = pt =>
  css`
    padding-top: ${valUnit(pt)};
  `
const paddingBottom = pb =>
  css`
    padding-bottom: ${valUnit(pb)};
  `
const paddingLeft = pl =>
  css`
    padding-left: ${valUnit(pl)};
  `
const paddingRight = pr =>
  css`
    padding-right: ${valUnit(pr)};
  `

// Combine the margin mixins
const margin = ({ mt, mb, ml, mr }) => css`
  ${mt && marginTop(mt)}
  ${mb && marginBottom(mb)}
  ${ml && marginLeft(ml)}
  ${mr && marginRight(mr)}
`

// Combine the padding mixins
const padding = ({ pt, pb, pl, pr }) => css`
  ${pt && paddingTop(pt)}
  ${pb && paddingBottom(pb)}
  ${pl && paddingLeft(pl)}
  ${pr && paddingRight(pr)}
`

// Combine margin and padding
const spaceMixin = props => css`
  ${margin(props)}
  ${padding(props)}
`

// Base components
export const Div = styled.div`
  ${spaceMixin}
`
export const P = styled.p`
  ${spaceMixin}
`
export const B = styled.b`
  ${spaceMixin}
`

export const PSC = styled(P)`
  margin-bottom: ${props => props.marginBottom || 'unset'};
  margin-top: ${props => props.marginTop || 'unset'};
`

export const BSC = styled(B)`
  margin-bottom: ${props => props.marginBottom || 'unset'};
  margin-top: ${props => props.marginTop || 'unset'};
`

export const DivSC = styled(Div)`
  margin-bottom: ${props => props.marginBottom || 'unset'};
  margin-top: ${props => props.marginTop || 'unset'};
`

export const DivFlexSC = styled(Div)`
  display: ${props => props.displaySC || 'flex'};
  justify-content: ${props => props.justifyContent || 'space-between'};
  align-items: ${props => props.alignItems || null};
  flex-direction: ${props => props.flexDirection || 'column'};
  flex-wrap: ${props => props.flexWrap || 'wrap'};
  margin-top: ${props => props.marginTop || null};
  margin-right: ${props => props.marginRight || null};
  margin-left: ${props => props.marginLeft || null};
  margin-bottom: ${props => props.marginBottom || null};
  height: ${props => props.height || null};
`
