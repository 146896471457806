import styled from 'styled-components'

import { FontSize } from 'lib/enums/style'
import { borderRadius, color, defaultSpacing, focusRing, fontSize } from 'src/style/mixins'

export const HiddenInput = styled.input`
  display: none;
`

export const ListWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`

export const ListItem = styled.div`
  font-size: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: ${color('primary')};
  border: 1px solid ${color('primary')};
  margin-bottom: ${defaultSpacing(2)};
  border-radius: ${borderRadius(2)};
  padding: ${defaultSpacing()} ${defaultSpacing(2)};
  height: ${defaultSpacing(7)};

  ${focusRing()}
`

export const ListItemInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RemoveButton = styled.button`
  border: none;
  background: none;
  font-size: ${fontSize(FontSize.Large)};
  color: ${color('primary')};

  ${focusRing()}

  :hover {
    color: ${props => props.theme.colors.bodyErrorText};
  }
`
