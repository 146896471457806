import React, { useContext, useEffect, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import LinkButton from 'components/Button/LinkButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import CropCanvas from 'components/CropCanvas/CropCanvas'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { ImageContext } from 'context/ImageContextProvider'
import useImageProcessing from 'hooks/useImageProcessing'
import usePolling from 'hooks/usePolling'
import { ImageSide } from 'typings/common'
import { Router } from 'typings/context'

import { PreviewWrapperSC } from './ScreenWMQ1.style'

interface ScreenWMQ1Props {
  router: Router
  side: ImageSide
}

function ScreenWMQ1(props: ScreenWMQ1Props) {
  const { router, side } = props

  const config = useContext(ConfigContext)
  const image = useContext(ImageContext)

  const cropCanvasRef = useRef(null)

  const { getImageSuggestions, stopPolling } = usePolling(side)
  const { loadImage } = useImageProcessing(side, cropCanvasRef, null)

  const [isLoading, setIsLoading] = useState(true)

  function handleContinue() {
    stopPolling()

    router.next(side)
  }

  function handleBack() {
    stopPolling()

    router.back()
  }

  useEffect(() => {
    async function setupImage() {
      setIsLoading(true)

      const { cropPoints, rotation } = await getImageSuggestions()
      const imageUrl = await loadImage(side, rotation)

      if (imageUrl) {
        image.update(side, { imageUrl, cropPoints, rotation })
      }

      setIsLoading(false)
    }

    setupImage()
  }, [])

  useEffect(() => {
    image.setSide(side)
  }, [side])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WQ1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WQ1_description" />
        </Description>

        <PreviewWrapperSC>
          {image.imageUrl ? (
            <CropCanvas
              mobileView={config.isMobile}
              ref={cropCanvasRef}
              imageURL={image.imageUrl}
              showPolygon={false}
            />
          ) : (
            <LoadingIndicator />
          )}
        </PreviewWrapperSC>

        <div>
          {!isLoading && <LinkButton messageId="WQ1_button_retry" onClick={() => router.back()} />}
        </div>
      </Body>

      <Footer>
        <BackButton onClick={handleBack} />

        <PrimaryButton
          messageId="WQ1_button_primary"
          onClick={handleContinue}
          disabled={isLoading}
          dataTestId="mobile-result-continue-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWMQ1
