import { PromiseReject, PromiseResolve, PromiseResume } from 'typings/api'
import PromisePoller from 'utils/PromisePoller'

import BaseApi from './BaseApi'

interface Job {
  created: string
  job_id: string
  job_type: string
  status: string
  subject_id: string
}

const jobApi = (api: BaseApi) => ({
  getJobs: () => api.get('session/job'),
  getJob: (jobId: string) => api.get(`session/job/${jobId}`),

  poller: (jobTypes: Array<string>, delay: number, timeout: number, uuid: string | null = null) => {
    return new PromisePoller(
      (resume: PromiseResume, resolve: PromiseResolve<Array<Job>>, reject: PromiseReject) => {
        api
          .get<Array<Job>>('session/job')
          .then(jobs => {
            const filteredJobs =
              !jobTypes || jobTypes.length === 0
                ? jobs
                : jobs.filter(job => jobTypes.includes(job.job_type))

            let finishedJobs = filteredJobs

            if (uuid) {
              // This part is needed due to a user being able to go back and restart the flow.
              // Due to this jobs might get stuck on a `created` status and the SDK will never stop loading.
              finishedJobs = filteredJobs.filter(job => job.subject_id === uuid)
            }

            const isFinished = finishedJobs.every(job => job.status === 'completed')

            return isFinished ? resolve(jobs) : resume()
          })
          .catch(reject)
      },
      delay,
      timeout
    )
  }
})

export default jobApi
