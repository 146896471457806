import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from '../BaseComponent/BaseComponent'
import { VerifaiMessage } from '../Text/VerifaiIntl'

import { ReasonBoxSC } from './ErrorReasonBox.style'

class ErrorReasonBox extends BaseComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const types = [...new Set(this.props.reasons.map(r => r.type))]

    return (
      <ReasonBoxSC>
        <ul>
          {types.map((type, index) => (
            <li key={index}>
              <p>
                <VerifaiMessage id={`DNA_reason_${type}`} />
              </p>
            </li>
          ))}
        </ul>
      </ReasonBoxSC>
    )
  }
}

ErrorReasonBox.defaultProps = {}

ErrorReasonBox.propTypes = {
  reasons: PropTypes.array
}

export default ErrorReasonBox
