import React from 'react'
import { fabric } from 'fabric'
import PropTypes from 'prop-types'

import BaseComponent from '../BaseComponent/BaseComponent'

import { CropCanvasSC } from './CropCanvas.style'

class CropCanvas extends BaseComponent {
  constructor(props) {
    super(props)

    const showPolygon = this.props.showPolygon !== undefined ? this.props.showPolygon : true

    this.state = {
      image: null,
      canvas: null,
      polygon: null,
      showPolygon
    }

    this.backgroundColor = '#d3d3d3'
    this.containerElement = React.createRef()
  }

  componentDidUpdate(prevProps) {
    // If image changes then redraw the canvas
    if (prevProps.imageURL !== this.props.imageURL) {
      this.loadImage(this.state.canvas)
    }
  }

  componentDidMount() {
    const canvas = new fabric.Canvas('canvas')

    canvas.backgroundColor = this.backgroundColor

    const container = this.containerElement.current

    this.setState({
      canvas
    })

    // Add operation at the end of stack, to ensure that containerElement has been already properly rendered
    setTimeout(() => {
      // Make canvas fill the width and height
      canvas.setWidth(container.offsetWidth)
      canvas.setHeight(container.offsetHeight)
      // Load and draw image
      this.loadImage(canvas)
    }, 0)

    setTimeout(() => {
      // This fixes a Chrome issue where if you load the page at 110%, the upper and lower canvas het out of
      // Sync. This detects that problem and resets it.
      const lowerCanvas = document.querySelector('.canvas-container canvas.lower-canvas')
      const upperCanvas = document.querySelector('.canvas-container canvas.upper-canvas')

      if (lowerCanvas.getAttribute('height') !== upperCanvas.getAttribute('height')) {
        upperCanvas.setAttribute('width', container.offsetWidth)
        upperCanvas.setAttribute('height', container.offsetHeight)
        lowerCanvas.setAttribute('width', container.offsetWidth)
        lowerCanvas.setAttribute('height', container.offsetHeight)

        // Trigger a redraw
        canvas.renderAll()
      }
    }, 100)
  }

  rotate() {
    const canvasCenter = new fabric.Point(this.state.canvas.width / 2, this.state.canvas.height / 2) // Center of canvas
    const rads = 0.174532925 // 10 degrees in radians

    this.state.canvas.getObjects().forEach(function (obj) {
      const objectOrigin = new fabric.Point(obj.left, obj.top)
      const newLoc = fabric.util.rotatePoint(objectOrigin, canvasCenter, rads)

      obj.top = newLoc.y
      obj.left = newLoc.x
      obj.angle += 10 // Rotate each object buy the same angle
    })
    this.state.canvas.renderAll()
  }

  loadImage(canvas) {
    const url = this.props.imageURL

    if (!url) {
      return
    }

    const image = new Image()

    canvas.remove(...canvas.getObjects())

    // When the image has loaded, draw it to the canvas
    image.onload = () => {
      const backgroundImage = new fabric.Image(image, {
        selectable: false,
        evented: false
      })

      const frontImage = new fabric.Image(image, {
        selectable: false,
        evented: false
      })

      // Scale the background image to fit the canvas width
      const ratio = frontImage.width / frontImage.height
      let newWidth = canvas.width
      let newHeight = newWidth / ratio

      if (newHeight > canvas.height) {
        newHeight = canvas.height
        newWidth = newHeight * ratio
      }

      frontImage.scaleToWidth(newWidth)
      frontImage.scaleToHeight(newHeight)
      backgroundImage.scaleToWidth(newWidth)
      backgroundImage.scaleToHeight(newHeight)

      this.setState({
        image: frontImage
      })

      // Add background to the canvas
      canvas.add(backgroundImage)
      // Creates shadow outside of the selection
      this.drawBackgroundColor(canvas, frontImage.getScaledWidth(), frontImage.getScaledHeight())
      // Add front image to the canvas
      canvas.add(frontImage)

      if (this.state.showPolygon) {
        this.drawPolygon(canvas, frontImage)
      }

      // Get scaling for display
      const retinaScaling = canvas.getRetinaScaling()

      this.setClipping(retinaScaling)

      backgroundImage.center()
      frontImage.center()
      canvas.renderAll()
    }

    image.src = url
  }

  drawBackgroundColor(canvas, imageWidth, imageHeight) {
    // Draw dark shadow over the image area
    const rectangle = new fabric.Rect({
      left: 0,
      top: 0,
      width: imageWidth,
      height: imageHeight,
      fill: 'rgba(0,0,0,0.5)',
      strokeWidth: 0,
      selectable: false,
      evented: false
    })

    canvas.add(rectangle)
    rectangle.center()
  }

  drawPolygon(canvas, image) {
    // TODO: when in production use `this.props.suggestedPoints` to use the points returned by the crop job
    let suggestedPoints = [
      { x: 0.1, y: 0.1 },
      { x: 0.1, y: 0.9 },
      { x: 0.9, y: 0.9 },
      { x: 0.9, y: 0.1 }
    ]

    if (this.props.suggestedPoints) {
      suggestedPoints = this.props.suggestedPoints
    }

    let radius = 5

    if (this.props.mobileView) {
      radius = 12
    }

    const convertedPoints = this.convertPercentagesPointsToPix(suggestedPoints, image)
    const points = this.sortPoints(convertedPoints)
    const offsetWidth = (canvas.getWidth() - image.getScaledWidth()) / 2
    const offsetHeight = (canvas.getHeight() - image.getScaledHeight()) / 2

    this.correctOffsetPoint(points, offsetWidth, offsetHeight)

    const options = {
      selectable: false,
      objectCaching: false,
      stroke: 'yellow',
      strokeWidth: 2,
      fill: 'rgba(0,0,0,0)'
    }

    const lines = [
      this.calculateIntermediatePoint(points[0], points[1], 1 / 3).concat(
        this.calculateIntermediatePoint(points[3], points[2], 1 / 3)
      ),
      this.calculateIntermediatePoint(points[0], points[1], 2 / 3).concat(
        this.calculateIntermediatePoint(points[3], points[2], 2 / 3)
      ),
      this.calculateIntermediatePoint(points[0], points[3], 1 / 3).concat(
        this.calculateIntermediatePoint(points[1], points[2], 1 / 3)
      ),
      this.calculateIntermediatePoint(points[0], points[3], 2 / 3).concat(
        this.calculateIntermediatePoint(points[1], points[2], 2 / 3)
      )
    ]

    const gridLines = []

    lines.forEach(function (line, index) {
      gridLines[index] = new fabric.Line(line, {
        stroke: 'rgba(255, 255, 255, 0.5)',
        strokeWidth: 1,
        selectable: false
      })
      canvas.add(gridLines[index])
    })

    const polygon = new fabric.Polygon(points, options)

    canvas.add(polygon)

    if (this.props.canEdit) {
      // Create dragging circles for the polygon
      points.forEach(function (point, index) {
        const circle = new fabric.Circle({
          originX: 'center',
          originY: 'center',
          left: point.x,
          top: point.y,
          strokeWidth: 1,
          radius,
          fill: '#fff',
          stroke: '#0084C8',
          hasBorders: false,
          hasControls: false,
          name: index,
          padding: 40
        })

        canvas.add(circle)
      })
    }

    // Handle user moving the edges of the polygon
    canvas.on('object:moving', options => {
      const p = options.target
      const centerPoint = p.getPointByOrigin()

      // Make sure the edges don't leave the canvas
      const correctedPoint = this.correctPoint(
        centerPoint.x,
        centerPoint.y,
        canvas.getWidth(),
        canvas.getHeight()
      )

      p.left = correctedPoint.x
      p.top = correctedPoint.y
      polygon.points[p.name] = correctedPoint

      // Check whether the polygon is still convex
      this.props.convexityChanged(this.checkConvexity())

      if (this.props.onPointDrag) {
        this.props.onPointDrag(this.getSelectionPoints())
      }

      // Update grid
      const that = this

      gridLines.forEach(function (gridLine, index) {
        let coords = []
        const { points } = polygon

        switch (index) {
          case 0:
            coords = that
              .calculateIntermediatePoint(points[0], points[1], 1 / 3)
              .concat(that.calculateIntermediatePoint(points[3], points[2], 1 / 3))
            break
          case 1:
            coords = that
              .calculateIntermediatePoint(points[0], points[1], 2 / 3)
              .concat(that.calculateIntermediatePoint(points[3], points[2], 2 / 3))
            break
          case 2:
            coords = that
              .calculateIntermediatePoint(points[0], points[3], 1 / 3)
              .concat(that.calculateIntermediatePoint(points[1], points[2], 1 / 3))
            break
          case 3:
            coords = that
              .calculateIntermediatePoint(points[0], points[3], 2 / 3)
              .concat(that.calculateIntermediatePoint(points[1], points[2], 2 / 3))
            break
          default:
            coords = [0, 0, 50, 50]
        }
        gridLine.set({ x1: coords[0], y1: coords[1], x2: coords[2], y2: coords[3] })
      })
    })

    this.setState({
      polygon
    })
  }

  /**
   * Image is centered in the middle of the canvas, thus crop points need to be offseted
   * @param points
   * @param offset
   */
  correctOffsetPoint(points, offsetWidth, offsetHeight) {
    points.map(point => {
      point.x += offsetWidth
      point.y += offsetHeight

      return point
    })
  }

  /**
   * Calculates a point between two other points
   * @param point1
   * @param point2
   * @param factor like 1/3 or 0.5
   * @returns {*[]}
   */
  calculateIntermediatePoint(point1, point2, factor) {
    return [point1.x + (point2.x - point1.x) * factor, point1.y + (point2.y - point1.y) * factor]
  }

  /**
   * Corrects a point if it is outside of the boundaries
   */
  correctPoint(x, y, canvasWidth, canvasHeight) {
    // Make sure the points are within boundaries
    if (x < 0) {
      x = 0
    } else if (x > canvasWidth) {
      x = canvasWidth
    }

    if (y < 0) {
      y = 0
    } else if (y > canvasHeight) {
      y = canvasHeight
    }

    return { x, y }
  }

  /**
   * Converts points in percentage format into exact pixels points
   */
  convertPercentagesPointsToPix(points, image) {
    const width = image.getScaledWidth()
    const height = image.getScaledHeight()
    const correctedPoints = []

    for (let i = 0; i < points.length; i++) {
      const point = points[i]
      const correctedPoint = this.convertPercentagesPointToPix(point, width, height)

      correctedPoints.push(correctedPoint)
    }

    return correctedPoints
  }

  /**
   * Converts point in percentage format into exact pixels points
   */
  convertPercentagesPointToPix(point, width, height) {
    return {
      x: point.x * width,
      y: point.y * height
    }
  }

  /**
   * Sorts points and returns them in order -> top left, top right, bottom right, bottom left
   */
  sortPoints(points) {
    function sortPointsByY(a, b) {
      return a.y < b.y ? -1 : a.y > b.y ? 1 : 0
    }

    const sortedPoints = points.sort((a, b) => {
      return a.x < b.x ? -1 : a.x > b.x ? 1 : 0
    })

    const leftPoints = sortedPoints.slice(0, 2).sort(sortPointsByY)
    const rightPoints = sortedPoints.slice(2, 4).sort(sortPointsByY)

    return [leftPoints[0], rightPoints[0], rightPoints[1], leftPoints[1]]
  }

  setClipping(retinaScaling) {
    const { image } = this.state
    const clipObj = this.state.polygon

    if (image) {
      // Clip image to the area of polygon
      image.clipTo = ctx => {
        ctx.save()
        ctx.setTransform(retinaScaling, 0, 0, retinaScaling, 0, 0)
        clipObj.render(ctx)
        ctx.restore()
      }
    }
  }

  /**
   * Returns polygon's points in percentage format
   */
  getSelectionPoints() {
    const { polygon } = this.state
    const { image } = this.state
    const { canvas } = this.state

    const width = image.getScaledWidth()
    const height = image.getScaledHeight()
    const offsetWidth = (canvas.getWidth() - image.getScaledWidth()) / 2
    const offsetHeight = (canvas.getHeight() - image.getScaledHeight()) / 2

    return polygon.points
      .filter(p => this.validPoint(p))
      .map(p => ({
        x: (p.x - offsetWidth) / width,
        y: (p.y - offsetHeight) / height
      }))
      .slice(0, 4) // Make triple sure we return only 4 points!
  }

  validPoint(point) {
    return typeof point.x === 'number' && typeof point.y === 'number'
  }

  /**
   * Checks whether the polygon is convex
   */
  checkConvexity() {
    const size = this.state.polygon.points.length
    const points = this.state.polygon.points.slice()

    for (let i = 0; i < size; i++) {
      const angle = this.getAngleForPoint(i, points)

      if (angle >= 0) {
        return false
      }
    }

    return true
  }

  getAngleForPoint(pointIndex, points) {
    const size = points.length
    const prevPointIndex = (((pointIndex - 1) % size) + size) % size
    const nextPointIndex = (pointIndex + 1) % size

    return this.calculateAngle(points[prevPointIndex], points[pointIndex], points[nextPointIndex])
  }

  /**
   * Calculates angle between 3 points
   */
  calculateAngle(prevPoint, centerPoint, nextPoint) {
    const lineA = {
      x: centerPoint.x - prevPoint.x,
      y: centerPoint.y - prevPoint.y
    }

    const lineB = {
      x: centerPoint.x - nextPoint.x,
      y: centerPoint.y - nextPoint.y
    }

    const dot = lineA.x * lineB.x + lineA.y * lineB.y
    const cross = lineA.x * lineB.y - lineA.y * lineB.x

    return Math.atan2(cross, dot)
  }

  render() {
    return (
      <CropCanvasSC ref={this.containerElement}>
        <canvas id="canvas" />
      </CropCanvasSC>
    )
  }
}

CropCanvas.defaultProps = {
  canEdit: true
}

CropCanvas.propTypes = {
  suggestedPoints: PropTypes.arrayOf(PropTypes.object),
  convexityChanged: PropTypes.func,
  imageURL: PropTypes.string,
  canEdit: PropTypes.bool,
  onPointDrag: PropTypes.func
}

export default CropCanvas
