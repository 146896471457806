import styled from 'styled-components'

import { defaultSpacing } from 'src/style/mixins'

export const HiddenInput = styled.input`
  display: none;
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const ImageWrapper = styled.div`
  flex-grow: 1;
`

export const RetryWrapper = styled.div`
  flex-grow: 0;
  margin-top: ${defaultSpacing()};
`
