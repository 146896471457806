import React, { useContext } from 'react'

import CancelFlowButton from 'components/CancelFlowButton/CancelFlowButton'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import Modal from 'components/Modal/Modal'
import { ScreenWrapperSC } from 'components/Screen/Screen.style'
import SessionTimeOut from 'components/SessionTimeOut/SessionTimeOut'
import Transition from 'components/Transition/Transition'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'

function VerifaiWrapper() {
  const config = useContext(ConfigContext)
  const router = useContext(RouterContext)

  return (
    <Modal>
      <ScreenWrapperSC isMobile={config.isMobile} isRTL={config.rightToLeftScript}>
        <ErrorBoundary>
          <CancelFlowButton show={config.closable} />

          <SessionTimeOut />

          <Transition currentScreen={router.current} isBack={router.wasBackAction} />
        </ErrorBoundary>
      </ScreenWrapperSC>
    </Modal>
  )
}

export default VerifaiWrapper
