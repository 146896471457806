import React, { useEffect, useMemo, useRef, useState } from 'react'

import PrimaryButton from 'components/Button/PrimaryButton'

import * as S from './DropFile.style'

interface DropFileProps {
  onDrop: (files: FileList) => void
  isUploading: boolean
  accept?: Array<string>
}

function DropFile(props: DropFileProps) {
  const { onDrop, isUploading, accept = [] } = props

  const dropAreaRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [isDragging, setIsDragging] = useState(false)

  function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      onDrop(event.target.files)
    }
  }

  function handleUploadButtonPressed() {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const acceptedFileTypes = useMemo(() => {
    return accept.join(', ')
  }, [accept])

  useEffect(() => {
    function handleDragEvent(event: DragEvent, dragValue?: boolean) {
      event.preventDefault()
      event.stopPropagation()

      if (dragValue !== undefined && !isUploading) {
        setIsDragging(dragValue)
      }
    }

    function handleWindowDragEnter(event: DragEvent) {
      handleDragEvent(event, true)
    }

    function handleDragEnter(event: DragEvent) {
      handleDragEvent(event, true)
    }

    function handleDragLeave(event: DragEvent) {
      handleDragEvent(event, false)
    }

    function handleDrop(event: DragEvent) {
      handleDragEvent(event, false)

      if (event.dataTransfer) {
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
          onDrop(event.dataTransfer.files)

          event.dataTransfer.clearData()
        }
      }
    }

    const dropElement = dropAreaRef.current

    if (dropElement) {
      dropElement.addEventListener('drop', handleDrop)
      dropElement.addEventListener('dragenter', handleDragEnter)
      dropElement.addEventListener('dragleave', handleDragLeave)
      dropElement.addEventListener('dragover', handleDragEvent)
    }

    window.addEventListener('dragenter', handleWindowDragEnter)

    return function cleanup() {
      if (dropElement) {
        dropElement.removeEventListener('drop', handleDrop)
        dropElement.removeEventListener('dragenter', handleDragEnter)
        dropElement.removeEventListener('dragleave', handleDragLeave)
        dropElement.removeEventListener('dragover', handleDragEvent)
      }

      window.removeEventListener('dragenter', handleWindowDragEnter)
    }
  }, [isUploading, onDrop])

  return (
    <S.DropContainer>
      <S.DropZone ref={dropAreaRef} isDragging={isDragging} />

      <S.Input
        type="file"
        name="file"
        accept={acceptedFileTypes}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />

      <S.ButtonContainer>
        <PrimaryButton
          messageId="WC2_dragdrop_button"
          onClick={handleUploadButtonPressed}
          isLoading={isUploading}
        />
      </S.ButtonContainer>
    </S.DropContainer>
  )
}

export default DropFile
