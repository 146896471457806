import React from 'react'

import { DescriptionSC } from './VerifaiDescription.style'

interface VerifaiDescriptionProps {
  children: React.ReactNode
}

function VerifaiDescription(props: VerifaiDescriptionProps) {
  const { children } = props

  return <DescriptionSC>{children}</DescriptionSC>
}

export default VerifaiDescription
