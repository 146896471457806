import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { ScanContext } from 'context/ScanContextProvider'
import { DocumentImage, DocumentType, Side } from 'lib/enums/common'
import { IconId, ImageSide } from 'typings/common'

import * as S from './DocumentExample.style'

function useDocumentExample(side: ImageSide) {
  const { documentType } = useContext(ScanContext)

  const [imageSide, setImageSide] = useState(Side.Front)
  const [shouldAnimate, setShouldAnimate] = useState(side === Side.Back)

  useEffect(() => {
    if (documentType?.id === DocumentType.Passport) {
      setImageSide(side)
      setShouldAnimate(false)
    } else {
      setImageSide(Side.Front)
      setShouldAnimate(side === Side.Back)
    }
  }, [side, documentType])

  return { imageSide, setImageSide, shouldAnimate, setShouldAnimate }
}

interface AnimatedDocumentProps {
  side: ImageSide
  imageId: IconId
}

function AnimatedDocument(props: AnimatedDocumentProps) {
  const { side, imageId } = props

  if (side === Side.Front) {
    return <S.DocumentFront id={imageId} isDecorative />
  }

  return <S.DocumentBack id={imageId} isDecorative />
}

interface DocumentExampleProps {
  side: ImageSide
}

function DocumentExample(props: DocumentExampleProps) {
  const { side } = props

  const scan = useContext(ScanContext)

  const { imageSide, setImageSide, shouldAnimate, setShouldAnimate } = useDocumentExample(side)

  const imageRef = useRef(null)

  const imageId = useMemo(() => {
    if (scan.documentType) {
      const { documentType } = scan

      if (documentType.id === DocumentType.Passport) {
        if (imageSide === Side.Front) {
          return DocumentImage.Passport
        }

        return DocumentImage.PassportBack
      }

      if (documentType.id === DocumentType.DriversLicense) {
        if (imageSide === Side.Front) {
          return DocumentImage.DriversLicenseFront
        }

        return DocumentImage.DriversLicenseBack
      }

      if (imageSide === Side.Front) {
        return DocumentImage.IdCardFront
      }

      return DocumentImage.IdCardBack
    }

    return DocumentImage.IdCardFront
  }, [imageSide, scan])

  const documentElement = useMemo(() => {
    if (shouldAnimate) {
      return (
        <S.ImageWrapper ref={imageRef}>
          <AnimatedDocument side={imageSide} imageId={imageId} />
        </S.ImageWrapper>
      )
    }

    if (scan.documentType?.id === DocumentType.Passport && side === Side.Front) {
      return (
        <S.PassportExample>
          <S.DefaultExample id={imageId} isDecorative />
        </S.PassportExample>
      )
    }

    return (
      <S.SvgWrapper>
        <S.DefaultExample id={imageId} isDecorative />
      </S.SvgWrapper>
    )
  }, [imageId, shouldAnimate, imageSide, scan, side])

  useEffect(() => {
    function handleEnd() {
      if (imageSide === Side.Front) {
        setImageSide(Side.Back)
      } else {
        setShouldAnimate(false)
      }
    }

    const imageWrapper = imageRef.current

    if (imageWrapper && shouldAnimate) {
      ;(imageWrapper as HTMLElement).addEventListener('animationend', handleEnd)
    }

    return function cleanup() {
      if (imageWrapper) {
        ;(imageWrapper as HTMLElement).removeEventListener('animationend', handleEnd)
      }
    }
  }, [imageRef, imageSide, setImageSide, scan, shouldAnimate, setShouldAnimate])

  return <S.ExampleContainer>{documentElement}</S.ExampleContainer>
}

export default DocumentExample
