import React from 'react'
import { fabric } from 'fabric'
import PropTypes from 'prop-types'

import LinkButton from 'components/Button/LinkButton'

import BaseComponent from '../BaseComponent/BaseComponent'

import { AdjustZonesContainer, AdjustZonesWrapper } from './AdjustZones.style'

class AdjustZones extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      canvas: null,
      scaledWidth: 0,
      scaledHeight: 0,
      zoneFillColor: '#292929',
      zoneFillSelectedColor: '#203D4C',
      scaleBy: 'width'
    }

    this.container = React.createRef()
    this.resetCanvas = this.resetCanvas.bind(this)
    this.updateFabricSettings()
  }

  updateFabricSettings() {
    fabric.Object.prototype.selectionColor = 'yellow'
    fabric.Object.prototype.borderColor = 'yellow'
    fabric.Object.prototype.cornerSize = 7
    fabric.Object.prototype.cornerStrokeColor = '#0084C8'
    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.cornerColor = '#fff'
    fabric.Object.prototype.padding = -1
    fabric.Object.prototype.cornerStyle = 'circle'
    fabric.Object.prototype.selectionBackgroundColor = '#FFFF00'
  }

  componentDidMount() {
    const canvas = new fabric.Canvas('canvas')
    const container = this.container.current

    canvas.uniScaleTransform = true
    canvas.selection = false

    this.setState({
      canvas
    })

    // Add operation at the end of stack, to ensure that containerElement has been already properly rendered
    setTimeout(() => {
      // Make canvas fills the screen
      const scaleBy = container.offsetWidth < container.offsetHeight ? 'width' : 'height'

      if (scaleBy === 'width') {
        canvas.setWidth(container.offsetWidth)
      } else {
        canvas.setHeight(container.offsetHeight)
      }

      // Draw image
      this.loadImage(canvas, scaleBy)
    }, 0)
  }

  resetCanvas() {
    const { canvas } = this.state
    const zones = canvas.getObjects().filter(obj => {
      return obj.isZone === true
    })

    zones.forEach(zone => {
      canvas.remove(zone)
    })

    this.drawZones(canvas)
  }

  loadImage(canvas, scaleBy) {
    const url = this.props.imageSrc
    const img = new Image()

    // When the image has loaded, draw it to the canvas
    img.onload = () => {
      const image = new fabric.Image(img, {
        selectable: false,
        evented: false
      })

      // Scale the background image to fit the canvas width
      if (scaleBy === 'width') {
        image.scaleToWidth(canvas.getWidth())
        canvas.setHeight(image.getScaledHeight())
      } else {
        image.scaleToHeight(canvas.getHeight())
        canvas.setWidth(image.getScaledWidth())
      }

      canvas.add(image)
      canvas.sendToBack(image)
      canvas.renderAll()

      // Store image size and then draw zones
      this.setState(
        {
          scaledWidth: image.getScaledWidth(),
          scaledHeight: image.getScaledHeight()
        },
        () => {
          this.drawZones(canvas)
        }
      )
    }
    img.src = url
  }

  drawZones(canvas) {
    const { scaledWidth } = this.state
    const { scaledHeight } = this.state
    let selectionZone = false

    this.props.zones.forEach(zone => {
      const rect = new fabric.Rect({
        left: zone.x * scaledWidth,
        top: zone.y * scaledHeight,
        width: zone.width * scaledWidth,
        height: zone.height * scaledHeight,
        fill: this.state.zoneFillColor,
        hasRotatingPoint: false,
        isZone: true,
        surface: zone.width * scaledWidth * (zone.height * scaledHeight),
        rx: 5,
        ry: 5,
        originalData: zone
      })

      rect.on('selected', () => {
        const activeObjects = canvas.getActiveObjects()

        activeObjects.forEach(item => {
          item.fill = this.state.zoneFillSelectedColor
          item.dirty = true
        })
        canvas.requestRenderAll()
      })
      rect.on('deselected', () => {
        const oldSelectionZones = canvas.getObjects().filter(obj => {
          return obj.isZone === true && obj.fill !== this.state.zoneFillColor
        })

        oldSelectionZones.forEach(item => {
          item.fill = this.state.zoneFillColor
          item.dirty = true
        })
        canvas.requestRenderAll()
      })
      canvas.add(rect)

      // Biggest surface gets selected by default
      if (!selectionZone || selectionZone.surface < rect.surface) {
        selectionZone = rect
      }
    })

    canvas.renderAll()

    if (selectionZone) {
      canvas.setActiveObject(selectionZone)
    }
    canvas.requestRenderAll()
  }

  getZones() {
    const { canvas } = this.state
    const { scaledWidth } = this.state
    const { scaledHeight } = this.state
    const zones = canvas.getObjects().filter(obj => {
      return obj.isZone === true
    })

    return zones.reduce((acc, zoneObj) => {
      const zone = {
        x: zoneObj.left / scaledWidth,
        y: zoneObj.top / scaledHeight,
        width: zoneObj.getScaledWidth() / scaledWidth,
        height: zoneObj.getScaledHeight() / scaledHeight,
        title: zoneObj.originalData.title,
        side: zoneObj.originalData.side,
        block: zoneObj.originalData.block
      }

      acc.push(zone)

      return acc
    }, [])
  }

  render() {
    return (
      <AdjustZonesWrapper>
        <AdjustZonesContainer ref={this.container}>
          <canvas id="canvas" />
        </AdjustZonesContainer>

        <LinkButton messageId="WG2_button_reset" onClick={this.resetCanvas} />
      </AdjustZonesWrapper>
    )
  }
}

AdjustZones.propTypes = {
  imageSrc: PropTypes.string,
  zones: PropTypes.arrayOf(PropTypes.object)
}

export default AdjustZones
