import styled, { keyframes } from 'styled-components'

import Icon from 'components/Image/Icon'
import { Breakpoint } from 'lib/enums/style'
import { defaultSpacing } from 'src/style/mixins'

const flipFront = keyframes`
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(-90deg);
  }
`

const flipBack = keyframes`
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
`

export const ImageWrapper = styled.div`
  perspective: 20cm;
`

export const DefaultExample = styled(Icon)`
  width: ${defaultSpacing(35)};
`

export const DocumentFront = styled(DefaultExample)`
  animation: ${flipFront} 0.75s ease-in;
  animation-delay: 0.75s;
`

export const DocumentBack = styled(DefaultExample)`
  animation: ${flipBack} 0.75s ease-out;
`

const passportZoom = keyframes`
  from {
    transform: translate(0px, 0px) scale(1);
  }

  to {
    transform: translate(0px, -95px) scale(1.40);
  }
`

export const PassportExample = styled.div`
  overflow: hidden;

  svg {
    animation: ${passportZoom} 0.75s ease-in-out forwards;
    animation-delay: 0.75s;
  }
`

export const SvgWrapper = styled.div`
  svg {
    fill: ${props => props.theme.colors.imageForeground};
    color: ${props => props.theme.colors.imageBackground};
  }
`

export const ExampleContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${Breakpoint.Medium}px) {
    margin-top: ${defaultSpacing(4)};
  }
`
