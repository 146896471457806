import React from 'react'

import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ClickAction } from 'lib/enums/common'

import { ButtonLinkSC } from './VerifaiButton.style'

interface LinkButtonProps {
  messageId: string
  onClick: () => void
  dataTestId?: string
}

function LinkButton(props: LinkButtonProps) {
  const { messageId, onClick, dataTestId } = props

  return (
    <ButtonLinkSC
      onClick={onClick}
      eventData={{ action: ClickAction.Secondary, id: messageId }}
      dataTestId={dataTestId}
    >
      <VerifaiMessage id={messageId} />
    </ButtonLinkSC>
  )
}

export default LinkButton
