import Webcam from 'react-webcam'
import styled from 'styled-components'

import { FontSize } from 'lib/enums/style'
import { borderRadius, color, defaultSpacing, fontSize, scrollbar } from 'src/style/mixins'

export const WebcamView = styled.div`
  height: 350px;
  max-height: 400px;
  max-width: 575px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  flex-grow: 1;

  ${scrollbar()}

  > video {
    width: 100%;
    height: 100%;
  }

  > button {
    margin-bottom: -40px;
    z-index: 2001;
  }
`

export const NoWebcamWarning = styled.div`
  background-color: ${color('inputInvalid')};
  border-radius: ${borderRadius()};
  vertical-align: middle;
  padding: ${defaultSpacing(2)};
`

export const WebcamWrapperSC = styled.div`
  height: 100%;
  width: 100%;
  align-content: center;
  overflow: hidden;
  display: flex;
  background-color: #1c1c1c;
`

export const CameraTitleSC = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  text-align: center;
  padding: ${defaultSpacing()};
  z-index: 2000;

  > h1 {
    margin: 0;
    font-size: ${fontSize(FontSize.Large)};
    font-weight: normal;
  }
`

export const CameraControlSC = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2000;
  bottom: 0;
`

export const WebcamSC = styled(Webcam)`
  position: relative;
  transform: translateX(-50%);
  left: 50%;
`

export const PermissionOverlaySC = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3000;
  background: white;
  bottom: 0;
`

export const DeviceOverlaySC = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3000;
  background: white;
  bottom: 0;
`

export const ProcessingOverlaySC = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  left: 0;
  z-index: 3000;
`

export const DocumentOverlaySC = styled.div`
  position: absolute;
  z-index: 2000;
  border-radius: ${borderRadius()};
  border: 4px solid white;
`
