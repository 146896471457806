import styled, { css } from 'styled-components'

import { defaultSpacing } from 'src/style/mixins'

export const DropContainer = styled.div`
  padding: ${defaultSpacing()};
`

export const DropZone = styled.div<{ isDragging: boolean }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  ${({ isDragging }) =>
    isDragging &&
    css`
      pointer-events: all;
      background-color: #757575;
      opacity: 0.3;
    `}
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: ${defaultSpacing(2)};
  }

  p {
    margin-top: ${defaultSpacing(2)};
    margin-bottom: ${defaultSpacing(2)};
  }
`

export const Input = styled.input`
  display: none;
`
