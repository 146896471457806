import React from 'react'
import { useIntl } from 'react-intl'

import { Icon } from 'components/Image/Icon'
import { ClickAction, Direction } from 'lib/enums/common'

import * as S from './VerifaiButton.style'

interface RotateButtonProps {
  direction: Direction
  onClick: (direction: Direction) => void
  isDisabled?: boolean
}

function RotateButton(props: RotateButtonProps) {
  const { onClick, direction, isDisabled } = props

  const intl = useIntl()

  function handleClick() {
    onClick(direction)
  }

  return (
    <S.RotateButton
      onClick={handleClick}
      eventData={{ action: ClickAction.Rotate }}
      disabled={isDisabled}
      direction={direction}
      ariaLabel={intl.formatMessage({ id: `WD1_button_rotate_${direction}` })}
    >
      <Icon id="rotate" />
    </S.RotateButton>
  )
}

export default RotateButton
