import styled, { css } from 'styled-components'

export const ScreenWrapperSC = styled.div<{ isRTL: boolean; isMobile: boolean }>`
  @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');

  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  width: 677px;
  min-height: 733px;
  outline: 0;
  align-content: center;

  ${({ isRTL }) =>
    isRTL &&
    css`
      direction: rtl;
      text-align: right;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      border-radius: revert;
      min-height: unset;
      width: unset;
      height: calc(100% - 30px); /* Full screen - promo component */
    `}
`

export const ScreenSC = styled.div<{ hasError: boolean; isMobile: boolean }>`
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background-color: ${props => props.theme.colors.modalBackground};

  ${({ hasError }) =>
    hasError &&
    css`
      position: absolute;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: calc(100% - 30px);
    `}
`
