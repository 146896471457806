import React, { useContext, useRef } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import { ButtonLinkSC } from 'components/Button/VerifaiButton.style'
import CropCanvas from 'components/CropCanvas/CropCanvas'
import Description from 'components/Description/VerifaiDescription'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'

interface CheckResultProps {
  file: File
  onCancel: () => void
  onContinue: () => void
  onRetry?: () => void
}

function CheckResult(props: CheckResultProps) {
  const { file, onCancel, onContinue, onRetry } = props

  const config = useContext(ConfigContext)

  const cropCanvasRef = useRef(null)

  return (
    <Screen>
      <Heading>
        <Header messageKey="WQ1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WQ1_description" />
        </Description>

        <CropCanvas
          mobileView={config.isMobile}
          ref={cropCanvasRef}
          imageURL={URL.createObjectURL(file)}
          showPolygon={false}
        />

        <div>
          <ButtonLinkSC onClick={onRetry || onCancel}>
            <VerifaiMessage id="WQ1_button_retry" />
          </ButtonLinkSC>
        </div>
      </Body>

      <Footer>
        <BackButton onClick={onCancel} />

        <PrimaryButton messageId="generic_button_continue" onClick={onContinue} />
      </Footer>
    </Screen>
  )
}

export default CheckResult
