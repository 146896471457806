import styled from 'styled-components'

import { ScreenSC } from '../Screen/Screen.style'

export const TransitionSC = styled(ScreenSC)`
  position: absolute;

  &.${({ prefix }) => prefix}-enter {
    transform: translateX(100%);
    z-index: 1001;
  }

  &.${({ prefix }) => prefix}-enter.${({ prefix }) => prefix}-active-enter {
    transform: translateX(0);
    transition: transform ${({ duration }) => duration}ms ease-out;
    z-index: 1001;
  }

  &.${({ prefix }) => prefix}-exit {
    transform: translateX(0);
    z-index: 1001;
  }

  &.${({ prefix }) => prefix}-exit.${({ prefix }) => prefix}-active-exit {
    transform: translateX(100%);
    transition: transform ${({ duration }) => duration}ms ease-out;
    z-index: 1001;
  }
`
