import styled, { css } from 'styled-components'

import { color, defaultSpacing } from 'src/style/mixins'

// Each of these images has the same size so the aspect ratio can be calculated manually.
const ASPECT_RATIO = 16 / 9

// eslint-disable-next-line import/prefer-default-export
export const InstructionImageSC = styled.img<{ isTable: boolean }>`
  width: ${defaultSpacing(35)};
  height: ${defaultSpacing(35 / ASPECT_RATIO)};
  background-color: ${color('uiBackground')};

  ${({ isTable }) =>
    isTable &&
    css`
      width: ${defaultSpacing(15)};
      height: ${defaultSpacing(15 / ASPECT_RATIO)};
    `}
`
