import React, { useContext, useEffect, useRef } from 'react'

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Screen from 'components/Screen/Screen'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { DEFAULT_JOB_DELAY, POLL_TIMEOUT_LONG } from 'lib/constants'
import PromisePoller from 'utils/PromisePoller'

/**
 * Screen WZ1 is the final screen.
 * It shows a spinner until all running jobs are finished.
 */
function ScreenWZ1() {
  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const pollerRef = useRef<PromisePoller>(null)

  async function pollJobs() {
    const poller = pollerRef.current

    if (poller) {
      poller.reset()
    }

    const newPoller = await api.job.poller([], DEFAULT_JOB_DELAY, POLL_TIMEOUT_LONG, scan.imageId)

    pollerRef.current = newPoller

    await newPoller.run()

    router.next()
  }

  useEffect(() => {
    pollJobs()
  }, [])

  useEffect(() => {
    const poller = pollerRef.current

    return function cleanup() {
      if (poller) {
        poller.reset()
      }
    }
  }, [])

  return (
    <Screen>
      <LoadingIndicator />
    </Screen>
  )
}

export default ScreenWZ1
