import React, { useContext, useEffect, useRef, useState } from 'react'

import AdjustZones from 'components/AdjustZones/AdjustZones'
import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { Side } from 'lib/enums/common'
import { PrivacyZone } from 'typings/context'

interface ScreenWG2Props {
  side: Side
}

function ScreenWG2(props: ScreenWG2Props) {
  const { side } = props

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)

  const [isProcessing, setIsProcessing] = useState(true)
  const [imageObjectUrl, setImageObjectUrl] = useState<string | null>(null)
  const [zones, setZones] = useState<Array<PrivacyZone>>([])

  const adjustZonesRef = useRef(null)

  async function handleAdjustingFinished() {
    if (adjustZonesRef.current) {
      const data = { zones: (adjustZonesRef.current as AdjustZones).getZones() }

      await api.image.postAdjustableZones(side, data)

      router.next(side)
    }
  }

  async function loadImage() {
    const result = await api.image.getCroppedImageSrc(side)

    setImageObjectUrl(URL.createObjectURL(result))
  }

  async function loadZones() {
    const newZones = await api.image.getAdjustableZones(side)

    setZones(newZones)
  }

  async function setupZones() {
    setIsProcessing(true)

    await loadImage()
    await loadZones()

    setIsProcessing(false)
  }

  useEffect(() => {
    setupZones()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WG2_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WG2_description" />
        </Description>

        {zones && imageObjectUrl && !isProcessing ? (
          <AdjustZones ref={adjustZonesRef} imageSrc={imageObjectUrl} zones={zones} />
        ) : (
          <LoadingIndicator />
        )}
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />

        <PrimaryButton messageId="WG2_button_primary" onClick={handleAdjustingFinished} />
      </Footer>
    </Screen>
  )
}

export default ScreenWG2
