import styled from 'styled-components'

import { color, defaultSpacing } from 'src/style/mixins'

export const MobileInstructionsList = styled.div`
  padding: ${defaultSpacing(2)};

  p {
    text-align: left;
    margin-bottom: 0;
  }
`

export const MobileInstructionsListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${defaultSpacing(5)};
`

export const MobileInstructionsListItemNumber = styled.div`
  min-width: 30px;

  circle {
    fill: ${color('primary')};
  }
`

export const MobileInstructionsListItemImage = styled.div`
  min-width: 100px;
`
