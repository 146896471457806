import React, { useContext } from 'react'

import { ConfigContext } from 'context/ConfigContextProvider'

import { ScreenSC } from './Screen.style'

interface ScreenProps {
  hasError?: boolean
  children: React.ReactNode
}

function Screen(props: ScreenProps) {
  const { hasError = false, children } = props

  const config = useContext(ConfigContext)

  return (
    <ScreenSC hasError={hasError} isMobile={config.isMobile}>
      {children}
    </ScreenSC>
  )
}

export default Screen
