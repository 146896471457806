import React, { useMemo } from 'react'
import { createIntl, createIntlCache, useIntl } from 'react-intl'

export function useFormattedValues(
  values?: Record<string, string | number>
): Record<string, () => JSX.Element> {
  const formattedValues = useMemo(() => {
    return {
      ...values,
      ...{
        strong: (...chunks: Array<string>) => <strong>{chunks}</strong>,
        em: (...chunks: Array<string>) => <em>{chunks}</em>,
        p: (...chunks: Array<string>) => <p>{chunks}</p>
      }
    }
  }, [values])

  return formattedValues
}

interface VerifaiMessageProps {
  id: string
  description?: string
  defaultMessage?: string
  values?: Record<string, string | number>
}

function VerifaiMessage(props: VerifaiMessageProps) {
  const { id, description, defaultMessage, values } = props

  const intl = useIntl()
  const formattedValues = useFormattedValues(values)

  const descriptor = useMemo(() => {
    return {
      id,
      description,
      defaultMessage
    }
  }, [id, description, defaultMessage])

  const message = useMemo(() => {
    const originalMessage = intl.messages[id]
    const regex = /'</g

    // An apostrophe serves as an escape character in ICU message syntax (https://formatjs.io/docs/core-concepts/icu-syntax#quoting--escaping).
    // Due to this certain languages (e.g. French) can have weirdly formatted translation strings.
    if (typeof originalMessage === 'string' && originalMessage.match(regex)) {
      // Manually create a new temporary key which can be used to properly format the message.
      const idOverride = `${id}_override`
      // Create a new temporary `intl` object which includes the new translation key.
      const cache = createIntlCache()
      const intlOverride = createIntl(
        {
          locale: intl.locale,
          messages: {
            ...intl.messages
          }
        },
        cache
      )

      intlOverride.messages[idOverride] = originalMessage.replace(regex, "''<")

      // Create a new descriptor object with the newly created ID.
      const newDescriptor = {
        ...descriptor,
        id: idOverride
      }

      return intlOverride.formatMessage(newDescriptor, formattedValues)
    }

    return intl.formatMessage(descriptor, formattedValues)
  }, [descriptor, formattedValues, intl, id])

  return <span className="notranslate">{message}</span>
}

// Keep old export to ensure backwards compatibility with other components.
// TODO: [ep] Remove this export once all imports are been updated to use default export.
export { VerifaiMessage }

export default VerifaiMessage
