import BackArrow from '../assets/back-arrow.svg'
import BackArrowRtl from '../assets/back-arrow-rtl.svg'
import CautionImage from '../assets/caution.svg'
import CheckCircle from '../assets/check-circle.svg'
import CheckMark from '../assets/check-mark.svg'
import CircleCameraImage from '../assets/circle-camera.svg'
import Wmf1Image from '../assets/circle-img-capture face.svg'
import CrossCircle from '../assets/cross-circle.svg'
import DocumentNotFound from '../assets/document_not_found.svg'
import DocumentVersionArrowBackImage from '../assets/document-version-arrow-back.svg'
import DocumentVersionArrowforwardImage from '../assets/document-version-arrow-forward.svg'
import DriversLicenseBack from '../assets/drivers_license_back.svg'
import DriversLicenseFront from '../assets/drivers_license_front.svg'
import DrivingLicenceImage from '../assets/driving-licence.svg'
import GoToYourMobileImage from '../assets/go-to-your-mobile.svg'
import IdCardImage from '../assets/id.svg'
import IdCardBack from '../assets/id-card-back.svg'
import IdCardFront from '../assets/id-card-front.svg'
import IdImage from '../assets/id-image.svg'
import MobileConnected from '../assets/mobile-connected.svg'
import MobileImage from '../assets/mobile-image.svg'
import InstructionsPhoneImage from '../assets/mobile-instructions-list/image_1.svg'
import ScanDocumentImage from '../assets/mobile-instructions-list/image_2.svg'
import InstructionsComputerImage from '../assets/mobile-instructions-list/image_3.svg'
import NumberOneImage from '../assets/mobile-instructions-list/number_1.svg'
import NumberTwoImage from '../assets/mobile-instructions-list/number_2.svg'
import NumberThreeImage from '../assets/mobile-instructions-list/number_3.svg'
import PassportImage from '../assets/passport.svg'
import PassportExample from '../assets/passport_example.svg'
import Rotate from '../assets/rotate.svg'
import Upload from '../assets/upload image.svg'
import CloudImage from '../assets/upload-cloud.svg'
import WarningImage from '../assets/warning.svg'
import Smartphone from '../assets/wc3-smartphone.svg'
import Webcam from '../assets/webcam.svg'
import WM1Image from '../assets/wm1-icon.svg'
import Wn1Image from '../assets/wn1-icon.svg'
import Wn2Image from '../assets/wn2-icon.svg'
import BodyCircleImage from '../assets/ws1-icon.svg'

const iconMap = {
  rotate: { icon: Rotate, adjustable: false },
  backArrow: { icon: BackArrow, adjustable: false },
  backArrowRtl: { icon: BackArrowRtl, adjustable: false },
  idImage: { icon: IdImage, adjustable: true },
  circleCameraImage: { icon: CircleCameraImage, adjustable: false },
  wmf1Image: { icon: Wmf1Image, adjustable: false },
  wM1Image: { icon: WM1Image, adjustable: false },
  wn1Image: { icon: Wn1Image, adjustable: false },
  wn2Image: { icon: Wn2Image, adjustable: false },
  bodyCircleImage: { icon: BodyCircleImage, adjustable: false },
  documentNotFound: { icon: DocumentNotFound, adjustable: false },
  documentVersionArrowBackImage: { icon: DocumentVersionArrowBackImage, adjustable: false },
  documentVersionArrowforwardImage: { icon: DocumentVersionArrowforwardImage, adjustable: false },
  passportImage: { icon: PassportImage, adjustable: false },
  idCardImage: { icon: IdCardImage, adjustable: false },
  drivingLicenceImage: { icon: DrivingLicenceImage, adjustable: false },
  residencePermitImage: { icon: PassportImage, adjustable: false },
  refugeeTravelDocumentImage: { icon: IdCardImage, adjustable: false },
  goToYourMobileImage: { icon: GoToYourMobileImage, adjustable: false },
  mobileConnected: { icon: MobileConnected, adjustable: false },
  mobileImage: { icon: MobileImage, adjustable: false },
  upload: { icon: Upload, adjustable: false },
  smartphone: { icon: Smartphone, adjustable: false },
  webcam: { icon: Webcam, adjustable: false },
  cloudImage: { icon: CloudImage, adjustable: false },
  warningImage: { icon: WarningImage, adjustable: false },
  cautionImage: { icon: CautionImage, adjustable: true },
  numberOneImage: { icon: NumberOneImage, adjustable: false },
  numberTwoImage: { icon: NumberTwoImage, adjustable: false },
  numberThreeImage: { icon: NumberThreeImage, adjustable: false },
  instructionsPhoneImage: { icon: InstructionsPhoneImage, adjustable: false },
  scanDocumentImage: { icon: ScanDocumentImage, adjustable: false },
  instructionsComputerImage: { icon: InstructionsComputerImage, adjustable: false },
  checkCircleImage: { icon: CheckCircle, adjustable: false },
  crossCircleImage: { icon: CrossCircle, adjustable: false },
  checkMark: { icon: CheckMark, adjustable: false },
  idCardFront: { icon: IdCardFront, adjustable: false },
  idCardBack: { icon: IdCardBack, adjustable: false },
  passportExample: { icon: PassportExample, adjustable: false },
  driversLicenseFront: { icon: DriversLicenseFront, adjustable: false },
  driversLicenseBack: { icon: DriversLicenseBack, adjustable: false }
}

export default iconMap
