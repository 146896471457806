import styled, { css } from 'styled-components'

import { Breakpoint } from 'lib/enums/style'

export const VerifaiContainer = styled.div`
  * {
    box-sizing: border-box;
    font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  svg {
    vertical-align: middle;
  }
`

export const ModalBackgroundSC = styled.div`
  position: fixed;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: auto;
  z-index: 1000; /* Sit on top */
`

export const RelWrapperSC = styled(VerifaiContainer)`
  position: relative;
  width: 677px;
  max-width: 677px;
  height: 733px;
  min-height: 733px;
`

export const WrapperSC = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ModalSC = styled(VerifaiContainer)<{ demo?: boolean }>`
  position: absolute;
  top: 360px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.colors.modalBackground};
  border: none;
  min-height: 733px;
  width: 677px;
  max-width: 677px;
  margin: 1.75rem auto;
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.corners.modalRadius}px;
  overflow: hidden;
  animation: fadeIn ease 0.3s;

  @media (max-width: ${Breakpoint.Medium}px) {
    max-width: 90%;
  }

  ${({ demo }) =>
    demo &&
    css`
      position: fixed;
      top: 400px;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: content-box;
      border: #ff576d solid 42px;
      background-color: #ff576d;
      z-index: 1001;
    `}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const ModalMobileSC = styled(VerifaiContainer)<{ demo?: boolean }>`
  overflow: hidden;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  border-radius: 0;
  z-index: 1000;
  background-color: ${props => props.theme.colors.modalBackground};
  animation: fadeIn ease 0.3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${({ demo }) =>
    demo &&
    css`
      bottom: -72px;
      border: #ff576d solid 10px;
      background-color: #ff576d;
    `}
`
