import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import { STORAGE_KEY_HANDOVER_DATA } from 'lib/constants'
import requests from 'utils/requests'

import BackendContextProvider from './BackendContext'

export class JWTWrapper extends BaseComponent {
  // Injected on build time
  static apiVersion = '__VERIFAI_API_VERSION__'

  static sdkVersion = '__VERIFAI_VERSION__'

  constructor(props) {
    super(props)
    this.defaultState = {
      jwt: null,
      jwtPayload: null,
      session: null,
      handoverId: null,
      initialized: false
    }
    this.state = this.defaultState
  }

  componentDidMount() {
    this.otpChange()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.otp !== this.props.otp) {
      this.reset()
        .then(() => this.otpChange())
        .catch(e => console.error(e))
    }
  }

  /**
   * Wraps the endpoint with the backend url and handles slashes
   * @param {string|Url} endpoint
   * @returns {string}
   */
  url(endpoint) {
    const strippedEndpoint = endpoint.replace(/^\//, '')
    const strippedBackendUrl = this.props.backendUrl.replace(/\/$/, '')

    return `${strippedBackendUrl}/v${JWTWrapper.apiVersion}/${strippedEndpoint}`
  }

  /**
   * Resets the state to default start state
   * @returns {Promise<Object>}
   */
  reset() {
    return this.promiseState(this.defaultState)
  }

  /**
   * Returns the header object for authentications and the sdk version
   * @param {string} token
   * @returns {{Authorization: string, "X-Verifai-SDK-Version": string}}
   */
  defaultHeader(token) {
    return {
      Authorization: `Bearer  ${token}`,
      ...this.versionHeader()
    }
  }

  /**
   * Returns the header object for the sdk version
   * @returns {{"X-Verifai-SDK-Version": string}}
   */
  versionHeader() {
    return { 'X-Verifai-SDK-Version': JWTWrapper.sdkVersion }
  }

  /**
   * If the OTP changes resets backend. And initializes it with the new otp
   * @returns {Promise<Object | void>|Promise<void>}
   */
  otpChange() {
    if (this.props.isHandover) {
      return this.otpHandover()
    }

    if (!this.props.otp) {
      return this.reset().then(() => this.props.show && console.warn('No OTP given'))
    }

    return this.newOtp()
  }

  /**
   * Initialise for handover session
   * @returns {Promise<Object | void>}
   */
  otpHandover() {
    return this.promiseState({ initialized: false })
      .then(() => this.getHandoverJWT())
      .then(({ jwt, session, handover_id: handoverId }) => {
        localStorage.setItem(
          STORAGE_KEY_HANDOVER_DATA,
          JSON.stringify({ jwt, session, handoverId })
        )

        return this.promiseState({ jwt, session, handoverId })
      })
      .then(({ jwt }) => this.parseJwt(jwt))
      .then(jwtPayload => this.promiseState({ jwtPayload }))
      .then(() => this.promiseState({ initialized: true }))
      .catch(e => {
        if (e.code === 403) {
          const storedHandoverData = localStorage.getItem(STORAGE_KEY_HANDOVER_DATA)

          if (storedHandoverData) {
            const { jwt, session, handoverId } = JSON.parse(storedHandoverData)

            return this.promiseState({ jwt, session, handoverId })
              .then(({ jwt }) => this.parseJwt(jwt))
              .then(jwtPayload => this.promiseState({ jwtPayload }))
              .then(() => this.promiseState({ initialized: true }))
              .catch(e => this.reset().then(() => console.warn(e)))
          }
        }

        return this.reset().then(() => console.warn(e))
      })
  }

  /**
   * Initialize for normal session
   * @returns {Promise<Object | void>}
   */
  newOtp() {
    return this.promiseState({ initialized: false })
      .then(() => this.createJwt())
      .then(jwtString => this.promiseState({ jwt: jwtString }))
      .then(({ jwt }) => this.parseJwt(jwt))
      .then(jwtPayload => this.promiseState({ jwtPayload }))
      .then(() => this.createSession())
      .then(session => this.promiseState({ session }))
      .then(() => this.promiseState({ initialized: true }))
      .catch(e => this.reset().then(() => console.warn(e)))
  }

  getHandoverJWT() {
    const endpoint = `${this.url('/handover/')}?secret=${this.props.otp}`

    return requests.get(endpoint, this.versionHeader()).then(requests.unpack)
  }

  /**
   * Posts the token and gets jwt from middleware
   * @returns {Promise<Object>} the JWT object
   */
  createJwt() {
    const url = this.url('/auth/jwt')
    const headers = this.defaultHeader(this.props.otp)

    return requests
      .post(url, {}, headers)
      .then(requests.unpack)
      .then(({ token }) => token)
  }

  /**
   * Posts the JWT, to start a session.
   * @returns {Promise<Object>} the Session object
   */
  createSession() {
    const url = this.url('/session')
    const headers = this.defaultHeader(this.state.jwt)

    return requests.post(url, {}, headers).then(requests.unpack)
  }

  /**
   * Parses the JWT token
   * @param {string} jwt
   * @returns {Promise<never>|Promise<Object>}
   */
  parseJwt(jwt) {
    try {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      )
      const jwtObject = JSON.parse(jsonPayload)

      return Promise.resolve(jwtObject)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  render() {
    return (
      <>
        {this.state.initialized && (
          <BackendContextProvider
            jwt={this.state.jwt}
            jwtPayload={this.state.jwtPayload}
            session={this.state.session}
            backendUrl={this.props.backendUrl}
            handoverId={this.state.handoverId}
          >
            {this.props.children}
          </BackendContextProvider>
        )}
      </>
    )
  }
}

JWTWrapper.defaultProps = {
  isHandover: false
}

JWTWrapper.propTypes = {
  otp: PropTypes.string,
  isHandover: PropTypes.bool.isRequired,
  backendUrl: PropTypes.string.isRequired
}
