import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'

import { BackendContext } from 'context/Backend/BackendContext'
import { AnalyticsEvent, ReducerAction } from 'lib/enums/common'
import { AnalyticsBeacon, DocumentTypeData, ImageData } from 'typings/common'
import { Config, MrzResult, Scan } from 'typings/context'
import { HandoverData } from 'typings/handover'

import { ConfigContext } from './ConfigContextProvider'

function getInitialDocumentType(config: Config) {
  const docTypesEntryList = Object.entries(config.documentTypes || {})

  if (docTypesEntryList.length !== 1) {
    return null
  }

  return Object.values(config.documentTypes)[0]
}

function getInitialUploadType(config: Config) {
  const UploadTypesEntryList = Object.entries(config.uploadTypes || {})

  if (UploadTypesEntryList.length !== 1) {
    return null
  }

  return Object.values(config.uploadTypes)[0]
}

function eventReducer(
  state: Array<AnalyticsBeacon>,
  action: { type: string; event?: AnalyticsBeacon }
): Array<AnalyticsBeacon> {
  if (action.type === ReducerAction.Add && action.event) {
    return [...state, action.event]
  }

  return state
}

interface ScanContextProps extends Scan {
  handover: Partial<HandoverData> | null
  addEvent: (action: AnalyticsBeacon['action'], data?: AnalyticsBeacon['data']) => void
  postEvents: (finishAction: AnalyticsEvent) => Promise<void>
}

export const ScanContext = createContext<ScanContextProps>({} as ScanContextProps)

interface ScanContextProviderProps {
  children: React.ReactNode
}

function ScanContextProvider(props: ScanContextProviderProps) {
  const { children } = props

  const { api } = useContext(BackendContext)
  const config = useContext(ConfigContext)

  const [documentType, setDocumentType] = useState<DocumentTypeData | null>(
    getInitialDocumentType(config)
  )
  const [uploadType, setUploadType] = useState(getInitialUploadType(config))
  const [pdfImages, setPdfImages] = useState<Array<ImageData>>([])
  const [faceImageId, setFaceImageId] = useState(null)
  const [mrzResult, setMrzResult] = useState<MrzResult | null>(null)
  const [classifiedUuid, setClassifiedUuid] = useState<Scan['classifiedUuid']>(null)
  const [document, setDocument] = useState<Scan['document']>(null)
  const [country, setCountry] = useState(null)
  const [handover, setHandover] = useState<Partial<HandoverData> | null>(null)
  const [handoverPhone, setHandoverPhone] = useState(null)
  const [handoverFinished, setHandoverFinished] = useState(false)
  const [needsBackside, setNeedsBackside] = useState(false)
  const [imageId, setImageId] = useState(null)
  const [events, eventDispatch] = useReducer(eventReducer, [])

  const applicationExitEvent = useMemo(() => {
    // Desktop triggers `visibilitychange` on tab change and that's not wanted.
    // Instead use different events for mobile and desktop.
    return config.isMobile ? 'visibilitychange' : 'beforeunload'
  }, [config])

  const handleHandover = useCallback((phoneNumber: string, id: string, expiresAt: number) => {
    setHandover({ phoneNumber, id, expiresAt })
  }, [])

  const hasBack = useCallback(() => {
    return (documentType || { sides: 0 }).sides > 1 || needsBackside
  }, [documentType, needsBackside])

  const postEvents = useCallback(
    async (finishAction: AnalyticsEvent) => {
      const currentDate = new Date()
      const timestamp = currentDate.toISOString()
      const finishEvent = { action: finishAction, timestamp }

      await api.session.postEvents([...events, finishEvent])
    },
    [events, api.session]
  )

  const value = useMemo(() => {
    function handleDocumentType(docType: string) {
      const newDocumentType = config.documentTypes[docType as keyof typeof config.documentTypes]

      setDocumentType(newDocumentType)
    }

    function reset() {
      setDocument(null)
      setClassifiedUuid(null)
      setMrzResult(null)
    }

    function addEvent(action: AnalyticsBeacon['action'], data?: AnalyticsBeacon['data']) {
      const currentDate = new Date()
      const timestamp = currentDate.toISOString()
      const newEvent = { action, data, timestamp }

      eventDispatch({ type: ReducerAction.Add, event: newEvent })
    }

    return {
      documentType,
      setDocumentType: handleDocumentType,
      uploadType,
      setUploadType,
      pdfImages,
      setPdfImages,
      faceImageId,
      setFaceImageId,
      mrzResult,
      setMrzResult,
      classifiedUuid,
      setClassifiedUuid,
      document,
      setDocument,
      country,
      setCountry,
      handover,
      setHandover: handleHandover,
      handoverPhone,
      setHandoverPhone,
      handoverFinished,
      setHandoverFinished,
      needsBackside,
      setNeedsBackside,
      hasBack,
      reset,
      imageId,
      setImageId,
      addEvent,
      postEvents
    }
  }, [
    documentType,
    uploadType,
    setUploadType,
    pdfImages,
    setPdfImages,
    faceImageId,
    setFaceImageId,
    mrzResult,
    setMrzResult,
    classifiedUuid,
    setClassifiedUuid,
    document,
    setDocument,
    country,
    setCountry,
    handover,
    handleHandover,
    handoverPhone,
    setHandoverPhone,
    handoverFinished,
    setHandoverFinished,
    needsBackside,
    setNeedsBackside,
    hasBack,
    config,
    imageId,
    setImageId,
    postEvents
  ])

  useEffect(() => {
    function handleApplicationExit() {
      postEvents(AnalyticsEvent.ExitApplication)
    }

    window.addEventListener(applicationExitEvent, handleApplicationExit)

    return function cleanup() {
      window.removeEventListener(applicationExitEvent, handleApplicationExit)
    }
  }, [postEvents, applicationExitEvent])

  return <ScanContext.Provider value={value}>{children}</ScanContext.Provider>
}

export default ScanContextProvider
