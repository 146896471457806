import React, { useContext } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import { Icon } from 'components/Image/Icon'
import {
  MobileInstructionsList,
  MobileInstructionsListItem,
  MobileInstructionsListItemImage,
  MobileInstructionsListItemNumber
} from 'components/Screen/Body/MobileInstructions.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { RouterContext } from 'context/Router/RouterContextProvider'

import { InstructionWrapper } from './ScreenWK2.style'

function ScreenWK1() {
  const router = useContext(RouterContext)

  function handleStartPressed() {
    router.next()
  }

  return (
    <Screen>
      <Heading>
        <Header messageKey="WK1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WK1_description" />
        </Description>

        <MobileInstructionsList>
          <MobileInstructionsListItem>
            <MobileInstructionsListItemNumber>
              <Icon id="numberOneImage" />
            </MobileInstructionsListItemNumber>
            <MobileInstructionsListItemImage>
              <Icon id="instructionsPhoneImage" />
            </MobileInstructionsListItemImage>
            <InstructionWrapper>
              <VerifaiMessage id="WK1_step1_description" />
            </InstructionWrapper>
          </MobileInstructionsListItem>

          <MobileInstructionsListItem>
            <MobileInstructionsListItemNumber>
              <Icon id="numberTwoImage" />
            </MobileInstructionsListItemNumber>
            <MobileInstructionsListItemImage>
              <Icon id="scanDocumentImage" />
            </MobileInstructionsListItemImage>
            <InstructionWrapper>
              <VerifaiMessage id="WK1_step2_description" />
            </InstructionWrapper>
          </MobileInstructionsListItem>

          <MobileInstructionsListItem>
            <MobileInstructionsListItemNumber>
              <Icon id="numberThreeImage" />
            </MobileInstructionsListItemNumber>
            <MobileInstructionsListItemImage>
              <Icon id="instructionsComputerImage" />
            </MobileInstructionsListItemImage>
            <InstructionWrapper>
              <VerifaiMessage id="WK1_step3_description" />
            </InstructionWrapper>
          </MobileInstructionsListItem>
        </MobileInstructionsList>
      </Body>

      <Footer>
        <BackButton />
        <PrimaryButton
          messageId="WK1_button_primary"
          onClick={handleStartPressed}
          dataTestId="handover-start-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWK1
