import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import { Theme } from 'typings/context'

export const DEFAULT_THEME = {
  spacing: {
    default: 8
  },
  corners: {
    modalRadius: 16,
    buttonRadius: 24
  },
  colors: {
    primary: '#0084c8',
    danger: '#e73939',
    light: '#fff',
    dark: '#000',
    uiBackground: '#f3f3f3',
    border: '#ccc',
    modalBackground: '#ffffff',
    buttonBack: '#757575',
    buttonBackHover: '#636363',
    buttonMainText: '#ffffff',
    bodyText: '#757575',
    bodyErrorText: '#e73939',
    textHeader: '#212529',
    textDescription: '#757575',
    imageForeground: '#212529',
    imageBackground: '#f3f3f3',
    phoneInputSecondary: '#f1f1f1',
    inputValid: '#ccffcc',
    inputInvalid: '#ff9999',
    inputBoxShadow: '#0066ff',
    inputBorder: '#d3d3d3',
    dropdownSeparationLine: '#bdbdbd',
    dropdownSecondaryText: '#8e8e8e',
    dropdownBoxShadow: '#7d7d7d'
  }
}

export interface ThemeContextProviderProps {
  theme: Theme
  children: React.ReactNode
}

function ThemeContextProvider(props: ThemeContextProviderProps) {
  const { theme, children } = props

  const mergedTheme = useMemo(() => {
    // This method makes sure you can omit some properties and it will use the defaults.
    return {
      spacing: {
        ...DEFAULT_THEME.spacing
      },
      corners: {
        ...DEFAULT_THEME.corners,
        ...theme.corners
      },
      colors: {
        ...DEFAULT_THEME.colors,
        ...theme.colors
      },
      icons: {
        ...theme.icons
      }
    }
  }, [theme.corners, theme.colors, theme.icons])

  return <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
}

export default ThemeContextProvider
