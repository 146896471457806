import React, { useContext, useMemo, useState } from 'react'
import { injectIntl, IntlShape } from 'react-intl'

import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { Country } from 'typings/common'

import * as S from './CountryList.style'
import CountryListItem from './CountryListItem'

interface CountryListSelectionProps {
  countries: Array<Country>
  onClick?: (code: Country['code']) => Promise<void>
  showSearch?: boolean
  suggestedCountry?: Country
  searchPlaceholderId?: string
  suggestedLabelId?: string
  intl: IntlShape
}

function CountryListSelection(props: CountryListSelectionProps) {
  const {
    countries,
    onClick,
    showSearch,
    suggestedCountry,
    searchPlaceholderId,
    suggestedLabelId,
    intl
  } = props

  const config = useContext(ConfigContext)

  const [searchQuery, setSearchQuery] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value)
  }

  async function handleCountryClick(countryCode: Country['code']) {
    setIsProcessing(true)

    if (onClick) {
      await onClick(countryCode)
    }

    setIsProcessing(false)
  }

  const searchPlaceholder = useMemo(() => {
    if (searchPlaceholderId) {
      return intl.formatMessage({ id: searchPlaceholderId })
    }

    return ''
  }, [intl, searchPlaceholderId])

  const filteredCountries = useMemo(() => {
    return countries.filter(country =>
      country.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    )
  }, [searchQuery, countries])

  return (
    <S.CountryListContainer>
      {suggestedCountry && (
        <S.SuggestedCountry>
          {suggestedLabelId && (
            <S.CountryLabel>
              <VerifaiMessage id={suggestedLabelId} />
            </S.CountryLabel>
          )}

          <S.CountryList isDisabled={isProcessing}>
            <CountryListItem
              key={suggestedCountry.code}
              country={suggestedCountry}
              onClick={handleCountryClick}
            />
          </S.CountryList>
        </S.SuggestedCountry>
      )}

      {showSearch && (
        <S.SearchWrapper>
          <S.SearchInput
            onChange={handleSearchChange}
            placeholder={searchPlaceholder}
            data-testid="country-search-input"
            type="text"
            value={searchQuery}
            autoFocus={!config.isMobile}
          />
        </S.SearchWrapper>
      )}

      <S.CountryList isDisabled={isProcessing}>
        {filteredCountries.map(country => (
          <CountryListItem
            key={country.code}
            country={country}
            onClick={onClick ? handleCountryClick : undefined}
          />
        ))}
      </S.CountryList>
    </S.CountryListContainer>
  )
}

export default injectIntl(CountryListSelection)
