import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'

import { FitImageSC } from './FitImage.style'

class FitImage extends BaseComponent {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.state = {
      maxWidth: null,
      maxHeight: null,
      imgWidth: 0,
      imgHeight: 0,
      width: 0,
      height: 0,
      loaded: false
    }
  }

  componentDidMount() {
    this.update()
  }

  componentDidUpdate(prevProps) {
    if (this.sizeDidChange() || prevProps.src !== this.props.src) {
      this.update()
    }
  }

  sizeDidChange() {
    const maxHeight = this.containerRef.current.clientHeight
    const maxWidth = this.containerRef.current.clientWidth

    return this.state.maxWidth !== maxWidth || this.state.maxHeight !== maxHeight
  }

  setImageDims(src) {
    return new Promise(resolve => {
      const img = new Image()

      img.onload = () => {
        this.promiseState({ imgWidth: img.width, imgHeight: img.height }).then(() => resolve())
      }
      img.src = src
    })
  }

  update() {
    if (!this.props.src || !this.containerRef.current) {
      return
    }

    const maxHeight = this.containerRef.current.clientHeight
    const maxWidth = this.containerRef.current.clientWidth

    this.promiseState({
      loaded: false,
      maxHeight,
      maxWidth
    })
      .then(() => this.setImageDims(this.props.src))
      .then(() => this.resize())
      .then(() => this.promiseState({ loaded: true }))
      .catch(e => console.warn(e))
  }

  resize() {
    const { maxWidth, maxHeight, imgWidth, imgHeight } = this.state
    const factor = Math.min(maxWidth / imgWidth, maxHeight / imgHeight)

    return this.promiseState({ width: imgWidth * factor, height: imgHeight * factor })
  }

  render() {
    return (
      <FitImageSC ref={this.containerRef}>
        {this.state.loaded && this.props.src ? (
          <img src={this.props.src} alt="" width={this.state.width} height={this.state.height} />
        ) : (
          <LoadingIndicator />
        )}
      </FitImageSC>
    )
  }
}

FitImage.defaultProps = {
  src: null
}

FitImage.propTypes = {
  src: PropTypes.string
}

export default FitImage
