import piexif from 'piexifjs'

import { UploadType } from 'lib/enums/common'
import { VerifaiSession } from 'typings/api'
import requests from 'utils/requests'
import thresholdImageSize from 'utils/thresholdImageSize'

/**
 * Base api containing the auth and the base request types
 */
export default class BaseApi {
  backendUrl: string
  jwt: string
  session: VerifaiSession

  static apiVersion = '__VERIFAI_API_VERSION__'

  // Injected on build time
  static sdkVersion = '__VERIFAI_VERSION__'

  constructor(backendUrl: string, jwt: string, session: VerifaiSession) {
    this.backendUrl = ''
    this.jwt = ''
    this.session = {} as VerifaiSession

    this.update(backendUrl, jwt, session)
  }

  /**
   * Updates the params on a component update.
   */
  update(backendUrl: string, jwt: string, session: VerifaiSession) {
    this.backendUrl = backendUrl
    this.jwt = jwt
    this.session = session
  }

  /**
   * Wraps the Url with the backend url and handles slashes.
   */
  url(endpoint: string) {
    const strippedEndpoint = endpoint.replace(/^\//, '')
    const strippedBackendUrl = this.backendUrl.replace(/\/$/, '')

    return `${strippedBackendUrl}/v${BaseApi.apiVersion}/${strippedEndpoint}`
  }

  /**
   * Returns the Auth headers object and the version.
   */
  defaultHeader() {
    return {
      'Authorization': `Bearer ${this.jwt}`,
      'X-Verifai-SDK-Version': BaseApi.sdkVersion
    }
  }

  /**
   * Executes a 'get' request and json unpacks
   */
  get<T>(endpoint: string): Promise<T> {
    const url = this.url(endpoint)

    return requests.get(url, this.defaultHeader()).then(requests.unpack)
  }

  post<T = Record<never, never>>(endpoint: string, data?: Record<string, unknown>): Promise<T> {
    const url = this.url(endpoint)

    return requests.post(url, data, this.defaultHeader()).then(requests.unpack)
  }

  put(endpoint: string, data?: Record<string, unknown>): Promise<unknown> {
    const url = this.url(endpoint)

    return requests.put(url, data, this.defaultHeader()).then(requests.unpack)
  }

  delete(endpoint: string) {
    const url = this.url(endpoint)

    return requests.delete(url, this.defaultHeader()).then(requests.unpack)
  }

  getImage(endpoint: string) {
    const url = this.url(endpoint)

    return requests.get(url, this.defaultHeader()).then(response => response.blob())
  }

  /**
   * Executes a 'post' request and then unpacks the resulting json
   * image as body in b64
   * Image size gets thresholded to 4k images max
   * If any EXIF data exists, it will be added back after downscaling
   */
  postImage(endpoint: string, imageDataUrl: string, uploadType?: UploadType) {
    const url = this.url(endpoint)

    return thresholdImageSize(imageDataUrl)
      .then(canvasUrl => this._addExif(canvasUrl, imageDataUrl))
      .then(exifUrl => fetch(exifUrl))
      .then(response => response.blob())
      .then(blob => this._createFormData(blob, uploadType))
      .then(formData => ({ headers: this.defaultHeader(), body: formData }))
      .then(config => requests.request('POST', url, config))
      .then(response => requests.unpack(response))
  }

  // TODO: [ep] Disabling this rule as it requires rewriting code which might not be desired.
  // eslint-disable-next-line class-methods-use-this
  _addExif(rescaled: string, original: string) {
    // Try and extract binary EXIF data from the original image
    // This can fail if the image has no EXIF or the EXIF standard is not supported (e.g. outdated or corrupted EXIF)
    try {
      const exif = piexif.load(original)
      const exifBinary = piexif.dump(exif)

      return piexif.insert(exifBinary, rescaled)
    } catch (e) {
      // Return the downscaled image if handling EXIF failed
      return rescaled
    }
  }

  // TODO: [ep] Disabling this rule as it requires rewriting code which might not be desired.
  // eslint-disable-next-line class-methods-use-this
  _createFormData(blob: Blob, uploadType?: string) {
    const formData = new FormData()

    formData.append('file', blob)

    if (uploadType) {
      formData.append('upload_type', uploadType)
    }

    return formData
  }

  postFile(endpoint: string, file: Blob) {
    const url = this.url(endpoint)

    const formData = this._createFormData(file)
    const config = { headers: this.defaultHeader(), body: formData }

    return requests.request('POST', url, config).then(response => {
      return requests.unpack(response)
    })
  }

  postFiles(endpoint: string, files: Array<File>) {
    const url = this.url(endpoint)
    const formData = new FormData()

    files.forEach(file => {
      formData.append('file', file)
    })

    const config = { headers: this.defaultHeader(), body: formData }

    return requests.request('POST', url, config).then(response => requests.unpack(response))
  }

  postPDFImage(endpoint: string) {
    const url = this.url(endpoint)
    const config = { headers: this.defaultHeader() }

    return requests.post(url, config)
  }
}
