import { useCallback, useContext, useEffect, useState } from 'react'

import { BackendContext } from 'context/Backend/BackendContext'
import { ImageContext } from 'context/ImageContextProvider'
import { rotateCropPoints } from 'hooks/useImageProcessing'
import {
  DEFAULT_CROP_POINTS,
  DEFAULT_JOB_DELAY,
  DEFAULT_SUGGESTED_ROTATION,
  POLL_TIMEOUT_LONG,
  POLL_TIMEOUT_SHORT
} from 'lib/constants'
import { Direction } from 'lib/enums/common'
import { CropPoints, ImageSide } from 'typings/common'
import PromisePoller from 'utils/PromisePoller'

function usePolling(side: ImageSide) {
  const { api } = useContext(BackendContext)
  const image = useContext(ImageContext)

  const [poller, setPoller] = useState<PromisePoller>(null)
  const [quadPoller, setQuadPoller] = useState<PromisePoller>(null)
  const [textPredictionPoller, setTextPredictionPoller] = useState<PromisePoller>(null)
  const [hasSuggestions, setHasSuggestions] = useState(false)

  function stopPolling() {
    if (poller) {
      poller.reset()
    }
  }

  function stopQuadPolling() {
    if (quadPoller) {
      quadPoller.reset()
    }
  }

  function stopTextPredictionPolling() {
    if (textPredictionPoller) {
      textPredictionPoller.reset()
    }
  }

  const stopAllPolling = useCallback(() => {
    poller?.reset()
    quadPoller?.reset()
    textPredictionPoller?.reset()
  }, [poller, quadPoller, textPredictionPoller])

  async function pollJobs() {
    stopPolling()

    const jobTypes = [`read_mrz_${side}`, 'classify_document_front']
    const newPoller = await api.job.poller(
      jobTypes,
      DEFAULT_JOB_DELAY,
      POLL_TIMEOUT_LONG,
      image.imageId
    )

    setPoller(newPoller)

    await newPoller.run()
  }

  async function pollQuad() {
    stopQuadPolling()

    const jobType = [`localize_document_${side}`]
    const newQuadPoller = await api.job.poller(jobType, DEFAULT_JOB_DELAY, POLL_TIMEOUT_SHORT)

    setQuadPoller(newQuadPoller)

    await newQuadPoller.run()

    try {
      const quad = await api.image.getQuadPrediction(side)

      return quad
    } catch (error) {
      return DEFAULT_CROP_POINTS
    }
  }

  async function pollTextPrediction() {
    stopTextPredictionPolling()

    const jobType = [`text_prediction_${side}`]
    const newTextPredictionPoller = await api.job.poller(
      jobType,
      DEFAULT_JOB_DELAY,
      POLL_TIMEOUT_LONG
    )

    setTextPredictionPoller(newTextPredictionPoller)

    await newTextPredictionPoller.run()

    try {
      const textPrediction = await api.image.getTextPrediction(side)

      // +360 to account for negative rotations, divide by 90 for getting quadrant, which could be a multitude, hence modulo 4.
      const quadrant = Math.round((textPrediction.text_angle + 360) / 90) % 4
      // if quadrant is 0, take 0 as rotation otherwise the inverse amount because that is how the middleware works.
      const rotation = quadrant === 0 ? 0 : 4 - quadrant

      return rotation
    } catch (error) {
      return DEFAULT_SUGGESTED_ROTATION
    }
  }

  async function parseCropPointsRotation(cropPoints: CropPoints, rotation: number) {
    let newCropPoints = cropPoints

    if (rotation !== DEFAULT_SUGGESTED_ROTATION) {
      // Rotate `cropPoints` by `rotation` amount.
      newCropPoints = rotateCropPoints(cropPoints, Direction.Right, rotation)
    }

    return { cropPoints: newCropPoints, rotation }
  }

  async function getImageSuggestions() {
    const [cropPoints, rotation] = await Promise.all([pollQuad(), pollTextPrediction()])

    const suggestions = await parseCropPointsRotation(cropPoints, rotation)

    setHasSuggestions(true)

    return suggestions
  }

  async function startPolling() {
    await pollJobs()
  }

  useEffect(() => {
    if (hasSuggestions) {
      startPolling()
    }
  }, [hasSuggestions])

  useEffect(() => {
    return function cleanup() {
      stopAllPolling()
    }
  }, [])

  return {
    quadPoller,
    textPredictionPoller,
    poller,
    getImageSuggestions,
    startPolling,
    stopPolling: stopAllPolling
  }
}

export default usePolling
