import React from 'react'

import * as S from './VerifaiBody.style'

interface VerifaiBodyProps {
  children: React.ReactNode
  allowScroll?: boolean
}

function VerifaiBody(props: VerifaiBodyProps) {
  const { children, allowScroll } = props

  return <S.Body allowScroll={allowScroll}>{children}</S.Body>
}

export default VerifaiBody
