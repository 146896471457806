import React from 'react'

import Icon from 'components/Image/Icon'
import VerifaiMessage from 'components/Text/VerifaiIntl'

import * as S from './Instruction.style'

const INSTRUCTION_TEXT_KEYS = [
  'WI1_instruction_brightness',
  'WI1_instruction_sharpness',
  'WI1_instruction_data',
  'WI1_instruction_corners'
]

function InstructionList() {
  return (
    <S.ListContainer>
      <S.ListWrapper>
        <S.ListHeading>
          <VerifaiMessage id="WI1_instructions_heading" />
        </S.ListHeading>

        {INSTRUCTION_TEXT_KEYS.map(textKey => (
          <S.ListItem key={textKey}>
            <Icon id="checkMark" width={24} /> <VerifaiMessage id={textKey} />
          </S.ListItem>
        ))}
      </S.ListWrapper>
    </S.ListContainer>
  )
}

export default InstructionList
