import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import VerifaiWrapper from 'components/VerifaiWrapper/VerifaiWrapper'
import { MultiContext } from 'context/MultiContextProvider'
import VerifaiContext from 'context/VerifaiContext'
import setNonce from 'utils/setNonce'

import verifaiPropTypes from './VerifaiPropTypes'

class Handover extends BaseComponent {
  static contextType = MultiContext

  constructor(props) {
    super(props)
    this.state = {
      secret: null
    }
  }

  /**
   * Gets the secret from the url
   * @returns {Promise<unknown>}
   */
  getSecret() {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(window.location.search)
      const secret = params.get(this.props.secretName)

      if (secret) {
        resolve(secret)
      } else {
        reject(new Error('No secret found in URL'))
      }
    })
  }

  componentDidMount() {
    setNonce(this.props.nonce)

    this.getSecret()
      .then(secret => this.promiseState({ secret }))
      .catch(e => console.error(e))
  }

  componentDidUpdate() {
    setNonce(this.props.nonce)
  }

  render() {
    return (
      <>
        {this.state.secret && (
          <VerifaiContext
            {...this.props}
            showInstruction={this.props.showInstruction}
            enableAttachments={this.props.enableAttachments}
            isHandover
            token={this.state.secret}
          >
            <VerifaiWrapper />
          </VerifaiContext>
        )}
      </>
    )
  }
}

Handover.defaultProps = {
  secretName: 's',
  closable: false,

  // Set empty by default.
  onSuccess: () => {},
  onCanceled: () => {},

  assetsUrl: '__VERIFAI_ASSETS_URL__'
}

Handover.propTypes = {
  secretName: PropTypes.string,
  ...verifaiPropTypes
}

export default Handover
