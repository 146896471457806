import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import * as S from 'components/Carousel/Carousel.style'
import { Icon } from 'components/Image/Icon'

const PDFItem = forwardRef(function PDFItem(props, ref) {
  return (
    <S.ThumbnailContainer
      isSelected={props.selected}
      onClick={props.onClick}
      ref={ref}
      data-testid={`pdf-item-${props.index}`}
    >
      <S.PdfThumbnail src={props.sample_front} />
    </S.ThumbnailContainer>
  )
})

PDFItem.propTypes = {
  selected: PropTypes.bool,
  sample_front: PropTypes.string,
  onClick: PropTypes.func,
  height_px: PropTypes.number,
  index: PropTypes.number
}

class PDFSelection extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: 0,
      urlObjects: this.props.choices.map(page => URL.createObjectURL(page.image))
    }

    this.imageSelectedHandler = this.imageSelectedHandler.bind(this)
    this.handleForwardArrowPressed = this.handleForwardArrowPressed.bind(this)
    this.handleBackArrowPressed = this.handleBackArrowPressed.bind(this)
  }

  componentDidMount() {
    const PDFPages = this.props.choices

    this.refs = PDFPages.reduce((acc, value) => {
      acc[value.image_id] = React.createRef()

      return acc
    }, {})
  }

  imageSelectedHandler(selectedIndex) {
    // Ignore indexes out of the range
    if (selectedIndex < 0 || selectedIndex >= this.props.choices.length) {
      return
    }

    this.setState({ selectedIndex })

    const selectedImage = this.props.choices[selectedIndex].image_id
    const ref = this.refs[selectedImage]

    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    }
  }

  getSelectedPage() {
    return this.props.choices[this.state.selectedIndex].image_id
  }

  handleBackArrowPressed() {
    const newIndex = this.state.selectedIndex - 1

    this.imageSelectedHandler(newIndex)
  }

  handleForwardArrowPressed() {
    const newIndex = this.state.selectedIndex + 1

    this.imageSelectedHandler(newIndex)
  }

  render() {
    const { selectedIndex } = this.state
    const disableBackArrow = this.state.selectedIndex === 0
    const disableForwardArrow = this.state.selectedIndex === this.props.choices.length - 1

    const selectedItem = this.props.choices[selectedIndex]
    let selectedUrl

    if (selectedItem) {
      selectedUrl = this.state.urlObjects[selectedIndex]
    }

    const items = this.props.choices.map((choice, index) => {
      return (
        <PDFItem
          ref={this.refs[choice.image_id]}
          key={choice.image_id}
          image_id={choice.image_id}
          sample_front={this.state.urlObjects[index]}
          selected={index === selectedIndex}
          height_px={100}
          onClick={() => this.imageSelectedHandler(index)}
          index={index}
        />
      )
    })

    return (
      <>
        <S.Carousel>
          <S.NavigationArrow onClick={this.handleBackArrowPressed} isDisabled={disableBackArrow}>
            <Icon id="documentVersionArrowBackImage" />
          </S.NavigationArrow>

          <S.PdfContainer>
            <S.PdfImage src={selectedUrl} alt="Selected Document" />
          </S.PdfContainer>

          <S.NavigationArrow
            onClick={this.handleForwardArrowPressed}
            isDisabled={disableForwardArrow}
          >
            <Icon id="documentVersionArrowforwardImage" />
          </S.NavigationArrow>
        </S.Carousel>

        <S.ThumbnailWrapper>
          <S.Thumbnails>{items}</S.Thumbnails>
        </S.ThumbnailWrapper>
      </>
    )
  }
}

PDFSelection.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object)
}

export default PDFSelection
