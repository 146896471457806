import { useCallback, useContext, useEffect, useState } from 'react'

import { BackendContext } from 'context/Backend/BackendContext'
import { DEFAULT_JOB_DELAY } from 'lib/constants'
import PromisePoller from 'utils/PromisePoller'

export default function useHandoverPolling() {
  const [poller, setPoller] = useState<PromisePoller>(null)

  const { api } = useContext(BackendContext)

  const unsetPoller = useCallback(() => {
    if (poller) {
      poller.reset()
    }

    setPoller(null)
  }, [poller])

  async function updatePoller(handoverId: string) {
    unsetPoller()

    const newPoller = await api.handover.poller(handoverId, DEFAULT_JOB_DELAY, 0)

    setPoller(newPoller)

    return newPoller
  }

  useEffect(() => {
    unsetPoller()

    return function cleanup() {
      unsetPoller()
    }
  }, [])

  return { updatePoller, poller }
}
