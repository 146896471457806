import styled from 'styled-components'

import { Breakpoint } from 'lib/enums/style'
import { defaultSpacing } from 'src/style/mixins'

export const FooterSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${defaultSpacing()} ${defaultSpacing(7)} ${defaultSpacing(4)};
  height: 15%;
  min-height: 15%;

  & > div:nth-child(1),
  > div:nth-child(3) {
    display: flex;
    width: 25%;
  }

  > div:nth-child(1) {
    justify-content: flex-start;
  }

  > div:nth-child(3) {
    justify-content: flex-end;
  }

  @media (max-width: ${Breakpoint.Medium}px) {
    padding: ${defaultSpacing()} ${defaultSpacing(4)} ${defaultSpacing(4)};
  }

  @media (max-width: ${Breakpoint.Small}px) {
    height: 10%;
    min-height: 10%;
    padding: ${defaultSpacing()} ${defaultSpacing(2)} ${defaultSpacing(2)};
  }
`

export const FooterButtonSC = styled.div`
  display: flex;
  flex-direction: row;
`
