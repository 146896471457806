import PropTypes from 'prop-types'

const uploadTypes = {
  phone: PropTypes.shape({
    showSms: PropTypes.bool
  }),
  file: PropTypes.shape({}),
  webcam: PropTypes.shape({})
}

export const defaultUploadTypes = {
  phone: {
    showSms: false
  },
  file: {},
  webcam: {}
}

const isEmptyObject = obj =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype

export const propUploadType = (props, propName, componentName) => {
  const uploadTypeKeys = Object.keys(uploadTypes)
  const uploadProps = props[propName]

  if (!uploadProps) {
    return
  }

  if (!Array.isArray(uploadProps)) {
    return new Error('UploadType should be an array')
  }

  for (const item of uploadProps) {
    if (typeof item === 'string' && uploadTypeKeys.includes(item)) {
      continue
    }

    if (Array.isArray(item)) {
      const key = item[0] || null

      if (!uploadTypeKeys.includes(key)) {
        return new Error(`First array item should be one of: ${uploadTypeKeys.join(',')}`)
      }

      if (item.length === 1) {
        continue
      }

      if (item.length !== 2) {
        return new Error('Array should have one or two values')
      }

      const value = item[1]

      if (isEmptyObject(value)) {
        continue
      }
      PropTypes.checkPropTypes({ config: uploadTypes[key] }, { config: value }, key, componentName)
      continue
    }

    return new Error(`UploadType item should be an array or a string: ${uploadTypeKeys.join(',')}`)
  }
}

export const propDisplay = PropTypes.oneOf(['modal', 'inline'])

const verifaiPropTypes = {
  token: PropTypes.string,
  assetsUrl: PropTypes.string,
  customAssetsUrl: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onCanceled: PropTypes.func.isRequired,
  onError: PropTypes.func,
  show: PropTypes.bool,
  closable: PropTypes.bool,
  showInstruction: PropTypes.bool,
  showGreeting: PropTypes.bool,
  uploadTypes: propUploadType,
  backendUrl: PropTypes.string,
  // theme: themePropType,
  faceMatchMobileOnly: PropTypes.bool,
  nonce: PropTypes.string,
  display: propDisplay,
  countryCodeOption: PropTypes.string,

  // Not frontend config variables
  isHandover: PropTypes.bool
}

export default verifaiPropTypes
