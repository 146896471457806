import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { ConfigContext } from 'context/ConfigContextProvider'

import { InstructionImageSC } from './InstructionImage.style'

const IMAGE_MAP = {
  angle: 'angle.jpg',
  contrast: 'contrast.jpg',
  correct: 'correct.jpg',
  far: 'far.jpg',
  flash: 'flash.jpg',
  focus: 'focus.jpg',
  obstruction: 'obstruction.jpg',
  resolution: 'reso.jpeg'
}

interface InstructionImageProps {
  imageKey: string
  isTable?: boolean
}

function InstructionImage(props: InstructionImageProps) {
  const { imageKey, isTable = false } = props

  const { assetsUrl } = useContext(ConfigContext)
  const intl = useIntl()

  const image = `${assetsUrl}/instruction-screens/${IMAGE_MAP[imageKey as keyof typeof IMAGE_MAP]}`

  return (
    <InstructionImageSC
      isTable={isTable}
      src={image}
      alt={intl.formatMessage({ id: `WI1_example_${imageKey}` })}
    />
  )
}

export default InstructionImage
