import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { AnalyticsEvent, ClickAction } from 'lib/enums/common'

import * as S from './VerifaiButton.style'

interface BackButtonProps {
  isDisabled?: boolean
  onClick?: () => void
}

function BackButton(props: BackButtonProps) {
  const { isDisabled, onClick } = props

  const router = useContext(RouterContext)
  const config = useContext(ConfigContext)
  const scan = useContext(ScanContext)

  const intl = useIntl()

  function handleOnClick(event: React.MouseEvent) {
    event.preventDefault()

    const messageId = 'generic_button_back'

    scan.addEvent(AnalyticsEvent.ClickButton, {
      screen: router.current.name,
      button: { type: ClickAction.Back, id: messageId, text: intl.formatMessage({ id: messageId }) }
    })

    if (onClick) {
      onClick()
    }

    if (!isDisabled) {
      if (onClick) {
        onClick()
      } else {
        router.back()
      }
    }
  }

  if (router.isFirst()) {
    return null
  }

  return (
    <S.FooterButton onClick={handleOnClick} isDisabled={isDisabled}>
      <S.FooterIcon id="backArrow" rotate={config.rightToLeftScript ? 180 : 0} isDecorative />

      {!config.isMobile && <VerifaiMessage id="generic_button_back" />}
    </S.FooterButton>
  )
}
export default BackButton
