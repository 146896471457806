import piexif from 'piexifjs'

/**
 * Strips exif before loading.
 * Loading with exif changes orientation off the image in some browsers.
 *
 */
function stripExif(dataUrl: string) {
  try {
    return piexif.remove(dataUrl)
  } catch (_) {
    // No exif or no jpeg
    return dataUrl
  }
}

export default function thresholdImageSize(src: string, maxAxisSize = 3840): Promise<string> {
  return new Promise(resolve => {
    const image = new Image()

    image.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const maxAxis = Math.max(image.width, image.height)
      const scale = maxAxis > maxAxisSize ? maxAxisSize / maxAxis : 1

      canvas.width = image.width * scale
      canvas.height = image.height * scale

      if (ctx) {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
      }

      resolve(canvas.toDataURL('image/jpeg', 0.9))
    }

    image.src = stripExif(src)
  })
}
