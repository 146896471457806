import styled from 'styled-components'

import { FontSize, FontWeight } from 'lib/enums/style'
import { darken, fontSize } from 'src/style/mixins'

export const PromoSC = styled.div<{ isMobile: boolean; isDemo: boolean }>`
  position: absolute;
  top: 723px;
  height: 30px;
  text-align: center;
  width: 100%;
  z-index: 1002;

  ${({ isMobile }) =>
    isMobile &&
    `
     position: relative;
     top: unset;
  `}

  p {
    color: white;
  }

  ${({ isDemo }) =>
    isDemo &&
    `
    top: 804px;
  `}
`

export const VerifaiLogoSC = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: ${fontSize(FontSize.Medium)};
  color: ${darken('uiBackground', 0.7)};
`
