import React from 'react'

import VerifaiMessage from 'components/Text/VerifaiIntl'
import { Size } from 'lib/enums/common'
import { Theme } from 'typings/context'

import * as S from './LoadingIndicator.style'

interface LoadingIndicatorProps {
  hasText?: boolean
  color?: keyof Theme['colors']
  size?: Size
}

function LoadingIndicator(props: LoadingIndicatorProps) {
  const { hasText = false, color = 'primary', size = Size.Large } = props

  return (
    <S.Container>
      <S.Spinner hasText={hasText} color={color} size={size} />

      {hasText && <VerifaiMessage id="generic_loading" />}
    </S.Container>
  )
}

export default LoadingIndicator
