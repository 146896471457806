import { PromiseReject, PromiseResolve, PromiseResume } from 'typings/api'
import { CreateHandover } from 'typings/response/handover'
import PromisePoller from 'utils/PromisePoller'

import BaseApi from './BaseApi'

const handoverApi = (api: BaseApi, handoverId?: string) => ({
  createHandover: () => api.post<CreateHandover>('session/handover'),

  getHandover: () => api.get(`session/handover/${handoverId}`),

  finishHandover: () => api.post(`session/handover/${handoverId}`),

  createHandoverTextMessage: (toNumber: string, id: string) =>
    api.post(`session/handover/${id}/textmessage`, { to: toNumber }),

  poller: (id: string, delay: number, timeout: number) =>
    new PromisePoller(
      (resume: PromiseResume, resolve: PromiseResolve<boolean>, reject: PromiseReject) => {
        api
          .get<{ used: boolean; expire: number }>(`session/handover/${id}`)
          .then(({ used, expire }) => {
            // Resolves true if used, false if expired
            if (used) {
              return resolve(true)
            }

            const expDate = new Date(expire)
            const currentDate = new Date()
            const diffInSeconds = (expDate.getTime() - currentDate.getTime()) / 1000

            if (diffInSeconds < 30) {
              return resolve(false)
            }

            return resume()
          })
          .catch(reject)
      },
      delay,
      timeout
    ),

  finishedPoller: (id: string, delay: number, timeout: number) =>
    new PromisePoller(
      (resume: PromiseResume, resolve: PromiseResolve, reject: PromiseReject) => {
        api
          .get<{ finished: boolean }>(`session/handover/${id}`)
          .then(({ finished }) => (finished ? resolve() : resume()))
          .catch(reject)
      },
      delay,
      timeout
    )
})

export default handoverApi
