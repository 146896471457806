import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from '../BaseComponent/BaseComponent'

import * as S from './ScaleText.style'

export default class ScaleText extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      fontSize: props.minFontSize,
      resized: false
    }
  }

  createRuler() {
    // Create copy of wrapper for sizing
    this.ruler = this.wrapper.cloneNode(true)

    const style = {
      position: 'absolute',
      visibility: 'hidden',
      width: 'auto',
      height: 'auto',
      whiteSpace: 'nowrap',
      fontSize: `${this.state.fontSize}px`
    }

    for (const prop in style) {
      this.ruler.style[prop] = style[prop]
    }
    this.ruler.className = 'ruler verifai_wrapper'
    document.body.appendChild(this.ruler)
  }

  resize() {
    if (!this.state.resized) {
      this.setState({ resized: true }, () => {
        this.createRuler()

        let fontSize = this.props.minFontSize

        while (
          this.ruler.clientWidth < this.target.clientWidth &&
          this.props.maxFontSize > fontSize
        ) {
          this.ruler.style.fontSize = `${++fontSize}px`
        }
        this.setState(
          {
            fontSize: fontSize - 1 // Remove one pixel because the ruler width is bigger than the target width
          },
          () => {
            this.clearRuler()
          }
        )
      })
    }
  }

  clearRuler() {
    if (this.ruler) {
      document.body.removeChild(this.ruler)
    }
    this.ruler = null
  }

  componentDidMount() {
    this.resize()
  }

  componentDidUpdate() {
    this.resize()
  }

  render() {
    return (
      <S.Wrapper
        ref={c => {
          this.target = c
        }}
      >
        <S.Font
          fontSize={this.state.fontSize}
          ref={c => {
            this.wrapper = c
          }}
        >
          {this.props.children}
        </S.Font>
      </S.Wrapper>
    )
  }
}

ScaleText.propTypes = {
  children: PropTypes.node.isRequired,
  maxFontSize: PropTypes.number.isRequired,
  minFontSize: PropTypes.number.isRequired
}
