import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import { ButtonPrimarySC, CloseButtonSC } from 'components/Button/VerifaiButton.style'
import Description from 'components/Description/VerifaiDescription'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import { FooterButtonSC } from 'components/Screen/Footer/VerifaiFooter.style'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { MultiContext } from 'context/MultiContextProvider'

import DocumentErrorBody from './ErrorBody/DocumentErrorBody'
import VErrorBody from './ErrorBody/VErrorBody'

class ErrorBoundary extends BaseComponent {
  static contextType = MultiContext

  constructor() {
    super()
    this.defaultState = {
      error: null,
      hasError: false
    }
    this.state = this.defaultState

    this.handlePrimaryButtonPressed = this.handlePrimaryButtonPressed.bind(this)
    this.handleCloseButton = this.handleCloseButton.bind(this)
  }

  getTitleId() {
    return this.state.error?.titleStringId || 'WL2_title'
  }

  getDescriptionId() {
    return this.state.error?.descStringId || 'WL2_description'
  }

  getPrimaryButtonId() {
    return this.state.error?.primaryButtonId || 'WL2_primary_button'
  }

  getPrimaryButtonAction() {
    return this.state.error?.primaryButtonAction || null
  }

  getShowCloseButton() {
    return this.state.error?.showCloseButton || true
  }

  getShowPrimaryButton() {
    return this.state.error?.showPrimaryButton || true
  }

  renderBody() {
    if (this.state.error?.name === 'DocumentError') {
      return <DocumentErrorBody error={this.state.error} />
    }

    return <VErrorBody />
  }

  componentDidCatch(error) {
    this.setState({
      error,
      hasError: true
    })

    if (this.context.config.onError) {
      this.context.config.onError(error)
    }

    console.error(error)
  }

  handlePrimaryButtonPressed() {
    if (this.getPrimaryButtonAction()) {
      this.getPrimaryButtonAction()(this.context).then(() => this.promiseState(this.defaultState))
    } else {
      this.context.scan.reset()
      this.context.router.reset()
      this.promiseState(this.defaultState)
    }
  }

  handleCloseButton() {
    this.context.router.forceCancel()
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <Screen hasError>
        <Heading>
          <Header messageKey={this.getTitleId()} />
        </Heading>

        <Body>
          <Description>
            <VerifaiMessage id={this.getDescriptionId()} />
          </Description>

          {this.renderBody()}
        </Body>

        <Footer>
          <FooterButtonSC>
            {this.getShowCloseButton() && (
              <CloseButtonSC onClick={this.handleCloseButton}>
                <VerifaiMessage id="generic_button_close" />
              </CloseButtonSC>
            )}
          </FooterButtonSC>

          <FooterButtonSC>
            {this.getShowPrimaryButton() && (
              <ButtonPrimarySC onClick={this.handlePrimaryButtonPressed}>
                <VerifaiMessage id={this.getPrimaryButtonId()} />
              </ButtonPrimarySC>
            )}
          </FooterButtonSC>
        </Footer>
      </Screen>
    )
  }
}

ErrorBoundary.defaultProps = {}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default ErrorBoundary
