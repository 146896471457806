import styled from 'styled-components'

export const InputGroupButtonDropdown = styled.div`
  flex: 1;
  position: relative;
  width: 50px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.inputBorder};
  border-right: 0;
  border-radius: 5px 0 0 0;
  padding: 10px;
  display: flex;
  box-sizing: border-box;

  background-color: ${props => props.theme.colors.phoneInputSecondary};

  :hover {
    background-color: inherit;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`

export const DropdownMenu = styled.ul`
  position: absolute;
  min-width: 400px;
  z-index: 1;
  background-color: ${props => props.theme.colors.modalBackground};
  box-shadow: 0 0 10px ${props => props.theme.colors.dropdownBoxShadow};
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 130px;
  padding: 0 0 10px;
`

export const DropdownItem = styled.li`
  display: flex;
  padding: 2px 10px;

  background-color: ${props =>
    props.selected ? props.theme.colors.phoneInputSecondary : 'inherit'};
  color: ${props => (props.header ? props.theme.colors.dropdownSecondaryText : 'inherit')};

  :hover {
    background-color: ${props =>
      !props.header ? props.theme.colors.phoneInputSecondary : 'inherit'};
  }
`

export const CountryCode = styled.div`
  margin-left: auto;
  color: ${props => props.theme.colors.dropdownSecondaryText};
`

export const InputGroup = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  height: 40px;
`

export const Input = styled.input`
  flex: 15;
  border: 1px solid ${props => props.theme.colors.inputBorder};
  border-radius: 0;
  padding: 5px;
  height: 40px;

  :focus {
    outline: none;
    box-shadow: 0 0 2px ${props => props.theme.colors.inputBoxShadow};
  }

  background-color: ${props =>
    props.valid == 1 // eslint-disable-line eqeqeq
      ? props.theme.colors.inputValid
      : props.valid == -1 // eslint-disable-line eqeqeq
      ? props.theme.colors.inputInvalid
      : 'inherit'};
`

export const Arrow = styled.div`
  float: right;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: ${props => (props.up ? '5px solid black' : '0px')};
  border-top: ${props => (props.down ? '5px solid black' : '0px')};
  margin-left: 5px;
  margin-top: 40%;
`

export const HLine = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${props => props.theme.colors.dropdownSeparationLine};
  padding: 0;
  margin: 1em 10px 1em 10px;
`
