export default class VError extends Error {
  constructor(
    {
      titleStringId = 'WL2_title',
      descStringId = 'WL2_description',
      showCloseButton = true,
      showPrimaryButton = true,
      primaryButtonId = 'WL2_primary_button',
      primaryButtonAction = null,
      type = null
    },
    ...params
  ) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VError)
    }

    this.name = 'VError'

    this.titleStringId = titleStringId
    this.descStringId = descStringId
    this.showCloseButton = showCloseButton
    this.showPrimaryButton = showPrimaryButton
    this.primaryButtonId = primaryButtonId
    this.primaryButtonAction = primaryButtonAction
    this.type = type

    this.date = new Date()
  }
}
