import styled from 'styled-components'

import { Breakpoint } from 'lib/enums/style'
import { defaultSpacing } from 'src/style/mixins'

// eslint-disable-next-line import/prefer-default-export
export const CropCanvasSC = styled.div`
  height: 100%;
  width: 100%;
  min-height: ${defaultSpacing(30)};
  max-height: ${defaultSpacing(40)};

  @media (max-width: ${Breakpoint.Small}px) {
    min-height: ${defaultSpacing(25)};
    max-height: ${defaultSpacing(35)};
  }
`
