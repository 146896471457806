import React, { useMemo, useState } from 'react'

import {
  ButtonLinkSC,
  ButtonOptionsSC,
  OptionsArrowSC
} from 'components/Button/VerifaiButton.style'
import PhoneInputSC from 'components/PhoneInputGroup/PhoneInput'
import { ErrorMessage } from 'components/Text/Text.style'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import * as S from 'pages/WK/ScreenWK2.style'
import { SmsWrapper } from 'pages/WK/ScreenWK2.style'
import { Country, Errors } from 'typings/common'
import { HandoverData } from 'typings/handover'

interface SendSmsOptionProps {
  shouldShowSms: boolean
  handoverData: HandoverData
  suggestedCountry: Country | null
  onPhoneChange: (phoneNumber: string) => void
  handleTextMessageSubmit: (event: React.FormEvent) => void
  isLoading: boolean
  errors: Errors
}

function SendSmsOption(props: SendSmsOptionProps) {
  const {
    shouldShowSms,
    handoverData,
    onPhoneChange,
    suggestedCountry,
    handleTextMessageSubmit,
    isLoading,
    errors
  } = props

  const [shouldShowOtherOptions, setShouldShowOtherOptions] = useState(false)

  const country = useMemo(() => {
    if (suggestedCountry) {
      return suggestedCountry.code
    }

    return 'NL'
  }, [suggestedCountry])

  if (!shouldShowSms) {
    return null
  }

  return (
    <div>
      {shouldShowOtherOptions ? (
        <div>
          <SmsWrapper>
            <VerifaiMessage id="WK2_or" />
          </SmsWrapper>

          <SmsWrapper>
            <VerifaiMessage id="WK2_description" />
          </SmsWrapper>

          <form onSubmit={handleTextMessageSubmit}>
            <div>
              <S.LabelWrapper>
                <VerifaiMessage id="WK2_label_your_number" />

                <S.LabelHint>
                  <VerifaiMessage id="WK2_hint_your_number" />
                </S.LabelHint>
              </S.LabelWrapper>

              <PhoneInputSC
                name="phoneInput"
                value={handoverData.phoneNumber}
                onChange={onPhoneChange}
                onClick={handleTextMessageSubmit}
                country={country}
                isLoading={isLoading}
              />

              {errors.phoneNumber && (
                <ErrorMessage>
                  <VerifaiMessage id="WK2_error_incorrect_phone_number" />
                </ErrorMessage>
              )}
            </div>
          </form>
        </div>
      ) : (
        <ButtonLinkSC onClick={() => setShouldShowOtherOptions(true)}>
          <ButtonOptionsSC>
            <VerifaiMessage id="WK2_button_other_options" />

            <OptionsArrowSC />
          </ButtonOptionsSC>
        </ButtonLinkSC>
      )}
    </div>
  )
}

export default SendSmsOption
