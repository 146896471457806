import React, { useContext, useEffect, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Carousel from 'components/Carousel/Carousel'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { UNKNOWN_ID_MODEL } from 'lib/constants'
import { AnalyticsEvent } from 'lib/enums/common'
import { IdModel } from 'typings/context'

function ScreenWE1() {
  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const config = useContext(ConfigContext)

  const [idModels, setIdModels] = useState<Array<Partial<IdModel>>>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedModelIndex, setSelectedModelIndex] = useState(0)

  async function setDocument(uuid: string | null) {
    const document = await api.session.setSelectedModel(uuid)

    scan.setDocument(document)
  }

  async function handleContinue() {
    const { model, uuid = null } = idModels[selectedModelIndex]

    setIsProcessing(true)

    await setDocument(uuid)

    scan.addEvent(AnalyticsEvent.SelectIdModel, {
      screen: router.current.name,
      document: { model: model || UNKNOWN_ID_MODEL }
    })

    router.next()
  }

  async function loadIdModels() {
    const docTypeWhiteList = Object.keys(config.documentTypes)

    const docs: typeof idModels = await api.session.getIdModels(docTypeWhiteList)

    try {
      if (Array.isArray(docs) && docs.length > 0) {
        if (docs.length === 1) {
          await setDocument(docs[0].uuid)

          router.next()
        } else {
          const emptyDocumentType = {
            uuid: null,
            height_mm: docs[0].height_mm,
            width_mm: docs[0].width_mm
          }

          docs.push(emptyDocumentType)

          setIdModels(docs)
          setIsProcessing(false)
        }
      } else {
        await setDocument(null)

        router.next()
      }
    } catch (error) {
      console.error(error)

      setIsProcessing(false)
    }

    setIsLoading(false)
  }

  function handleChangeEvent(index: number) {
    setSelectedModelIndex(index)

    const { model, uuid = null } = idModels[index]

    scan.addEvent(AnalyticsEvent.ChangeIdModel, {
      screen: router.current.name,
      document: { model: model || UNKNOWN_ID_MODEL, uuid }
    })
  }

  useEffect(() => {
    loadIdModels()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WE1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WE1_description" />
        </Description>

        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Carousel
            selectedIndex={selectedModelIndex}
            choices={idModels}
            onChange={handleChangeEvent}
          />
        )}
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />

        <PrimaryButton
          messageId={config.canEditPrivacyFilters ? 'WD1_button_submit' : 'WE1_button_primary'}
          onClick={handleContinue}
          disabled={isProcessing || idModels.length === 0}
          dataTestId="document-model-continue"
          isLoading={isProcessing}
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWE1
