import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import { BackendContext } from 'context/Backend/BackendContext'
import { ERROR_CODE } from 'lib/constants'
import VError from 'utils/VError'

class SessionTimeOut extends BaseComponent {
  static contextType = BackendContext

  constructor(props) {
    super(props)

    this.sessionTimeOut = this.sessionTimeOut.bind(this)
  }

  componentDidMount() {
    const expire = new Date(this.context.jwtPayload.exp * 1000)

    this.sessionTimeOutTimer = setTimeout(this.sessionTimeOut, expire - Date.now())
  }

  componentWillUnmount() {
    clearTimeout(this.sessionTimeOut)
  }

  sessionTimeOut() {
    this.throwError(
      new VError({
        type: ERROR_CODE.JwtExpired,
        titleStringId: 'WL2_timeout_title',
        descStringId: 'WL2_timeout',
        showPrimaryButton: false
      })
    )
  }

  render() {
    return this.props.children || null
  }
}

SessionTimeOut.defaultProps = {}

SessionTimeOut.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default SessionTimeOut
