import React from 'react'

import { Country } from 'typings/common'

import * as S from './CountryList.style'

interface CountryListItemProps {
  country: Country
  onClick?: (code: Country['code']) => void
}

function CountryListItem(props: CountryListItemProps) {
  const { country, onClick } = props

  function handleClick(event: React.MouseEvent) {
    event.preventDefault()

    if (onClick) {
      onClick(country.code)
    }
  }

  if (onClick) {
    return (
      <S.ClickableListItem onClick={handleClick} data-testid={`country-item-${country.code}`}>
        <S.Flag>{country.flag}</S.Flag>

        <span>{country.name}</span>
      </S.ClickableListItem>
    )
  }

  return (
    <S.ListItem>
      <S.Flag>{country.flag}</S.Flag>

      <span>{country.name}</span>
    </S.ListItem>
  )
}

export default CountryListItem
