import React, { useContext, useEffect, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import DocumentExample from 'components/DocumentExample/DocumentExample'
import { DivFlexSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ImageContext } from 'context/ImageContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { ALLOWED_IMAGE_TYPES } from 'lib/constants'
import { DocumentType, Side, UploadType } from 'lib/enums/common'
import { ImageSide } from 'typings/common'
import blobToDataUrl from 'utils/blobToDataUrl'

import * as S from './ScreenWMN1.style'

interface ScreenWMN1Props {
  side: ImageSide
}

function ScreenWMN1(props: ScreenWMN1Props) {
  const { side } = props

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const image = useContext(ImageContext)

  const fileInputRef = useRef(null)

  const [isUploading, setIsUploading] = useState(false)
  const [hasError, setHasError] = useState(false)

  function getHeaderKey() {
    // TODO Adapt this when doing Pootle String refactor
    const sideMap = { front: '1', back: '2' }
    const documentMap = {
      P: 'passport',
      I: 'id',
      D: 'dl',
      RP: 'rp',
      V: 'visa',
      R: 'rtd'
    }

    if (scan.documentType) {
      if (side === Side.Back && scan.documentType.id === DocumentType.Passport) {
        return 'WP1_title_back'
      }

      return `WN${sideMap[side]}_title_${documentMap[scan.documentType.id]}`
    }

    return 'WP1_title_front'
  }

  async function shouldScanBackside() {
    const response = await api.session.shouldScanBackside()
    const scanBack = response.scan_backside

    scan.setNeedsBackside(scanBack)
  }

  async function sendFile(file: File) {
    setIsUploading(true)

    const isValid = ALLOWED_IMAGE_TYPES.includes(file.type)

    if (isValid) {
      try {
        const dataUrl = await blobToDataUrl(file)
        const response = await api.image.postImage(side, dataUrl, UploadType.Phone)

        image.update(side, { imageId: response.image_id })

        scan.setImageId(response.image_id)

        await shouldScanBackside()

        router.next(side)
      } catch (error) {
        setIsUploading(false)
      }
    } else {
      setHasError(true)
      setIsUploading(false)
    }
  }

  function handleTakePicture(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      sendFile(event.target.files[0])
    }
  }

  function handleUploadButtonPressed() {
    if (fileInputRef.current) {
      ;(fileInputRef.current as HTMLElement).click()
    }
  }

  useEffect(() => {
    image.setSide(side)
  }, [side])

  useEffect(() => {
    if (side === Side.Front) {
      scan.reset()
      image.update(side, {}, true)
    }
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey={getHeaderKey()} />
      </Heading>

      <Body>
        <DivFlexSC flexDirection="column" height="100%">
          <>
            {side === Side.Front ? (
              <>
                <DocumentExample side={side} />

                {hasError && (
                  <S.ErrorMessage>
                    <VerifaiMessage id="WC2_error_file_format" />
                  </S.ErrorMessage>
                )}
              </>
            ) : (
              <DocumentExample side={side} />
            )}
          </>
        </DivFlexSC>
      </Body>

      <Footer>
        <BackButton isDisabled={isUploading} />

        <PrimaryButton
          messageId={side === Side.Front ? 'WN1_button_primary' : 'WN2_button_primary'}
          onClick={handleUploadButtonPressed}
          isLoading={isUploading}
          dataTestId="mobile-upload-continue-btn"
        />
      </Footer>

      <S.HiddenInput
        type="file"
        ref={fileInputRef}
        accept={ALLOWED_IMAGE_TYPES.join(',')}
        capture="environment"
        onChange={handleTakePicture}
      />
    </Screen>
  )
}

export default ScreenWMN1
