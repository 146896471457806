// Image
export const DEFAULT_MAX_DIMENSION = 512

// Background tasks
export const DEFAULT_JOB_DELAY = 1000
export const POLL_TIMEOUT_SHORT = 10000
export const POLL_TIMEOUT_LONG = 50000

export const DEFAULT_CROP_POINTS = [
  { x: 0.1, y: 0.1 },
  { x: 0.1, y: 0.9 },
  { x: 0.9, y: 0.9 },
  { x: 0.9, y: 0.1 }
]
export const DEFAULT_SUGGESTED_ROTATION = 0

// Local storage
export const STORAGE_KEY_HANDOVER_DATA = 'handoverData'

// TODO: Convert to enum when everything is TypeScript
export const ERROR_CODE = {
  JwtExpired: 'jwtExpired',
  CropFailed: 'cropFailed',
  DocumentValidationFailed: 'documentValidationFailed'
}

export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/bmp']
export const ALLOWED_FILE_TYPES = [...ALLOWED_IMAGE_TYPES, 'application/pdf']

export const UNKNOWN_ID_MODEL = 'unknown'

// Styling
export const ITEM_INACTIVE_OPACITY = 0.7
export const BUTTON_LUMINOSITY_AMOUNT = 0.04
export const IMAGE_WIDTH_MULTIPLIER = 35
