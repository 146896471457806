import React, { useContext, useEffect } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import { Icon } from 'components/Image/Icon'
import { SvgWrapperSC } from 'components/Image/Image.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'

function ScreenWMM1() {
  const router = useContext(RouterContext)
  const { session } = useContext(BackendContext)
  const scan = useContext(ScanContext)

  useEffect(() => {
    const documentType = session.selected_document_type

    scan.setDocumentType(documentType)
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WM1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WM1_description" />
        </Description>

        <SvgWrapperSC>
          <Icon id="wM1Image" />
        </SvgWrapperSC>
      </Body>

      <Footer>
        <BackButton />
        <PrimaryButton
          messageId="generic_button_continue"
          onClick={() => router.next()}
          dataTestId="handover-connected-continue-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWMM1
