import React, { useContext } from 'react'

import { Icon } from 'components/Image/Icon'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ConfigContext } from 'context/ConfigContextProvider'
import { AnalyticsEvent, ClickAction, UploadType } from 'lib/enums/common'
import { IconId } from 'typings/common'
import { Config } from 'typings/context'

import * as S from './DocumentUploadList.style'

interface DocumentUploadListItemProps {
  data: {
    type: UploadType
    icon: IconId
    titleId: string
    descriptionId: string
  }
  onClick: () => void
  isDisabled: boolean
}

function DocumentUploadListItem(props: DocumentUploadListItemProps) {
  const { data, onClick, isDisabled } = props

  const { rightToLeftScript } = useContext<Config>(ConfigContext)

  return (
    <S.DocumentUploadListItem
      onClick={onClick}
      dataTestId={`upload-type-${data.type}`}
      disabled={isDisabled}
      eventData={{
        type: AnalyticsEvent.SelectUploadType,
        action: ClickAction.ListItem,
        id: data.type
      }}
    >
      <S.DocumentUploadImage isRTL={rightToLeftScript}>
        <Icon id={data.icon} isDecorative />
      </S.DocumentUploadImage>
      <div>
        <S.TextBold>{data.titleId && <VerifaiMessage id={data.titleId} />}</S.TextBold>
        <S.Text>{data.descriptionId && <VerifaiMessage id={data.descriptionId} />}</S.Text>
      </div>
    </S.DocumentUploadListItem>
  )
}

export default DocumentUploadListItem
