import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PropTypes from 'prop-types'

import { MultiContext } from 'context/MultiContextProvider'

import { TransitionSC } from './Transition.style'

class Transition extends Component {
  static contextType = MultiContext

  constructor(props) {
    super(props)
    this.state = this.getScreenState(props)
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentScreen !== prevProps.currentScreen) {
      this.setState(this.getScreenState(this.props))
    }
  }

  getHash(name, props = null) {
    if (!props || !props.hasOwnProperty('side')) {
      return name
    }

    return `${name}-${props.side}`
  }

  getScreenState(props) {
    const current = props.currentScreen

    if (!current) {
      return { screens: [] }
    }

    return {
      screens: [
        {
          name: current.name,
          Screen: current.screen,
          props: current.props,
          hash: this.getHash(current.name, current.props)
        }
      ]
    }
  }

  getProps() {
    return this.context.router.current.props
  }

  getDirection(className1, className2) {
    const reverse = this.context.config.rightToLeftScript

    if (reverse) {
      ;[className2, className1] = [className1, className2]
    }

    return this.props.isBack ? className1 : className2
  }

  isStartScreen(name) {
    return name === this.context.router.startScreen
  }

  render() {
    const { prefix, duration } = this.props

    return (
      <TransitionGroup>
        {this.state.screens.map(({ name, Screen, props, hash }) => (
          <CSSTransition
            key={hash}
            timeout={duration}
            classNames={{
              appear: `${prefix}-appear`,
              appearActive: `${prefix}-active-appear`,
              appearDone: `${prefix}-done-appear`,
              enter: this.getDirection(`${prefix}-stay`, `${prefix}-enter`),
              enterActive: this.getDirection(`${prefix}-active-stay`, `${prefix}-active-enter`),
              enterDone: `${prefix}-done-enter`,
              exit: this.getDirection(`${prefix}-exit`, `${prefix}-stay`),
              exitActive: this.getDirection(`${prefix}-active-exit`, `${prefix}-active-stay`),
              exitDone: `${prefix}-done-exit`
            }}
            unmountOnExit
          >
            <TransitionSC prefix={prefix} duration={duration}>
              <Screen
                {...(props || {})}
                isStartScreen={this.isStartScreen(name)}
                api={this.context.backend.api}
                scan={this.context.scan}
                config={this.context.config}
                router={this.context.router}
              />
            </TransitionSC>
          </CSSTransition>
        ))}
      </TransitionGroup>
    )
  }
}

Transition.defaultProps = {
  duration: 300,
  prefix: 'screen'
}

Transition.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  prefix: PropTypes.string,
  duration: PropTypes.number,
  isBack: PropTypes.bool.isRequired,
  currentScreen: PropTypes.object
}

export default Transition
