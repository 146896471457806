type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

class RequestError extends Error {
  code: Response['status'] | null
  type: Response['type']

  constructor(response: Response) {
    super(response.statusText || '')

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError)
    }

    this.name = 'RequestError'
    this.code = response.status || null
    this.type = response.type || null
  }
}

function dataConfig(data = '', headers = {}, config = {}) {
  return {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: data
  }
}

function getConfig(headers = {}, config = {}) {
  return {
    ...config,
    headers
  }
}

export function pack(data: Record<string, unknown>) {
  try {
    return Promise.resolve(JSON.stringify(data))
  } catch (e) {
    return Promise.reject(e)
  }
}

export function unpack(response: Response) {
  return response.json()
}

export function validate(response: Response) {
  if (response.ok) {
    return Promise.resolve(response)
  }

  return Promise.reject(new RequestError(response))
}

export function request(method: HttpMethod, url: string, config = {}) {
  return fetch(url, { method, ...config }).then(validate)
}

export function get(url: string, headers = {}, config = {}) {
  return request('GET', url, getConfig(headers, config))
}

export function post(url: string, data = {}, headers = {}, config = {}) {
  return pack(data)
    .then(jsonData => dataConfig(jsonData, headers, config))
    .then(requestConfig => request('POST', url, requestConfig))
}

export function put(url: string, data = {}, headers = {}, config = {}) {
  return pack(data)
    .then(jsonData => dataConfig(jsonData, headers, config))
    .then(requestConfig => request('PUT', url, requestConfig))
}

export function del(url: string, headers = {}, config = {}) {
  return request('DELETE', url, getConfig(headers, config))
}

const requests = {
  get,
  post,
  put,
  delete: del,
  request,
  validate,
  pack,
  unpack
}

export default requests
