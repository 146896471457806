import styled from 'styled-components'

import { FontSize } from 'lib/enums/style'
import { fontSize } from 'src/style/mixins'

// TODO: [ep] We don't want a default export here so disable the ESLint rule for now.
// eslint-disable-next-line import/prefer-default-export
export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.bodyErrorText};
  font-size: ${fontSize(FontSize.Small)};
`
