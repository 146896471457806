import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { AnalyticsEvent, ClickAction } from 'lib/enums/common'

interface VerifaiButtonProps {
  onClick: (event: React.MouseEvent) => void
  disabled?: boolean
  dataTestId?: string
  eventData?: {
    action: ClickAction
    type?: AnalyticsEvent
    id?: string
    text?: string
  }
  ariaLabel?: string
  className?: string
  children?: React.ReactNode
}

function VerifaiButton(props: VerifaiButtonProps) {
  const { onClick, disabled, eventData, ariaLabel, className, children, dataTestId } = props

  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)

  const intl = useIntl()

  function handleOnClick(event: React.MouseEvent) {
    event.preventDefault()

    if (eventData) {
      if (eventData.id && intl.messages[eventData.id]) {
        eventData.text = intl.formatMessage({ id: eventData.id })
      }

      scan.addEvent(eventData.type || AnalyticsEvent.ClickButton, {
        screen: router.current.name,
        button: eventData
      })
    }

    if (!disabled && onClick) {
      onClick(event)
    }
  }

  const classNames = useMemo(() => {
    return ['button', className, disabled ? 'disabled' : 'enabled'].join(' ')
  }, [className, disabled])

  return (
    <button
      type="button"
      className={classNames}
      onClick={handleOnClick}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  )
}

export default VerifaiButton
