import React, { createContext, useMemo, useReducer, useState } from 'react'

import { Side } from 'lib/enums/common'
import { CropPoints, ImageSide } from 'typings/common'

const INITIAL_DATA = { imageUrl: null, rotation: null, cropPoints: [], imageId: null }

interface ImageContextProps {
  imageUrl: string | null
  cropPoints: CropPoints
  side: ImageSide
  rotation: number | null
  imageId: string | null
  setCropPoints: (cropPoints: CropPoints) => void
  setSide: (side: ImageSide) => void
  update: (
    side: ImageSide,
    data: Partial<ContextImages['front'] | ContextImages['back']>,
    shouldInitialize?: boolean
  ) => void
}

export const ImageContext = createContext<ImageContextProps>({} as ImageContextProps)

type ContextImages = Record<
  ImageSide,
  {
    imageUrl: string | null
    rotation: number | null
    cropPoints: ImageContextProps['cropPoints']
    imageId: string | null
  }
>

function reducer(state: ContextImages, action: { type: string; images: ContextImages }) {
  if (action.type === 'update') {
    return {
      ...state,
      ...action.images
    }
  }

  return state
}

interface ImageContextProviderProps {
  children: React.ReactNode
}

function ImageContextProvider(props: ImageContextProviderProps) {
  const { children } = props

  const [images, dispatch] = useReducer(reducer, {
    front: { imageUrl: null, rotation: null, cropPoints: [], imageId: null },
    back: { imageUrl: null, rotation: null, cropPoints: [], imageId: null }
  })
  const [side, setSide] = useState(Side.Front)

  const imageData = useMemo(() => {
    return images[side]
  }, [images, side])

  const value = useMemo(() => {
    function handleCropPoints(newCropPoints: ImageContextProps['cropPoints']) {
      const newImages = { ...images }

      newImages[side].cropPoints = newCropPoints

      dispatch({ type: 'update', images: newImages })
    }

    function handleUpdate(imageSide: ImageSide, data = {}, shouldInitialize = false) {
      const newImages = { ...images }

      if (shouldInitialize) {
        newImages[imageSide] = { ...INITIAL_DATA, ...data }
      } else {
        newImages[imageSide] = { ...newImages[imageSide], ...data }
      }

      dispatch({ type: 'update', images: newImages })
    }

    return {
      imageUrl: imageData.imageUrl,
      cropPoints: imageData.cropPoints,
      setCropPoints: handleCropPoints,
      side,
      setSide,
      rotation: imageData.rotation,
      imageId: imageData.imageId,
      update: handleUpdate
    }
  }, [side, images, dispatch, imageData])

  return <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
}

export default ImageContextProvider
