import React from 'react'

import { UploadType } from 'lib/enums/common'
import { CautionInfo } from 'typings/common'
import { Theme } from 'typings/context'

import { JWTWrapper } from './Backend/JWTWrapper'
import IntlContextProvider from './Intl/IntlContextProvider'
import RouterWrapper from './Router/RouterWrapper'
import ConfigContextProvider from './ConfigContextProvider'
import IconContextProvider from './IconContextProvider'
import ImageContextProvider from './ImageContextProvider'
import { MultiContextProvider } from './MultiContextProvider'
import ScanContextProvider from './ScanContextProvider'
import ThemeContextProvider from './ThemeContextProvider'

interface VerifaiContextProps {
  token: string
  backendUrl?: string
  isHandover?: boolean
  show?: boolean
  onSuccess?: () => void
  onCanceled?: () => void
  onError?: () => void
  closable?: boolean
  showInstruction?: boolean
  showGreeting?: boolean
  cautionInfo?: CautionInfo
  enableAttachments?: boolean
  uploadTypes?: Array<UploadType>
  faceMatchMobileOnly?: boolean
  display?: 'modal' | 'inline'
  countryCodeOption?: string
  assetsUrl?: string
  customAssetsUrl?: string
  theme?: Theme
  children?: React.ReactNode
}

function VerifaiContext(props: VerifaiContextProps) {
  const {
    token,
    onSuccess,
    onCanceled,
    onError,
    closable,
    faceMatchMobileOnly,
    display,
    countryCodeOption,
    assetsUrl,
    backendUrl = 'https://websdk.verifai.com/',
    isHandover = false,
    show = true,
    uploadTypes = [UploadType.File, UploadType.Phone],
    showInstruction = false,
    showGreeting = true,
    cautionInfo = null,
    enableAttachments = false,
    customAssetsUrl = '',
    theme = { corners: {}, colors: {} },
    children
  } = props

  return (
    <JWTWrapper otp={token} backendUrl={backendUrl} isHandover={isHandover} show={show}>
      <ConfigContextProvider
        onSuccess={onSuccess}
        onCanceled={onCanceled}
        onError={onError}
        show={show}
        closable={closable}
        showInstruction={showInstruction}
        showGreeting={showGreeting}
        cautionInfo={cautionInfo}
        uploadTypes={uploadTypes?.length > 0 ? uploadTypes : undefined} // Todo, jvw: fix this with param validation
        isHandover={isHandover}
        faceMatchMobileOnly={faceMatchMobileOnly}
        display={display}
        countryCodeOption={countryCodeOption}
        assetsUrl={assetsUrl}
        enableAttachments={enableAttachments}
      >
        <IntlContextProvider
          assetsUrl={assetsUrl}
          customAssetsUrl={customAssetsUrl}
          defaultLocale="en_US"
        >
          <ThemeContextProvider theme={theme}>
            <ScanContextProvider>
              <IconContextProvider iconPath={customAssetsUrl}>
                <ImageContextProvider>
                  <RouterWrapper>
                    <MultiContextProvider>{children}</MultiContextProvider>
                  </RouterWrapper>
                </ImageContextProvider>
              </IconContextProvider>
            </ScanContextProvider>
          </ThemeContextProvider>
        </IntlContextProvider>
      </ConfigContextProvider>
    </JWTWrapper>
  )
}

export default VerifaiContext
