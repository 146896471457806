import styled, { css } from 'styled-components'

import { DescriptionSC } from 'components/Description/VerifaiDescription.style'
import { Body } from 'components/Screen/Body/VerifaiBody.style'
import { FontSize, FontWeight } from 'lib/enums/style'
import { color, defaultSpacing, fontSize, scrollbar } from 'src/style/mixins'

export const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InstructionBody = styled(Body)`
  ${DescriptionSC} {
    /* Leave three lines worth of height reserved for the description. */
    height: 4.5rem;
  }
`

export const InstructionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const InstructionTableWrapper = styled.div`
  flex: inherit;
  position: relative;
  overflow: auto;

  ${scrollbar()}
`

export const InstructionTable = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin-bottom: ${defaultSpacing()};
`

export const InstructionTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  :not(:last-child) {
    margin-bottom: ${defaultSpacing()};
  }
`

export const ImageContainer = styled.div`
  position: relative;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 ${defaultSpacing(4)};

  ${ImageContainer} {
    :not(:last-child) {
      margin-bottom: ${defaultSpacing()};
    }
  }
`

export const InstructionTextContainer = styled.div`
  color: ${color('bodyText')};
  margin-bottom: ${defaultSpacing()};
  height: ${defaultSpacing(9)};
`

export const InstructionText = styled.div<{ isTable?: boolean }>`
  ${({ isTable }) =>
    isTable &&
    css`
      padding-left: ${defaultSpacing()};
      text-align: left;
      font-size: ${fontSize(FontSize.Small)};
      color: ${color('bodyText')};
    `}
`

export const Mark = styled.div<{ table?: boolean }>`
  position: absolute;
  top: ${defaultSpacing()};
  left: ${defaultSpacing()};
  width: ${defaultSpacing(4)};
  height: ${defaultSpacing(4)};

  ${({ table }) =>
    table &&
    css`
      top: 4px;
      left: 4px;
      width: 20px;
      height: 20px;

      svg {
        vertical-align: unset;
      }
    `}
`

export const Dots = styled.div`
  margin-top: ${defaultSpacing(3)};
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Dot = styled.div<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? color('primary') : '#757575')};
  border-radius: 50%;
  width: 10px;
  height: 10px;

  :not(:last-child) {
    margin-right: ${defaultSpacing()};
  }
`

export const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  color: ${color('dark')};
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const ListHeading = styled.span`
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${defaultSpacing()};
  align-self: flex-start;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${defaultSpacing()};

  div + span {
    margin-left: ${defaultSpacing()};
  }
`
