import styled from 'styled-components'

export const ReasonBoxSC = styled.div`
  background-color: ${props => props.theme.colors.imageBackground};
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 40px;
  text-align: left;
  color: ${props => props.theme.colors.textDescription};

  li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
