import { DEFAULT_MAX_DIMENSION } from 'lib/constants'
import { UploadType } from 'lib/enums/common'
import { CropPoints } from 'typings/common'
import { MrzResult, PrivacyZone, ScanZones } from 'typings/context'

import BaseApi from './BaseApi'

const imageApi = (api: BaseApi) => ({
  getQuadPrediction: (side: string) => api.get(`session/image/${side}/quad-prediction`),

  getTextPrediction: (side: string) =>
    api.get<{ text_angle: number }>(`session/image/${side}/text-prediction`),

  getMrzPrediction: (side: string) => api.get<MrzResult>(`session/image/${side}/mrz-prediction`),

  getMaskedImageSrc: (side: string) => api.getImage(`session/image/${side}/masked/binary`),

  getCroppedImageSrc: (side: string) => api.getImage(`session/image/${side}/cropped/binary`),

  getImage: (side: string) => api.get(`session/image/${side}/`),

  getAdjustableZones: (side: string) =>
    api.get<Array<PrivacyZone>>(`session/image/${side}/adjustable-zones`),

  getImageSrc: (side: string, rotation = 0, maxDimension = DEFAULT_MAX_DIMENSION) => {
    // Todo jvw, handle params in requests lib
    const params = `max_dimension=${maxDimension}&rotation=${rotation}`

    return api.getImage(`session/image/${side}/binary?${params}`)
  },

  postImageCrop: (side: string, cropData: { quad: CropPoints; rotation: number | null }) =>
    api.post(`session/image/${side}/crop`, cropData),

  postAdjustableZones: (side: string, adjustableZones: { zones: ScanZones }) =>
    api.post(`session/image/${side}/adjustable-zones`, adjustableZones),

  postImage: (side: string, imageDataUrl: string, uploadType?: UploadType) =>
    api.postImage(`session/image/${side}`, imageDataUrl, uploadType),

  postAttachments: (files: Array<File>) => api.postFiles(`session/attachments`, files),

  postPDF: (side: string, file: Blob) => api.postFile(`session/pdf/convert/${side}`, file),

  getPDFImage: (pdfImageId: string) => api.getImage(`session/pdf/${pdfImageId}`),

  postPDFImage: (pdfImageId: string) => api.post(`session/pdf/${pdfImageId}`),

  postFaceImage: (imageDataUrl: string) => api.postImage('session/facematch', imageDataUrl),

  postFacePDF: (file: File) => api.postFile('session/facematch', file)
})

export default imageApi
