import React, { useContext, useMemo } from 'react'

import { IconContext } from 'context/IconContextProvider'
import { IconId } from 'typings/common'

import * as S from './Image.style'

interface IconProps {
  id: IconId
  width?: number
  rotate?: number
  disabled?: boolean
  isDecorative?: boolean
  className?: string
}

function Icon(props: IconProps) {
  const { id, width, rotate, disabled = false, isDecorative, className } = props

  const iconContext = useContext(IconContext)

  const iconData = useMemo(() => {
    return iconContext.getIcon(id)
  }, [iconContext, id])

  if (!iconData) {
    return null
  }

  if (iconData.customUrl) {
    return (
      <S.IconWrapper id={id} width={width} isDisabled={disabled} className={className}>
        <img src={iconData.customUrl} alt="" />
      </S.IconWrapper>
    )
  }

  const IconComponent = iconData.icon

  return (
    <S.IconWrapper
      id={id}
      width={width}
      rotate={rotate}
      isDisabled={disabled}
      className={className}
    >
      <IconComponent aria-hidden={isDecorative} />
    </S.IconWrapper>
  )
}

// Keep old export to ensure backwards compatibility with other components.
// TODO: Remove this export once all imports are been updated to use default export.
export { Icon }

export default Icon
