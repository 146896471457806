import styled from 'styled-components'

import { Breakpoint } from 'lib/enums/style'
import { defaultSpacing } from 'src/style/mixins'

// eslint-disable-next-line import/prefer-default-export
export const FitImageSC = styled.div`
  width: auto;
  height: 100%;
  min-height: ${defaultSpacing(30)};
  max-height: ${defaultSpacing(35)};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoint.Small}px) {
    min-height: ${defaultSpacing(25)};
  }
`
