import styled, { css } from 'styled-components'

import { IconWrapper } from 'components/Image/Image.style'
import { ITEM_INACTIVE_OPACITY } from 'lib/constants'
import { FontSize } from 'lib/enums/style'
import {
  borderRadius,
  color,
  defaultSpacing,
  focusRing,
  fontSize,
  scrollbar,
  transparentize
} from 'src/style/mixins'

export const NavigationArrow = styled.button<{ isDisabled?: boolean }>`
  background: none;
  border: 2px solid ${color('primary')};
  border-radius: 50%;
  padding: 0;

  ${focusRing()}

  svg {
    circle {
      transition: fill 0.15s ease-in-out;
      fill: #fff;
    }

    path {
      fill: ${color('primary')};
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      circle {
        fill: ${transparentize('primary', 0.9)};
      }
    }
  }

  &:active {
    svg {
      circle {
        fill: ${transparentize('primary', 0.8)};
      }
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: ${ITEM_INACTIVE_OPACITY};
    `}
`

export const Carousel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const ImageContainer = styled.div`
  border-radius: ${borderRadius(2)};
  width: 100%;
  margin: 0 ${defaultSpacing(2)};
`

export const IdModelImage = styled.img`
  width: ${defaultSpacing(40)};
`

export const UnknownImageContainer = styled(ImageContainer)`
  height: 80%;
`

export const UnknownModelImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: ${borderRadius(2)};
  padding: ${defaultSpacing()};
  background: ${color('uiBackground')};
  color: ${color('dark')};

  ${IconWrapper} {
    margin-top: auto;
  }

  span {
    margin-top: auto;
  }
`

export const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Thumbnails = styled.div`
  flex-wrap: nowrap;
  overflow: auto;
  display: flex;
  padding: ${defaultSpacing()} 0;
  margin: 0 auto;
  margin-top: auto;

  ${scrollbar()}
`

export const Thumbnail = styled.img`
  display: block;
  min-width: ${defaultSpacing(10)};
  height: 100%;
  background: ${color('uiBackground')};
  border-radius: ${borderRadius()};
  font-size: ${fontSize(FontSize.Small)};
`

export const UnknownThumbnail = styled.div`
  padding: ${defaultSpacing()};
  background: ${color('uiBackground')};
  border-radius: ${borderRadius()};
  height: 100%;
`

export const ThumbnailContainer = styled.div<{ isSelected: boolean; aspectRatio?: number | null }>`
  border-radius: ${borderRadius()};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isSelected }) => (isSelected ? color('primary') : color('light'))};
  padding: ${defaultSpacing(0.5)};
  height: ${defaultSpacing(10)};

  ${({ aspectRatio }) =>
    aspectRatio &&
    css`
      height: ${defaultSpacing(35 / aspectRatio)};
    `}

  :not(:last-child) {
    margin-right: ${defaultSpacing()};
  }

  &:hover {
    cursor: pointer;
  }

  ${Thumbnail} {
    opacity: ${({ isSelected }) => (isSelected ? 1 : ITEM_INACTIVE_OPACITY)};

    :hover {
      opacity: 1;
    }
  }

  ${IconWrapper} {
    height: 100%;
    opacity: ${({ isSelected }) => (isSelected ? 1 : ITEM_INACTIVE_OPACITY)};
    min-width: ${defaultSpacing(10)};

    :hover {
      opacity: 1;
    }

    svg {
      height: 100%;
      width: auto;
    }
  }
`

export const PdfContainer = styled.div`
  border-radius: ${borderRadius(2)};
  width: 100%;
  height: ${defaultSpacing(35)};
  margin: 0 ${defaultSpacing(2)};
`

export const PdfImage = styled.img`
  height: 100%;
  width: auto;
`

export const PdfThumbnail = styled(Thumbnail)`
  min-width: ${defaultSpacing(5)};
`

export const ItemContainer = styled.div``

export const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: ${fontSize(FontSize.Small)};

  ${ItemContainer} {
    margin-bottom: ${defaultSpacing()};
  }

  ${ThumbnailContainer} {
    width: ${defaultSpacing(35)};
    margin-right: 0;
    display: flex;
    flex-direction: column;

    span {
      display: block;
      margin-top: ${defaultSpacing()};
    }

    ${Thumbnail} {
      width: 100%;
      height: 100%;
    }

    ${UnknownThumbnail} {
      height: unset;
    }
  }

  ${IconWrapper} {
    padding-top: ${defaultSpacing()};
    height: auto;

    svg {
      height: 50%;
      width: 50%;
    }
  }
`
