import React, { useCallback, useState } from 'react'

import LinkButton from 'components/Button/LinkButton'
import { Icon } from 'components/Image/Icon'
import InstructionImage from 'components/Image/InstructionImage'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'

import * as S from './Instruction.style'

interface InstructionTableProps {
  instructions: Array<{ labelId: string; messageId: string }>
}

function InstructionTable(props: InstructionTableProps) {
  const { instructions } = props

  const [amountInstructions, setAmountInstructions] = useState(2)
  const [isHidden, setIsHidden] = useState(false)

  const viewMore = useCallback(() => {
    setIsHidden(true)
    setAmountInstructions(7)
  }, [setAmountInstructions, setIsHidden])

  return (
    <S.InstructionTable>
      {instructions.map((item, index) => {
        const key = `instruction-${index}`

        return (
          index < amountInstructions && (
            <S.InstructionTableRow key={key}>
              <S.ImageContainer>
                <S.Mark table>
                  <Icon id="crossCircleImage" />
                </S.Mark>

                <InstructionImage isTable imageKey={item.labelId} />
              </S.ImageContainer>

              <S.InstructionText isTable>
                <VerifaiMessage id={item.messageId} />
              </S.InstructionText>
            </S.InstructionTableRow>
          )
        )
      })}

      <S.InstructionTableRow>
        <S.ImageContainer>
          <S.Mark table>
            <Icon id="checkCircleImage" />
          </S.Mark>

          <InstructionImage isTable imageKey="correct" />
        </S.ImageContainer>

        <S.InstructionText isTable>
          <VerifaiMessage id="WI1_description_correct" />
        </S.InstructionText>
      </S.InstructionTableRow>

      {!isHidden && <LinkButton messageId="WN1_button_view" onClick={viewMore} />}
    </S.InstructionTable>
  )
}

export default InstructionTable
