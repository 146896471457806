import React, { useContext, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import DropFile from 'components/DropFile/DropFile'
import { Icon } from 'components/Image/Icon'
import { SvgWrapperSC } from 'components/Image/Image.style'
import { DivSC } from 'components/Screen/Body/Elements.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { ErrorMessage } from 'components/Text/Text.style'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { ALLOWED_FILE_TYPES } from 'lib/constants'
import { FileType } from 'lib/enums/common'
import blobToDataUrl from 'utils/blobToDataUrl'

import * as S from './ScreenWMF.style'

function ScreenWMF1() {
  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const config = useContext(ConfigContext)

  const [isUploading, setIsUploading] = useState(false)
  const [isInputValid, setIsInputValid] = useState(true)

  const fileInputRef = useRef<HTMLInputElement>(null)

  async function upload(file: File) {
    if (file.type === FileType.PDF) {
      return api.image.postFacePDF(file)
    }

    const dataUrl = await blobToDataUrl(file)

    return api.image.postFaceImage(dataUrl)
  }

  async function sendFile(file: File) {
    setIsUploading(true)

    if (ALLOWED_FILE_TYPES.includes(file.type)) {
      const imageId = await upload(file)

      scan.setFaceImageId(imageId)

      router.next()
    } else {
      setIsInputValid(false)
    }
  }

  function handleDrop(files: FileList) {
    sendFile(files[0])
  }

  function handleTakePicture(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      sendFile(event.target.files[0])
    }
  }

  function handleUploadButtonPressed() {
    fileInputRef.current?.click()
  }

  return (
    <Screen>
      {config.isMobile ? (
        <>
          <Heading>
            <Header messageKey="WMF1_mobile_title" />
          </Heading>

          <Body>
            <Description>
              <VerifaiMessage id="WMF1_mobile_description" />
            </Description>

            <SvgWrapperSC>
              <Icon id="wmf1Image" />
            </SvgWrapperSC>
          </Body>

          <S.HiddenInput
            type="file"
            ref={fileInputRef}
            accept={'image/*'}
            capture="user"
            onChange={handleTakePicture}
            multiple={false}
          />

          <Footer>
            <BackButton />

            <PrimaryButton
              messageId="WN1_button_primary"
              onClick={handleUploadButtonPressed}
              isLoading={isUploading}
            />
          </Footer>
        </>
      ) : (
        <>
          <Heading>
            <Header messageKey="WMF1_web_title" />
          </Heading>

          <Body>
            <Description>
              <VerifaiMessage id="WMF1_web_description" />
            </Description>

            <SvgWrapperSC>
              <Icon id="wmf1Image" />
            </SvgWrapperSC>

            <DivSC marginTop="auto">
              {!isInputValid && (
                <ErrorMessage>
                  <VerifaiMessage id="WC2_error_file_format" />
                </ErrorMessage>
              )}

              <Description>
                <VerifaiMessage id="WC2_dragdrop_title" />
              </Description>

              <Description>
                <VerifaiMessage id="WC2_dragdrop_or" />
              </Description>
            </DivSC>
          </Body>

          <Footer>
            <BackButton isDisabled={isUploading} />

            <DropFile isUploading={isUploading} onDrop={handleDrop} />
          </Footer>
        </>
      )}
    </Screen>
  )
}

export default ScreenWMF1
