import React, { useContext, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import CountryListSelection from 'components/CountryList/CountryListSelection'
import Description from 'components/Description/VerifaiDescription'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { Country } from 'typings/common'

function ScreenWF1() {
  const [isProcessing, setIsProcessing] = useState(false)

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const config = useContext(ConfigContext)

  async function setDocument(uuid: string | null) {
    const document = await api.session.setSelectedModel(uuid)

    scan.setDocument(document)
  }

  async function deductDocument() {
    if (scan.documentType) {
      const docType = scan.documentType.id
      const docs = await api.session.getIdModels(docType)

      if (docs.length === 1) {
        setDocument(docs[0].uuid)
      } else {
        setDocument(null)
      }
    }
  }

  async function handleCountrySelection(countryCode: Country['code']) {
    setIsProcessing(true)

    scan.setCountry(countryCode)

    try {
      await api.session.setSelectedCountry(countryCode)

      const uuid = await api.session.getClassificationResult()

      scan.setClassifiedUuid(uuid)

      await setDocument(uuid)
      await deductDocument()
    } catch (error) {
      console.error(error)
    }

    router.next()
  }

  return (
    <Screen>
      <Heading>
        <Header messageKey="WF1_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WF1_description" />
        </Description>

        {config.countries && (
          <CountryListSelection
            countries={config.countries}
            suggestedCountry={config.countryFinalSuggestion}
            onClick={handleCountrySelection}
            showSearch={config.countries.length > 10}
            searchPlaceholderId="WF1_country_search"
            suggestedLabelId="WF1_country_suggested"
          />
        )}
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />
      </Footer>
    </Screen>
  )
}

export default ScreenWF1
