import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { NavigationArrow } from 'components/Carousel/Carousel.style'
import { Icon } from 'components/Image/Icon'
import InstructionImage from 'components/Image/InstructionImage'

import * as S from './Instruction.style'
import { InstructionContainer } from './Instruction.style'

interface InstructionGridProps {
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  instructions: Array<{ labelId: string; messageId: string }>
}

function InstructionGrid(props: InstructionGridProps) {
  const { selectedIndex, setSelectedIndex, instructions } = props

  const intl = useIntl()

  const selectedInstruction = useMemo(() => {
    return instructions[selectedIndex]
  }, [selectedIndex, instructions])

  function handleNavigation(amount: number) {
    let newIndex = selectedIndex + amount

    if (newIndex < 0) {
      newIndex = instructions.length - 1
    } else if (newIndex > instructions.length - 1) {
      newIndex = 0
    }

    setSelectedIndex(newIndex)
  }

  return (
    <S.InstructionWrapper>
      <InstructionContainer>
        <NavigationArrow
          onClick={() => handleNavigation(-1)}
          aria-label={intl.formatMessage({ id: 'WI1_previous_requirement' })}
        >
          <Icon id="documentVersionArrowBackImage" />
        </NavigationArrow>

        <S.ImagesContainer>
          <S.ImageContainer>
            <S.Mark>
              <Icon id="crossCircleImage" />
            </S.Mark>

            <InstructionImage imageKey={selectedInstruction.labelId} />
          </S.ImageContainer>

          <S.ImageContainer>
            <S.Mark>
              <Icon id="checkCircleImage" />
            </S.Mark>

            <InstructionImage imageKey="correct" />
          </S.ImageContainer>
        </S.ImagesContainer>

        <NavigationArrow
          onClick={() => handleNavigation(1)}
          aria-label={intl.formatMessage({ id: 'WI1_next_requirement' })}
        >
          <Icon id="documentVersionArrowforwardImage" />
        </NavigationArrow>
      </InstructionContainer>

      <S.Dots>
        {instructions.map(({ labelId }, index) => (
          <S.Dot key={labelId} isSelected={selectedIndex === index} />
        ))}
      </S.Dots>
    </S.InstructionWrapper>
  )
}

export default InstructionGrid
