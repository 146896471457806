import React, { useContext, useEffect, useMemo, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import LinkButton from 'components/Button/LinkButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import FitImage from 'components/Image/FitImage'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { DEFAULT_JOB_DELAY } from 'lib/constants'
import PromisePoller from 'utils/PromisePoller'

import * as S from './ScreenWMF.style'

enum FaceMatchState {
  Processing = 'processing',
  Match = 'match',
  NoMatch = 'no_match',
  NoFaceDetected = 'no_face_detected'
}

function ScreenWMF2() {
  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)

  const [poller, setPoller] = useState<PromisePoller>(null)
  const [hasMatch, setHasMatch] = useState(false)
  const [faceDetected, setFaceDetected] = useState(false)
  const [isProcessing, setIsProcessing] = useState(true)
  const [imageObjectUrl, setImageObjectUrl] = useState<string | null>(null)

  async function loadImage() {
    const result = await api.image.getImageSrc('face')

    const objectUrl = URL.createObjectURL(result)

    setImageObjectUrl(objectUrl)
  }

  async function initialize() {
    const newPoller = await api.job.poller(['face_match'], DEFAULT_JOB_DELAY, 0)

    newPoller.run()

    setPoller(newPoller)

    try {
      await loadImage()
      setIsProcessing(false)
      setFaceDetected(true)

      const faceMatchResult = await api.session.getFacematchResult()

      setHasMatch(faceMatchResult.matches)
    } catch (error) {
      console.error(error)

      setFaceDetected(false)
    }
  }

  const faceMatchState = useMemo(() => {
    if (isProcessing) {
      return FaceMatchState.Processing
    }

    if (faceDetected) {
      return hasMatch ? FaceMatchState.Match : FaceMatchState.NoMatch
    }

    return FaceMatchState.NoFaceDetected
  }, [isProcessing, faceDetected, hasMatch])

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    return function cleanup() {
      if (poller) {
        poller.reset()
      }
    }
  }, [poller])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WMF2_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WMF2_description" />
        </Description>

        <S.BodyWrapper>
          <S.ImageWrapper>
            <FitImage src={imageObjectUrl} />
          </S.ImageWrapper>
        </S.BodyWrapper>

        <S.RetryWrapper>
          {imageObjectUrl && (
            <LinkButton messageId="WMF2_button_no_retry" onClick={() => router.back()} />
          )}
        </S.RetryWrapper>
      </Body>

      <Footer>
        <BackButton isDisabled={faceMatchState === FaceMatchState.Processing} />

        <PrimaryButton
          messageId="WMF2_button_submit"
          onClick={() => router.next()}
          disabled={faceMatchState === FaceMatchState.Processing}
          dataTestId="face-match-submit-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWMF2
