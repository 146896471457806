import { useContext, useEffect, useState } from 'react'

import CropCanvas from 'components/CropCanvas/CropCanvas'
import { BackendContext } from 'context/Backend/BackendContext'
import { ImageContext } from 'context/ImageContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { ERROR_CODE } from 'lib/constants'
import { Direction, HttpCode } from 'lib/enums/common'
import { CropPoints, ImageSide } from 'typings/common'
import PromisePoller from 'utils/PromisePoller'
import VError from 'utils/VError'

export function rotateCropPoints(
  cropPoints: CropPoints,
  direction: Direction.Left | Direction.Right,
  times = 1
): CropPoints {
  // Nested function to rotate the crop points one time with 90 degrees in the required direction
  const mapPoints = (points: CropPoints): CropPoints =>
    points.map(({ x, y }) =>
      direction === Direction.Left ? { x: y, y: 1 - x } : { x: 1 - y, y: x }
    )

  if (times === 1) {
    return mapPoints(cropPoints)
  }

  const newPoints = mapPoints(cropPoints)

  return rotateCropPoints(newPoints, direction, times - 1)
}

function useImageProcessing(side: ImageSide, cropCanvas?: CropCanvas, poller?: PromisePoller) {
  const scan = useContext(ScanContext)
  const { api } = useContext(BackendContext)
  const image = useContext(ImageContext)

  const [isLoading, setIsLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  async function loadImage(imageSide: ImageSide, rotation: number) {
    if (rotation === null) {
      return null
    }

    setIsLoading(true)

    const response = await api.image.getImageSrc(imageSide, rotation)
    const objectUrl = URL.createObjectURL(response)

    setIsLoading(false)

    return objectUrl
  }

  async function updateScanDocument(uuid: string | null) {
    const document = await api.session.setSelectedModel(uuid)

    scan.setDocument(document)
  }

  async function handleMrzPrediction() {
    const result = await api.image.getMrzPrediction(side)

    scan.setMrzResult(result)

    if (result.violations.length > 0) {
      await api.session.documentValidation()
    }
  }

  async function handleDocumentPrediction() {
    const uuid = await api.session.getClassificationResult()

    scan.setClassifiedUuid(uuid)

    await updateScanDocument(uuid)
  }

  async function handlePredictions() {
    await handleMrzPrediction()
    await handleDocumentPrediction()
  }

  async function deductDocument() {
    if (scan.documentType) {
      const documentType = scan.documentType.id
      const documents = await api.session.getIdModels(documentType)
      const documentUuid = documents.length === 1 ? documents[0].uuid : null

      await updateScanDocument(documentUuid)
    }
  }

  async function shouldScanBackside() {
    const response = await api.session.shouldScanBackside()
    const scanBack = response.scan_backside

    scan.setNeedsBackside(scanBack)
  }

  function getCropData() {
    if (cropCanvas?.current) {
      const points = cropCanvas.current.getSelectionPoints()

      return { quad: points, rotation: image.rotation }
    }

    return { quad: [], rotation: 0 }
  }

  async function getRotatedData(direction: Direction.Left | Direction.Right) {
    if (cropCanvas?.current && image.rotation !== null) {
      // Get current selection, so it doesn't reset to the suggested points
      const currentCropPoints = cropCanvas.current.getSelectionPoints()
      const rotatedCropPoints = rotateCropPoints(currentCropPoints, direction)

      const amount = direction === Direction.Left ? 3 : 1
      const newRotation = (image.rotation + amount) % 4

      return { cropPoints: rotatedCropPoints, rotation: newRotation }
    }

    return null
  }

  async function processImage() {
    setIsProcessing(true)

    const cropData = getCropData()

    await api.image.postImageCrop(side, cropData)

    // TODO: [ep] The following code is very ugly.
    // It's needed as we want to continue the flow even if one of the steps fails.
    // Otherwise the app might get into an incorrect state (e.g. still requiring two sides per document).
    try {
      if (poller) {
        await poller.promise
      }

      await handlePredictions()
      await deductDocument()
    } catch (error: VError) {
      if (error.code === HttpCode.ServerError500) {
        throw new VError({ showCloseButton: false, type: ERROR_CODE.CropFailed })
      }
    }

    try {
      if (poller) {
        await poller.promise
      }

      await shouldScanBackside()
    } catch (error: VError) {
      if (error.code === HttpCode.ServerError500) {
        throw new VError({ showCloseButton: false, type: ERROR_CODE.CropFailed })
      }
    }
  }

  useEffect(() => {
    return function cleanup() {
      setIsProcessing(false)
    }
  }, [])

  return { loadImage, getRotatedData, processImage, isProcessing, isLoading }
}

export default useImageProcessing
