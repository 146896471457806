import React, { useContext, useEffect, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import PrimaryButton from 'components/Button/PrimaryButton'
import Description from 'components/Description/VerifaiDescription'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import PDFSelection from 'components/PDFSelection/PDFSelection'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { Side } from 'lib/enums/common'

interface ScreenWE2Props {
  side: Side
}

function ScreenWE2(props: ScreenWE2Props) {
  const { side } = props

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [pdfImages, setPdfImages] = useState([])

  const pdfSelectionRef = useRef(null)

  async function setupImages() {
    const promises = scan.pdfImages.map(({ image_id: id }) => api.image.getPDFImage(id))

    const result = await Promise.all(promises)

    const newPdfImages = result
      .map(blob => ({ image: blob }))
      .map((item, i) => ({ image: item.image, image_id: scan.pdfImages[i].image_id }))

    setPdfImages(newPdfImages)
    setIsLoading(false)
  }

  async function handleContinue() {
    if (pdfSelectionRef.current) {
      setIsProcessing(true)

      const selectedPage = (pdfSelectionRef.current as PDFSelection).getSelectedPage()

      setIsProcessing(true)

      await api.image.postPDFImage(selectedPage)

      router.next(side)
    }
  }

  useEffect(() => {
    setupImages()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WE2_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WE2_description" />
        </Description>

        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <PDFSelection ref={pdfSelectionRef} choices={pdfImages} />
        )}
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />

        <PrimaryButton
          messageId="WE1_button_primary"
          onClick={handleContinue}
          disabled={isLoading}
          isLoading={isProcessing}
          dataTestId="pdf-upload-continue-btn"
        />
      </Footer>
    </Screen>
  )
}

export default ScreenWE2
