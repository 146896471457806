import React, { useContext, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import Description from 'components/Description/VerifaiDescription'
import DocumentUploadList from 'components/DocumentUploadList/DocumentUploadList'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { UploadType } from 'lib/enums/common'

function ScreenWC3() {
  const scan = useContext(ScanContext)
  const router = useContext(RouterContext)

  const [isProcessing, setIsProcessing] = useState(false)

  function onNext(uploadType: UploadType) {
    setIsProcessing(true)

    scan.setUploadType(uploadType)

    router.next(uploadType)
  }

  return (
    <Screen>
      <Heading>
        <Header messageKey="WC3_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WC3_description" />
        </Description>

        <DocumentUploadList onClick={onNext} isDisabled={isProcessing} />
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />
      </Footer>
    </Screen>
  )
}

export default ScreenWC3
