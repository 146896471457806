import React from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import FocusTrap from 'focus-trap-react'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import { ConfigContext } from 'context/ConfigContextProvider'

import Promo from '../Promo/Promo'

import { ModalBackgroundSC, ModalMobileSC, ModalSC, RelWrapperSC, WrapperSC } from './Modal.style'

class Modal extends BaseComponent {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.targetRef = React.createRef()

    this.setDisableBodyScroll = this.setDisableBodyScroll.bind(this)
  }

  setDisableBodyScroll() {
    // Don't remove scroll bar if we are on mobile, (not always supported there)
    if (this.context.isMobile) {
      return
    }

    // Don't remove body scroll on inline display
    if (this.context.display === 'inline') {
      return
    }

    if (!this.context.show) {
      return clearAllBodyScrollLocks()
    }

    const options = { reserveScrollBarGap: true }

    disableBodyScroll(this.targetRef.current, options)
  }

  componentDidUpdate() {
    this.setDisableBodyScroll()
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    const { isDemo, isMobile, display, show } = this.context

    // Don't show if show is false
    if (!show) {
      return null
    }

    // Mobile fullscreen
    if (isMobile) {
      return (
        <ModalMobileSC ref={this.targetRef} demo={isDemo} data-testid="verifai-modal">
          {this.props.children}
          <Promo demo={isDemo} />
        </ModalMobileSC>
      )
    }

    // No modal (only on desktop)
    if (display === 'inline') {
      return (
        <RelWrapperSC>
          <WrapperSC>{this.props.children}</WrapperSC>
        </RelWrapperSC>
      )
    }

    // Show modal
    return (
      <ModalBackgroundSC>
        <FocusTrap>
          <ModalSC
            ref={this.targetRef}
            demo={isDemo}
            data-testid="verifai-modal"
            role="dialog"
            aria-modal="true"
          >
            {this.props.children}
          </ModalSC>
        </FocusTrap>

        <Promo demo={isDemo} />
      </ModalBackgroundSC>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node
}

export default Modal
