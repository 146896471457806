type NonceWindow = Window &
  typeof globalThis & {
    __webpack_nonce__: string
  }

/**
 * Sets the nonce field on the style sheet
 * Styled-Components does not do this if the nonce changes, so we have to do it for them.
 *
 */
function setNonceOnStyleSheet(nonce: string) {
  const elems = document.querySelectorAll('style[verifai-styles="active"]') || []

  if (nonce) {
    elems.forEach(elem => elem.setAttribute('nonce', nonce))

    return
  }

  elems.forEach(elem => elem.removeAttribute('nonce'))
}

/**
 * Sets the nonce in the style sheet
 * And in the global __webpack_nonce__ for styled components:
 * https://github.com/styled-components/styled-components/issues/887
 *
 */
export default function setNonce(nonce: string) {
  if (window && nonce && nonce !== (window as NonceWindow).__webpack_nonce__) {
    ;(window as NonceWindow).__webpack_nonce__ = nonce
  }

  setNonceOnStyleSheet(nonce)
}
