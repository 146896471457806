import React from 'react'

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { ClickAction, Size } from 'lib/enums/common'

import { ButtonPrimarySC } from './VerifaiButton.style'

interface PrimaryButtonProps {
  messageId: string
  onClick: (event: React.MouseEvent) => void
  disabled?: boolean
  isLoading?: boolean
  dataTestId?: string
}

function PrimaryButton(props: PrimaryButtonProps) {
  const { onClick, messageId, disabled, isLoading, dataTestId } = props

  function handleOnClick(event: React.MouseEvent) {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <ButtonPrimarySC
      onClick={handleOnClick}
      disabled={disabled || isLoading}
      dataTestId={dataTestId}
      eventData={{ action: ClickAction.Primary, id: messageId }}
    >
      {isLoading ? (
        <LoadingIndicator color="light" size={Size.Small} />
      ) : (
        <VerifaiMessage id={messageId} />
      )}
    </ButtonPrimarySC>
  )
}

export default PrimaryButton
