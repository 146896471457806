import React from 'react'

import ScaleText from 'components/Text/ScaleText'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'

import * as S from './VerifaiHeader.style'

interface VerifaiHeaderProps {
  messageKey: string
}

function VerifaiHeader(props: VerifaiHeaderProps) {
  // TODO: [ep] Rename `messageKey` to `messageId` after refactor.
  const { messageKey: messageId } = props

  return (
    <S.Header>
      <ScaleText minFontSize={24} maxFontSize={32}>
        <VerifaiMessage id={messageId} />
      </ScaleText>
    </S.Header>
  )
}

export default VerifaiHeader
