import React, { useContext, useEffect, useMemo, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import LinkButton from 'components/Button/LinkButton'
import Description from 'components/Description/VerifaiDescription'
import DocumentTypeList from 'components/DocumentTypeList/DocumentTypeList'
import { DocumentTypeGroupSC } from 'components/DocumentTypeList/DocumentTypeList.style'
import Body from 'components/Screen/Body/VerifaiBody'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { ConfigContext } from 'context/ConfigContextProvider'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { DocumentType } from 'lib/enums/common'
import { DocumentTypeData } from 'typings/common'

const DEFAULT_LIST_SIZE = 3

function ScreenWB1() {
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)
  const config = useContext(ConfigContext)
  const backend = useContext(BackendContext)

  const [shouldShowOthers, setShouldShowOthers] = useState(false)
  const [primaryList, setPrimaryList] = useState<Array<DocumentTypeData>>([])
  const [secondaryList, setSecondaryList] = useState<Array<DocumentTypeData>>([])
  const [isProcessing, setIsProcessing] = useState(false)

  const documentList = useMemo(() => {
    if (shouldShowOthers) {
      return [...primaryList, ...secondaryList]
    }

    return primaryList
  }, [shouldShowOthers, primaryList, secondaryList])

  function setupDocumentLists(documentListSize = DEFAULT_LIST_SIZE) {
    const docList = Object.values(config.documentTypes)
    const primaryDocs = docList.filter(item => item.primary)
    const secondaryDocs = docList.filter(item => !item.primary)
    const extraItemAmount = Math.max(documentListSize - primaryDocs.length, 0)

    setPrimaryList(primaryDocs.concat(secondaryDocs.slice(0, extraItemAmount)))
    setSecondaryList(secondaryDocs.slice(extraItemAmount))
  }

  async function setDocumentType(docType: DocumentType) {
    setIsProcessing(true)

    scan.setDocumentType(docType)

    await backend.api.session.setSelectedDocumentType(docType)

    setIsProcessing(false)
  }

  function handleDocumentTypeClick(id: DocumentType) {
    setDocumentType(id)

    router.next()
  }

  function handleClickOtherDocs() {
    setShouldShowOthers(true)
  }

  useEffect(() => {
    setupDocumentLists()
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WB1_title" />
      </Heading>

      <Body allowScroll>
        <Description>
          <VerifaiMessage id="WB1_description" />
        </Description>

        <DocumentTypeGroupSC>
          <DocumentTypeList
            documentList={documentList}
            onItemClick={handleDocumentTypeClick}
            isDisabled={isProcessing}
          />

          {secondaryList.length > 0 && !shouldShowOthers && (
            <LinkButton messageId="WB1_button_other" onClick={handleClickOtherDocs} />
          )}
        </DocumentTypeGroupSC>
      </Body>

      <Footer>
        <BackButton isDisabled={isProcessing} />
      </Footer>
    </Screen>
  )
}

export default ScreenWB1
