import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from '../../BaseComponent/BaseComponent'
import { VerifaiMessage } from '../../Text/VerifaiIntl'
import ErrorReasonBox from '../ErrorReasonBox'

class DocumentErrorBody extends BaseComponent {
  render() {
    return (
      <>
        <ErrorReasonBox reasons={this.props.error.reasons} />
        <p>
          <VerifaiMessage id={this.props.error.hintStringId} />
        </p>
      </>
    )
  }
}

DocumentErrorBody.propTypes = {
  children: PropTypes.node
}

export default DocumentErrorBody
