import React from 'react'
import PropTypes from 'prop-types'

import BaseComponent from '../../BaseComponent/BaseComponent'
import { Icon } from '../../Image/Icon'
import { SvgWrapperSC } from '../../Image/Image.style'

class VErrorBody extends BaseComponent {
  render() {
    return (
      <SvgWrapperSC>
        <Icon id="warningImage" alt="Warning" />
      </SvgWrapperSC>
    )
  }
}

VErrorBody.propTypes = {
  children: PropTypes.node
}

export default VErrorBody
