import { useContext, useState } from 'react'

import { BackendContext } from 'context/Backend/BackendContext'
import { ScanContext } from 'context/ScanContextProvider'
import { Scan } from 'typings/context'
import { HandoverData } from 'typings/handover'

const INITIAL_HANDOVER_DATA: HandoverData = {
  id: '',
  secret: null,
  url: null,
  expiresAt: null,
  phoneNumber: ''
}

export default function useHandoverData() {
  const scan = useContext<Scan>(ScanContext)
  const { api } = useContext(BackendContext)

  const [handoverData, setHandoverData] = useState(INITIAL_HANDOVER_DATA)

  async function createHandover() {
    const response = await api.handover.createHandover()
    const expirationDate = Date.parse(response.expire)

    setHandoverData({
      id: response.handover_id,
      secret: response.secret,
      url: response.handover_base_url,
      expiresAt: expirationDate,
      phoneNumber: ''
    })

    await scan.setHandover(null, response.handover_id, expirationDate)

    return response.handover_id
  }

  function update(field: string, value: string) {
    setHandoverData({ ...handoverData, [field]: value })
  }

  return { handoverData, createHandover, update }
}
