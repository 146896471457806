import React from 'react'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'

import { BackendContext } from '../Backend/BackendContext'

class IntlContextProvider extends BaseComponent {
  static contextType = BackendContext

  constructor(props, context) {
    super(props, context)

    this.state = {
      messages: null
    }

    this.initMessages = this.initMessages.bind(this)
  }

  componentDidMount() {
    this.initMessages()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetsUrl !== this.props.assetsUrl) {
      this.initMessages()
    }
  }

  initDefaultMessages() {
    if (this.context.session.locale === this.props.defaultLocale) {
      return Promise.resolve({}) // Locale is the default.
    }

    const endpoint = `${this.props.assetsUrl}/messages/${this.props.defaultLocale}.json`

    return fetch(endpoint).then(response => response.json())
  }

  initLocaleMessages() {
    const locale = this.context.session.locale || this.props.defaultLocale
    const endpoint = `${this.props.assetsUrl}/messages/${locale}.json`

    return fetch(endpoint).then(response => response.json())
  }

  initCustomMessages() {
    const locale = this.context.session.locale || this.props.defaultLocale

    if (!this.props.customAssetsUrl) {
      return Promise.resolve({})
    }

    const endpoint = `${this.props.customAssetsUrl}/messages/${locale}.json`

    return fetch(endpoint)
      .then(response => response.json())
      .catch(() => console.info('No custom messages found'))
  }

  initMessages() {
    return Promise.all([
      this.initLocaleMessages(),
      this.initDefaultMessages(),
      this.initCustomMessages()
    ])
      .then(([messages, defaultMessages, customMessages]) =>
        this.promiseState({
          messages: { ...defaultMessages, ...messages, ...customMessages }
        })
      )
      .catch(errors => {
        throw new Error(errors)
      })
  }

  render() {
    return (
      <>
        {this.state.messages && (
          <IntlProvider
            locale={this.context.session.locale.replace('_', '-')}
            messages={this.state.messages}
            wrapRichTextChunksInFragment
          >
            {this.props.children}
          </IntlProvider>
        )}
      </>
    )
  }
}

IntlContextProvider.defaultProps = {}

IntlContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  assetsUrl: PropTypes.string,
  customAssetsUrl: PropTypes.string,
  defaultLocale: PropTypes.string.isRequired
}

export default IntlContextProvider
