import { ERROR_CODE } from 'lib/constants'
import { IdModel } from 'typings/context'
import DocumentError from 'utils/DocumentError'

import BaseApi from './BaseApi'

const sessionApi = (api: BaseApi) => ({
  getDocumentTypeWhitelist: () => api.get('session/document-type-whitelist'),

  getCanEditPrivacyFilters: () => api.get('session/can-edit-privacy-filters'),

  // Todo, jvw: put this endpoint in session in middleware
  getAvailableCountries: () => api.get('available-countries'),

  getFacematchResult: () =>
    api.get<{ matches: boolean; confidence: number }>('session/get-facematch-result'),

  getClassificationResult: () => {
    const endpoint = 'session/document-classification'

    return api
      .get<{ uuid: IdModel['uuid']; error?: { message: string } }>(endpoint)
      .then(response => {
        if (!response.hasOwnProperty('uuid') && response.error) {
          throw new Error(response.error.message)
        }

        if (response.uuid.length < 36) {
          throw new Error('Malformed classification result')
        }

        return response.uuid.substring(0, 36)
      })
  },

  getIdModels: (docTypes: string | Array<string>) => {
    const docMapping = { P: 'P', PE: 'P', I: 'I', D: 'D', R: 'R', IT: 'RP', IR: 'RP' }
    const endpoint = 'session/id-models'

    return api
      .get<Array<IdModel>>(endpoint)
      .then(response =>
        response.map(item => ({
          ...item,
          type: docMapping[item.type as keyof typeof docMapping] || null
        }))
      )
      .then(docs => {
        if (docTypes) {
          return docs.filter(({ type }) => {
            if (type === null) {
              return false
            }

            return type === docTypes || docTypes.includes(type)
          })
        }

        return docs
      })
      .then(result => {
        return result as Array<IdModel>
      })
  },

  setSelectedModel: (selectedModel: string | null) => {
    const endpoint = 'session/selected_document_model'
    const data = { selected_model_uuid: selectedModel }

    return api.post<IdModel>(endpoint, data)
  },

  shouldScanBackside: () => {
    const endpoint = 'session/should-scan-backside'

    return api.get<{ scan_backside: boolean }>(endpoint)
  },

  setSelectedDocumentType: (selectedDocumentType: string) => {
    const endpoint = 'session/selected_document_type'
    const data = { selected_document_type: selectedDocumentType }

    return api.post(endpoint, data)
  },

  setSelectedCountry: (selectedCountryCode: string) => {
    const endpoint = 'session/selected_country'
    const data = { selected_country: selectedCountryCode }

    return api.post(endpoint, data)
  },

  documentValidation: () => {
    const endpoint = 'session/validate'

    return api.get<Array<{ type: string }>>(endpoint).then(result => {
      if (result.length > 0) {
        throw new DocumentError({
          reasons: result,
          type: ERROR_CODE.DocumentValidationFailed
        })
      }
    })
  },

  setFinished: () => api.post('session/finish'),

  postEvents: events => {
    const endpoint = 'session/beacon'

    return api.post(endpoint, events)
  }
})

export default sessionApi
