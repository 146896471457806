import React from 'react'
import * as localeCountries from 'i18n-iso-countries/langs/en.json'
import {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js'
import PropTypes from 'prop-types'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import { ButtonPrimaryPhoneSC } from 'components/Button/VerifaiButton.style'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import { Div } from 'components/Screen/Body/Elements.style'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'

import {
  Arrow,
  CountryCode,
  DropdownItem,
  DropdownMenu,
  HLine,
  Input,
  InputGroup,
  InputGroupButtonDropdown
} from './PhoneInput.style'

const PhoneInputStatus = {
  VALID: 1,
  NEUTRAL: 0,
  INVALID: -1
}

class PhoneInput extends BaseComponent {
  constructor(props) {
    super(props)

    this.localeCountries = localeCountries

    this.countryMap = getCountries()
      .map(alpha2 => {
        return {
          alpha2,
          country: this.getCountryFromAlpha2(alpha2),
          flag: this.getFlagFromAlpha2(alpha2),
          code: getCountryCallingCode(alpha2)
        }
      })
      .filter(obj => obj.country)

    this.state = {
      isEditing: false,
      formatted: '',
      country: this.props.country,
      isOpenDropdown: false,
      dropDownSearch: '',
      validPhone: false
    }

    this.onChange = this.onChange.bind(this)
    this.setCountry = this.setCountry.bind(this)

    this.state.formatted = `+${getCountryCallingCode(this.state.country)}`
  }

  onChange(event) {
    const curValue = event ? event.target.value : this.state.formatted

    // Remove whitespace and country code, then format
    const formatted = this.validateAndFormat(this.getNumberWithoutCountry(curValue))

    this.setState({ formatted })

    this.props.onChange(formatted)
  }

  validateAndFormat(number) {
    const regExp = /[a-zA-Z!@#$%^&*()_=]/g

    number = `+${getCountryCallingCode(this.state.country)}${number}`

    if (isValidPhoneNumber(number)) {
      this.setState({ validPhone: PhoneInputStatus.VALID })

      return parsePhoneNumber(number).formatInternational()
    }

    if (regExp.test(number)) {
      this.setState({ validPhone: PhoneInputStatus.INVALID })
    } else {
      this.setState({ validPhone: PhoneInputStatus.NEUTRAL })
    }

    return number
  }

  getNumberWithoutCountry(number) {
    return number
      .replace(/\s+/g, '')
      .substring(getCountryCallingCode(this.state.country).length + 1)
  }

  setCountry(item) {
    const country = item.currentTarget.accessKey

    const n = this.getNumberWithoutCountry(this.state.formatted)

    this.promiseState({ country })
      .then(() => this.promiseState({ isOpenDropdown: false }))
      .then(() => this.promiseState({ formatted: this.validateAndFormat(n) }))
  }

  getFlagFromAlpha2(alpha2) {
    return alpha2
      .toUpperCase()
      .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
  }

  getCountryFromAlpha2(alpha2) {
    let country = this.localeCountries.countries[alpha2]

    if (Array.isArray(country)) {
      country = country[0]
    }

    return country
  }

  getCountryCodeDropdown() {
    const search = this.state.dropDownSearch.toUpperCase()
    const items = this.countryMap
      .filter(obj => {
        return (
          (search && obj.alpha2.startsWith(search)) ||
          obj.country.toUpperCase().startsWith(search) ||
          obj.code.startsWith(search) ||
          (search.startsWith('+') && obj.code.startsWith(search.substring(1)))
        )
      })
      .sort((a, b) => {
        const aName = a.country.toUpperCase()
        const bName = b.country.toUpperCase()

        return aName < bName ? -1 : aName > bName ? 1 : 0
      })
      .map(obj => (
        <DropdownItem
          accessKey={obj.alpha2}
          key={obj.alpha2}
          selected={this.state.country === obj.alpha2}
          onClick={this.setCountry}
        >
          <Div pr={5}>
            <span>{obj.flag}</span>
          </Div>
          <div>
            <span>{obj.country}</span>
          </div>
          <CountryCode>
            <span>(+{obj.code})</span>
          </CountryCode>
        </DropdownItem>
      ))

    return (
      <div>
        <InputGroupButtonDropdown
          onClick={() => this.setState({ isOpenDropdown: !this.state.isOpenDropdown })}
        >
          <div className="vertical-center">
            {this.countryMap.find(o => o.alpha2 === this.state.country).flag}
            {this.state.isOpenDropdown ? <Arrow up /> : <Arrow down />}
          </div>
        </InputGroupButtonDropdown>

        {this.state.isOpenDropdown && (
          <DropdownMenu>
            <DropdownItem header>Select country</DropdownItem>
            <DropdownItem header>
              <Input
                value={this.state.dropDownSearch}
                onChange={e => this.setState({ dropDownSearch: e.target.value })}
              />
            </DropdownItem>

            <HLine />

            {items}
          </DropdownMenu>
        )}
      </div>
    )
  }

  phoneInputStatusToBackgroundColorMap() {
    switch (this.state.validPhone) {
      case PhoneInputStatus.VALID:
        return this.context.theme.colors.inputValid
      case PhoneInputStatus.INVALID:
        return this.context.theme.colors.inputInvalid
      default:
        return 'inherit'
    }
  }

  render() {
    return (
      <InputGroup className="mb-3">
        {this.getCountryCodeDropdown()}

        <Input
          type="text"
          placeholder="Phone number"
          aria-label="Phone number"
          aria-describedby="basic-addon1"
          name={this.props.name}
          value={this.state.formatted}
          onChange={this.onChange}
          valid={this.state.validPhone}
        />

        <ButtonPrimaryPhoneSC onClick={this.props.onClick}>
          {this.props.isLoading ? (
            <LoadingIndicator color="light" size="sm" />
          ) : (
            <VerifaiMessage id="WK2_button_send" />
          )}
        </ButtonPrimaryPhoneSC>
      </InputGroup>
    )
  }
}

PhoneInput.propTypes = {
  countries: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.string,
  country: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  validationColors: PropTypes.array
}

export default PhoneInput
