import styled from 'styled-components'

import { Breakpoint } from 'lib/enums/style'
import { defaultSpacing } from 'src/style/mixins'

// eslint-disable-next-line import/prefer-default-export
export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${defaultSpacing(6)} ${defaultSpacing(7)} ${defaultSpacing(2)};
  height: ${defaultSpacing(15)};
  min-height: ${defaultSpacing(15)};

  @media (max-width: ${Breakpoint.Medium}px) {
    padding: ${defaultSpacing(4)} ${defaultSpacing(4)} ${defaultSpacing()};
  }
`
