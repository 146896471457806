import React from 'react'

import BaseComponent from 'components/BaseComponent/BaseComponent'
import VerifaiWrapper from 'components/VerifaiWrapper/VerifaiWrapper'
import VerifaiContext from 'context/VerifaiContext'
import setNonce from 'utils/setNonce'

import verifaiPropTypes from './VerifaiPropTypes'

class Verifai extends BaseComponent {
  componentDidMount() {
    setNonce(this.props.nonce)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nonce !== this.props.nonce) {
      setNonce(this.props.nonce)
    }
  }

  render() {
    return (
      <VerifaiContext {...this.props}>
        <VerifaiWrapper />
      </VerifaiContext>
    )
  }
}

Verifai.defaultProps = {
  // Add warnings by default.
  onSuccess: () => console.warn('Please implement your onSuccess callback'),
  onCanceled: () => console.warn('Please implement your onCanceled callback'),
  assetsUrl: '__VERIFAI_ASSETS_URL__'
}

Verifai.propTypes = verifaiPropTypes

export default Verifai
