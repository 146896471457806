import React, { useContext, useEffect, useRef, useState } from 'react'

import BackButton from 'components/Button/BackButton'
import { ButtonOutlineSC } from 'components/Button/VerifaiButton.style'
import Description from 'components/Description/VerifaiDescription'
import { Icon } from 'components/Image/Icon'
import { SvgWrapperSC } from 'components/Image/Image.style'
import Body from 'components/Screen/Body/VerifaiBody'
import { InfoboxSC } from 'components/Screen/Body/VerifaiBody.style'
import Footer from 'components/Screen/Footer/VerifaiFooter'
import Header from 'components/Screen/Header/VerifaiHeader'
import Heading from 'components/Screen/Heading/Heading'
import Screen from 'components/Screen/Screen'
import { VerifaiMessage } from 'components/Text/VerifaiIntl'
import { BackendContext } from 'context/Backend/BackendContext'
import { RouterContext } from 'context/Router/RouterContextProvider'
import { ScanContext } from 'context/ScanContextProvider'
import { DEFAULT_JOB_DELAY } from 'lib/constants'
import formatSeconds from 'utils/formatSeconds'
import PromisePoller from 'utils/PromisePoller'

function ScreenWK3() {
  const [secondsRemaining, setSecondsRemaining] = useState<number | null>(null)

  const { api } = useContext(BackendContext)
  const router = useContext(RouterContext)
  const scan = useContext(ScanContext)

  const countdownRef = useRef<number | null>(null)
  const pollerRef = useRef<PromisePoller>(null)

  async function startPoller() {
    if (pollerRef.current) {
      pollerRef.current.reset()
    }

    const handoverId = scan.handover?.id

    const newPoller = await api.handover.poller(handoverId, DEFAULT_JOB_DELAY, 0)

    pollerRef.current = newPoller

    return newPoller.run()
  }

  function updateCountdown() {
    if (scan.handover?.expiresAt) {
      const newSecondsRemaining = (scan.handover.expiresAt - Date.now()) / 1000

      if (countdownRef.current !== null && newSecondsRemaining <= 0) {
        clearInterval(countdownRef.current)
        setSecondsRemaining(null)
      } else {
        setSecondsRemaining(newSecondsRemaining)
      }
    }
  }

  async function initialize() {
    const isUsed = await startPoller()

    try {
      if (isUsed) {
        router.next()
      } else {
        router.back()
      }
    } catch (error) {
      console.error(error)
    }

    updateCountdown()

    countdownRef.current = window.setInterval(updateCountdown, 300)
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    return function cleanup() {
      if (pollerRef.current) {
        pollerRef.current.reset()
      }

      if (countdownRef.current) {
        clearInterval(countdownRef.current)
      }
    }
  }, [])

  return (
    <Screen>
      <Heading>
        <Header messageKey="WK3_title" />
      </Heading>

      <Body>
        <Description>
          <VerifaiMessage id="WK3_description" values={{ phone: scan.handoverPhone || '' }} />
        </Description>

        <SvgWrapperSC>
          <Icon id="goToYourMobileImage" />
        </SvgWrapperSC>

        <InfoboxSC>
          {secondsRemaining !== null && secondsRemaining > 0 ? (
            <VerifaiMessage
              id="WK3_info_box"
              values={{ remaining: formatSeconds(secondsRemaining) }}
            />
          ) : (
            <>
              <VerifaiMessage id="WK3_info_box_link_expired" />
              <br />
              <ButtonOutlineSC onClick={() => router.back()}>
                <VerifaiMessage id="WK3_resend_text_message_button" />
              </ButtonOutlineSC>
            </>
          )}
        </InfoboxSC>
      </Body>

      <Footer>
        <BackButton />
      </Footer>
    </Screen>
  )
}

export default ScreenWK3
