import React from 'react'

import * as S from './Heading.style'

interface HeadingProps {
  children: React.ReactNode
}

function Heading(props: HeadingProps) {
  const { children } = props

  return <S.Heading>{children}</S.Heading>
}

export default Heading
